<template>
  <div class="eleStatement">
    <div class="title">
      <p>
        <span>用电报表</span>
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <!--头部-->
        <div class="headBox">
          <p class="title">用电量</p>
          <div class="tabBox">
            <p :class="activeIdx===1?'active':''" @click="activeClick(1)">日报表</p>
            <p :class="activeIdx===2?'active':''" @click="activeClick(2)">月报表</p>
            <p :class="activeIdx===3?'active':''" @click="activeClick(3)">年报表</p>
          </div>
        </div>
        <!--搜索-->
        <div class="searchBox">
          <div class="liPox">
            <div class="liBox" style="display: flex">
              <el-date-picker v-if="activeIdx===1" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="daterange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===2" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                              range-separator="→"
                              start-placeholder="开始时间"
                              type="monthrange"
                              value-format="YYYY-MM-DD">
              </el-date-picker>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[0]"
                              :disabled-date="disabledDate"
                              placeholder="开始时间"
                              type="year" value-format="YYYY-MM-DD"
              />
              <div v-if="activeIdx===3" class="segmentation" style="margin: 0 8px;transform: translateY(5px)">至</div>
              <el-date-picker v-if="activeIdx===3"
                              v-model="dateTime[1]"
                              :disabled-date="disabledDate2"
                              placeholder="结束时间"
                              type="year"
                              value-format="YYYY-MM-DD"
              />
            </div>
            <div class="liBox">
              <el-tree-select
                  ref="treeRef1"
                  v-model="ammeter"
                  :data="ammeterList"
                  :props="props"
                  :render-after-expand="false"
                  class="treeSelect"
                  filterable
                  multiple
                  node-key="id"
                  placeholder="请选择电表"
                  style="height: auto!important"
              />
            </div>
            <div class="liBox">
              <el-select v-model="chartType" placeholder="请选择图表类型" @change="planChange">
                <el-option :value="1" label="折线"></el-option>
                <el-option :value="2" label="柱状"></el-option>
              </el-select>
            </div>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
            <el-button class="searchButton resetButton" @click="exportFun">导出</el-button>
          </div>
        </div>
        <!--内容-->
        <div class="containBox">
          <el-empty v-if="echartsDataX.length===0"
                    class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
          <!--图表-->
          <div v-show="echartsDataX.length>0" class="chart"></div>
        </div>
      </div>
    </div>
    <!--选择设备弹窗-->
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" class="bindDialog" title="绑定设备" width="80%">
      <div class="dialogCont">
        <div class="leftBox">
          <div class="head">待选项:</div>
          <div class="leftBoxCont">
            <div class="transferContentLeft">
              <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                       :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                       :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
                <template #default="{ node, data }">
                  <span>{{ node.label }}</span>
                </template>
              </el-tree>
            </div>
            <div class="transferContRight">
              <el-popover :visible="checkAllVisible" :width="224" placement="top">
                <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                  <div style="display:flex;align-items: center;">确定要将设备全部添加到应用中吗？</div>
                  <div style="display:flex;justify-content: end;height: 32px;">
                    <el-button size="small" @click="checkAllVisible = false">取消</el-button>
                    <el-button size="small" type="primary" @click="equipmentSelectAllClick">确定</el-button>
                  </div>
                </div>
                <template #reference>
                  <!--                  <p @click="checkAllVisible=true">全选</p>-->
                  <el-button type="primary" @click="checkAllVisible=true">全选</el-button>
                </template>
              </el-popover>
              <p v-for="(item,index) in equipmentSelectList" :key="index"
                 :style="{cursor:item.enableFlag==1?'no-drop':'pointer'}"
                 @click="addEquipmentFun(item)">
                {{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="head" style="display: flex;justify-content: space-between">
            <p>已绑定的设备:</p>
            <el-popover :visible="delAllVisible" :width="224" placement="top">
              <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                <div style="display:flex;align-items: center;">确定要将应用设备设备清空吗？</div>
                <div style="display:flex;justify-content: end;height: 32px;">
                  <el-button size="small" @click="delAllVisible = false">取消</el-button>
                  <el-button size="small" type="primary" @click="delAllClick">确定</el-button>
                </div>
              </div>
              <template #reference>
                <div style="display: flex;align-items: center;cursor: pointer" @click="delAllVisible=true">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/clear.png"
                       style="width: 16px;height: 16px;">
                  <span style="color: #165DFF;transform: translateX(-10px)">清空</span>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="deviceBox">
            <p v-for="(item,index) in checkList" :key="index">
              <span>{{ item.value }}</span>
              <el-icon @click="delEquipmentFun(index)">
                <CircleCloseFilled/>
              </el-icon>
            </p>
          </div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addDeviceFun">确认</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import './eleStatement.less'
import * as echarts from "echarts";
import {ElLoading, ElMessage} from "element-plus";
import moment from "moment";
import {getReportDataApi, getAllTreeApi} from "@/api/modules/bigScreen";
import {http2} from "@/api";
import qs from "qs";
import {getPowerPriceGroupTreeApi} from "@/api/modules/rate";


// 查询字段*********************************************************************************
let dateTime = ref([moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]) //开始结束时间
let ammeter = ref([]) //电表
let chartType = ref(1) //图表类型
const planChange = function () {
  nextTick(() => {
    chartLoad()
  })
}

watch(() => dateTime.value, (val) => {
  if (activeIdx.value == 3) {
    disabledDate(new Date(val[0]))
    disabledDate2(new Date(val[1]))
  }
})
const disabledDate = (time) => {
  if (dateTime.value[1]) {
    return time.getTime() >= moment(dateTime.value[1]).valueOf()
  }
  return false
}
const disabledDate2 = (time) => {
  if (dateTime.value[0]) {
    return time.getTime() < moment(dateTime.value[0]).valueOf()
  }
  return false
}


// 电表列表
let ammeterList = ref()


// 日月年切换
let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
}

// 获取图表数据****************************************************************************************
let echartsDataY = ref([])
let echartsDataX = ref([])
const getEchartsDataFun = async function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value && chartType.value) {
    echartsDataX.value = []
    echartsDataY.value = []
    openFullScreen()
    await getReportDataApi(qs.stringify({
      type: 1,
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentIdList: ammeter.value.join(',')
    })).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        echartsDataX.value = res.data.result.timeList
        for (let i = 0; i < res.data.result.list.length; i++) {
          echartsDataY.value.push({
            name: res.data.result.list[i].key,
            type: chartType.value == 1 ? 'line' : 'bar',
            symbol: 'circle',
            symbolSize: 8,    //小圆点的大小
            itemStyle: {
              // color: '#427EFF',    //小圆点和线的颜色
              borderColor: '#fff', // 设置边框透明
              borderWidth: 1, // 设置边框宽度为0
              borderType: 'solid', // 设置边框类型为实线
            },
            data: res.data.result.list[i].value
          })
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}

// 查询
const searchFun = async function () {
  await getEchartsDataFun()
}
// 重置
const resetFun = function () {
  ammeter.value = []
  if (activeIdx.value === 1) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }
  chartType.value = 1

  echartsDataY.value = []
  echartsDataX.value = []
  nextTick(() => {
    chartLoad()
  })
}

// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    series: echartsDataY.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出************************************************************************************
const exportFun = function () {
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && ammeter.value) {
    openFullScreen()
    window.location.href = http2 + 'zouk/rest/localScreenData/exportReportData?type=1&timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentIdList=' + ammeter.value.join(',')
    setTimeout(() => {
      loadingAdd.value.close();
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 1000);
    loadingAdd.value.close()
  } else {
    ElMessage({
      type: 'info',
      message: '请选择导出条件'
    });
  }
}


// 选择设备操作************************************************************************************
// 点击新增设备
let checkList = ref([])   //选中的设备
const selectTimeFun = function () {
  dialogVisible.value = true
  checkList.value = bindDevice.value
}
let dialogVisible = ref(false)  //时间段弹窗
// 点击添加
const addEquipmentFun = function (item) {
  if (item.enableFlag == 0) {
    let flag = false
    for (let i = 0; i < checkList.value.length; i++) {
      if (checkList.value[i].key === item.id) {
        flag = true
      }
    }
    if (!flag) {
      checkList.value.push({
        key: item.id,
        value: item.name
      })
    } else {
      ElMessage({
        type: 'info',
        message: '该设备已选中'
      })
    }
  } else {
    ElMessage({
      type: 'info',
      message: '该设备在其他组合包中已使用'
    })
  }
}
const delEquipmentFun = function (idx) {
  checkList.value.splice(idx, 1)
}
// 全选应用设备
let checkAllVisible = ref(false)
const equipmentSelectAllClick = function () {
  for (let i = 0; i < equipmentSelectList.value.length; i++) {
    addEquipmentFun(equipmentSelectList.value[i])
  }
  checkAllVisible.value = false
}
// 点击将设备添加到选择框内
let bindDevice = ref([])
const addDeviceFun = function () {
  bindDevice.value = checkList.value
  strategyForm.value.equipmentIdList = []
  for (let i = 0; i < checkList.value.length; i++) {
    strategyForm.value.equipmentIdList.push(checkList.value[i].key)
  }
  dialogVisible.value = false
}
// 清空应用设备
let delAllVisible = ref(false)
const delAllClick = function () {
  checkList.value = []
  delAllVisible.value = false
}
// 树级列表
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let defaultExpandKeys = ref() //默认选中树级列表第一级
let treeRef = ref()
let customerList = ref([])
//获取项目树级列表
const getProjectListFun = async function () {
  await getPowerPriceGroupTreeApi(qs.stringify({
    projectId: 0,
    id: router.currentRoute.value.query.id ? router.currentRoute.value.query.id : 0
  })).then(res => {
    if (res.data.code === 0) {
      customerList.value = res.data.result
      defaultExpandKeys.value = [customerList.value[0].id]
      //有无路由参数
      currentNodeKey.value = customerList.value[0].id
      custerObj.value = customerList.value[0]
      nextTick(() => {
        if (treeRef.value) {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        }
      })
      getEquipmentSelectFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  defaultExpandKeys.value.push(e.parentId)
  getEquipmentSelectFun()
}
// 根据项目获取设备选项
let equipmentSelectList = ref([])
const getEquipmentSelectFun = function () {
  getPowerPriceGroupTreeApi(qs.stringify({
    projectId: custerObj.value.id,
    id: router.currentRoute.value.query.id ? router.currentRoute.value.query.id : 0
  })).then(res => {
    if (res.data.code === 0) {
      equipmentSelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
onMounted(() => {
  chartLoad()


  // 获取所有设备树
  getAllTreeApi(qs.stringify({
    type: 1
  })).then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      ammeterList.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


</script>

<style lang="less" scoped>
</style>
