<template>
  <div class="businessScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="headBox">
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data.totalPayAmount" :options="{useGrouping:true}" :start-val="countStart2"
                        class="val">
                <template #suffix>
                  <span style="font-size: 26px;letter-spacing: 0"> 元</span>
                </template>
              </count-up>
              <!--              <div class="bgcList">-->
              <!--                <p v-for="(item,index) in (String(Number(data.totalPayAmount).toFixed(0)).length)"-->
              <!--                   :key="index"></p>-->
              <!--                <p v-for="(item,index) in String(Number(data.totalPayAmount).toFixed(0))"-->
              <!--                   :key="index">{{ item }}</p>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data1.powerEnergyUseAmount" :options="{useGrouping:true}"
                        :start-val="countStart1" class="val">
                <template #suffix>
                  <span style="font-size: 26px;letter-spacing: 0"> kwh</span>
                </template>
              </count-up>
              <!--              <div class="bgcList">-->
              <!--                <p v-for="(item,index) in (String(Number(data.powerEnergyUseAmount).toFixed(0)).length)"-->
              <!--                   :key="index"></p>-->
              <!--                <p v-for="(item,index) in String(Number(data1.powerEnergyUseAmount).toFixed(0))"-->
              <!--                   :key="index">{{ item }}</p>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="headItemBox">
            <div class="valueBox">
              <count-up :end-val="data.dataNumber" :options="{useGrouping:true}" :start-val="countStart3"
                        class="val">
                <template #suffix>
                  <span style="font-size: 26px;letter-spacing: 0"> 条</span>
                </template>
              </count-up>
              <!--              <div class="bgcList">-->
              <!--                <p v-for="(item,index) in (String(Number(data.dataNumber).toFixed(0)).length)"-->
              <!--                   :key="index"></p>-->
              <!--                <p v-for="(item,index) in String(Number(data.dataNumber).toFixed(0))"-->
              <!--                   :key="index">{{ item }}</p>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <div class="leftBox">
          <div class="leftItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>光伏数据</p>
            </div>
            <div class="cont">
              <p>总发电量(kwh)</p>
              <count-up :key="num1" :end-val="data.photovoltaicTotalPower" :start-val="countStart4"
                        class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfItem1.png">
                  <p>逆变器台数</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.photovoltaicEquipmentNumber }}</p>
                  <p class="unit">台</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/gfItem2.png">
                  <p>总装机容量</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.photovoltaicTotalCapacity }}</p>
                  <p class="unit">kw</p>
                </div>
              </div>
            </div>
          </div>
          <div class="leftItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>储能数据</p>
            </div>
            <div class="cont">
              <p>总放电量(kwh)</p>
              <count-up :key="num2" :end-val="data.storeEnergyTotalDischarge" :start-val="countStart5"
                        class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnItem1.png">
                  <p>储能电站</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.storeEnergyEquipmentNumber }}</p>
                  <p class="unit">个</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/cnItem2.png">
                  <p>总装机容量</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.storeEnergyTotalCapacity }}</p>
                  <p class="unit">kw</p>
                </div>
              </div>
            </div>
          </div>
          <div class="leftItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>电能数据</p>
            </div>
            <div class="cont">
              <p>设备总数</p>
              <count-up :key="num3" :end-val="data.equipmentNumber" :start-val="countStart6" class="val"></count-up>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnItem1.png">
                  <p>总功率</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.powerEnergyRate }}</p>
                  <p class="unit">kw</p>
                </div>
              </div>
              <div class="item">
                <div class="itemL">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/dnItem2.png">
                  <p>总客户数</p>
                </div>
                <div class="itemR">
                  <p class="itemVal">{{ data.powerEnergyCustomerNumber }}</p>
                  <p class="unit">个</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>收款数据</p>
            </div>
            <div class="cont cont3">
              <div class="chart2"></div>
              <div class="list">
                <div v-for="(item,index) in data.payPieList" :key="index" class="listItem">
                  <div v-if="item.key==='微信'" class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem1.png">
                    <p>微信</p>
                  </div>
                  <div v-if="item.key==='微信'" class="itemR">
                    <div class="val">{{
                        Number(item.value) > 100000000 ? (item.value / 100000000).toFixed(2) : (item.value / 10000).toFixed(2)
                      }}
                    </div>
                    <div class="unit">{{ Number(item.value) > 100000000 ? '亿元' : '万元' }}</div>
                  </div>
                  <div v-if="item.key==='支付宝'" class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem2.png">
                    <p>支付宝</p>
                  </div>
                  <div v-if="item.key==='支付宝'" class="itemR">
                    <div class="val">{{
                        Number(item.value) > 100000000 ? (item.value / 100000000).toFixed(2) : (item.value / 10000).toFixed(2)
                      }}
                    </div>
                    <div class="unit">{{ Number(item.value) > 100000000 ? '亿元' : '万元' }}</div>
                  </div>
                  <div v-if="item.key==='银行卡'" class="itemL">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/skItem3.png">
                    <p>银行卡</p>
                  </div>
                  <div v-if="item.key==='银行卡'" class="itemR">
                    <div class="val">{{
                        Number(item.value) > 100000000 ? (item.value / 100000000).toFixed(2) : (item.value / 10000).toFixed(2)
                      }}
                    </div>
                    <div class="unit">{{ Number(item.value) > 100000000 ? '亿元' : '万元' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/yqTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>每日收款</p>
            </div>
            <div class="cont cont2">
              <div class="chart3"></div>
            </div>
          </div>
          <div class="rightItemBox">
            <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/ysTitle.png">-->
            <div class="headTitleBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/titleIcon.png">
              <p>每日电量</p>
            </div>
            <div class="cont cont2">
              <div class="chart1"></div>
            </div>
          </div>
          <!--          <div class="rightItemBox">-->
          <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/yqTitle.png">-->
          <!--            <div class="cont cont1">-->
          <!--              <div class="list">-->
          <!--                <div class="listItem">-->
          <!--                  <p>氧气用量</p>-->
          <!--                  <p>150t</p>-->
          <!--                </div>-->
          <!--                <div class="listItem">-->
          <!--                  <p>氮气</p>-->
          <!--                  <p>150t</p>-->
          <!--                </div>-->
          <!--                <div class="listItem">-->
          <!--                  <p>二氧化碳用量</p>-->
          <!--                  <p>150t</p>-->
          <!--                </div>-->
          <!--                <div class="listItem">-->
          <!--                  <p>燃气</p>-->
          <!--                  <p>150t</p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="centerBox">
          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/ywTitle.png">
        </div>

        <!--进入退出全屏-->
        <div class="enterScreenBox">
          <img v-show="!isScreen" alt="" class="enterBtn"
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/enterScreen.png"
               @click="enterFullScreen">
          <img v-show="isScreen" alt=""
               src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/outScreen.png"
               @click="exitFullscreen">
        </div>
      </div>
      <div class="bgcGif"></div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import './businessScreen.less'
import VScaleScreen from 'v-scale-screen'
import CountUp from 'vue-countup-v3'
import * as echarts from "echarts";
import {getBusinessScreenApi, getBusinessScreenPowerApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let data = ref({})
let data1 = ref({powerEnergyUseAmount: 0})

let num = 0
let num1 = ref(0)
let num2 = ref(0)
let num3 = ref(0)
// 获取大屏数据
const getScreenData = function () {
  getBusinessScreenApi().then(res => {
    if (res.data.code === 0) {
      // 处理交易量和采集量的动态数据
      if (countStart2.value !== 0) {
        let num = res.data.result.totalPayAmount - data.value.totalPayAmount
        countStart2.value = Number(data.value.totalPayAmount)
        data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 12
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 2
        }, 3000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 31
        }, 6000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 8
        }, 9000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 7
        }, 12000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 14
        }, 15000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 4
        }, 18000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 2
        }, 21000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 8
        }, 24000)
        setTimeout(() => {
          countStart2.value = Number(data.value.totalPayAmount)
          data.value.totalPayAmount = Number(data.value.totalPayAmount) + (num / 100) * 12
        }, 27000)
      }
      if (countStart3.value !== 0) {
        let num2 = res.data.result.dataNumber - data.value.dataNumber
        countStart3.value = Number(data.value.dataNumber)
        data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 12
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 2
        }, 3000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 31
        }, 6000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 8
        }, 9000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 7
        }, 12000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 14
        }, 15000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 4
        }, 18000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 2
        }, 21000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 8
        }, 24000)
        setTimeout(() => {
          countStart3.value = Number(data.value.dataNumber)
          data.value.dataNumber = Number(data.value.dataNumber) + (num2 / 100) * 12
        }, 27000)
      }

      if (num != 0) {
        countStart4.value = 0
        num1.value = num1.value + 1
        data.value.photovoltaicTotalPower = res.data.result.photovoltaicTotalPower
        data.value.photovoltaicEquipmentNumber = res.data.result.photovoltaicEquipmentNumber
        data.value.photovoltaicTotalCapacity = res.data.result.photovoltaicTotalCapacity
        setTimeout(() => {
          countStart5.value = 0
          num2.value = num2.value + 1
          data.value.storeEnergyTotalDischarge = res.data.result.storeEnergyTotalDischarge
          data.value.storeEnergyEquipmentNumber = res.data.result.storeEnergyEquipmentNumber
          data.value.storeEnergyTotalCapacity = res.data.result.storeEnergyTotalCapacity
        }, 5000)
        setTimeout(() => {
          countStart6.value = 0
          num3.value = num3.value + 1
          data.value.equipmentNumber = res.data.result.equipmentNumber
          data.value.powerEnergyRate = res.data.result.powerEnergyRate
          data.value.powerEnergyCustomerNumber = res.data.result.powerEnergyCustomerNumber
        }, 10000)
        setTimeout(() => {
          data.value.payPieList = res.data.result.payPieList
          // 饼图处理
          for (let i = 0; i < data.value.payPieList.length; i++) {
            data.value.payPieList[i].name = data.value.payPieList[i].key
            data.value.payPieList[i].value = Number(data.value.payPieList[i].value)
          }
          data.value.payPieList2 = JSON.parse(JSON.stringify(data.value.payPieList))
          for (let i = 0; i < data.value.payPieList.length; i++) {
            if (data.value.payPieList[i].name === '微信') {
              data.value.payPieList[i].itemStyle = {
                color: 'rgba(40, 196, 69, 1)',
              }
            } else if (data.value.payPieList[i].name === '支付宝') {
              data.value.payPieList[i].itemStyle = {
                color: 'rgba(52, 109, 249,1)',
              }
            } else if (data.value.payPieList[i].name === '银行卡') {
              data.value.payPieList[i].itemStyle = {
                // color: 'rgba(6, 145, 253,1)'
                color: '#FF9730'
              }
            }
          }
          for (let i = 0; i < data.value.payPieList2.length; i++) {
            if (data.value.payPieList2[i].name === '微信') {
              data.value.payPieList2[i].itemStyle = {
                color: '#dbf6e7',
              }
            } else if (data.value.payPieList2[i].name === '支付宝') {
              data.value.payPieList2[i].itemStyle = {
                color: '#dff1ff',
              }
            } else if (data.value.payPieList2[i].name === '银行卡') {
              data.value.payPieList2[i].itemStyle = {
                // color: '#e0e8ff',
                color: '#FFEAD5',
              }
            }
          }
          nextTick(() => {
            chartFun2()
          })
        }, 15000)
        setTimeout(() => {
          data.value.payList = res.data.result.payList
          for (let i = 0; i < data.value.payList.length; i++) {
            data.value.payList[i] = Number((data.value.payList[i]) / 10000).toFixed(2)
          }
          nextTick(() => {
            chartFun3()
          })
        }, 20000)
        setTimeout(() => {
          data.value.powerList = res.data.result.powerList
          for (let i = 0; i < data.value.powerList.length; i++) {
            data.value.powerList[i] = Number(data.value.powerList[i]).toFixed(2)
          }
          nextTick(() => {
            chartFun1()
          })
        }, 25000)
      } else {
        countStart4.value = res.data.result.photovoltaicTotalPower
        countStart5.value = res.data.result.storeEnergyTotalDischarge
        countStart6.value = res.data.result.equipmentNumber
        data.value = res.data.result
        // 饼图处理
        for (let i = 0; i < data.value.payPieList.length; i++) {
          data.value.payPieList[i].name = data.value.payPieList[i].key
          data.value.payPieList[i].value = Number(data.value.payPieList[i].value)
        }
        data.value.payPieList2 = JSON.parse(JSON.stringify(data.value.payPieList))
        for (let i = 0; i < data.value.payPieList.length; i++) {
          if (data.value.payPieList[i].name === '微信') {
            data.value.payPieList[i].itemStyle = {
              color: 'rgba(40, 196, 69, 1)',
            }
          } else if (data.value.payPieList[i].name === '支付宝') {
            data.value.payPieList[i].itemStyle = {
              color: 'rgba(52, 109, 249,1)',
            }
          } else if (data.value.payPieList[i].name === '银行卡') {
            data.value.payPieList[i].itemStyle = {
              // color: 'rgba(6, 145, 253,1)'
              color: '#FF9730'
            }
          }
        }
        for (let i = 0; i < data.value.payPieList2.length; i++) {
          if (data.value.payPieList2[i].name === '微信') {
            data.value.payPieList2[i].itemStyle = {
              color: '#dbf6e7',
            }
          } else if (data.value.payPieList2[i].name === '支付宝') {
            data.value.payPieList2[i].itemStyle = {
              color: '#dff1ff',
            }
          } else if (data.value.payPieList2[i].name === '银行卡') {
            data.value.payPieList2[i].itemStyle = {
              // color: '#e0e8ff',
              color: '#FFEAD5',
            }
          }
        }
        for (let i = 0; i < data.value.payList.length; i++) {
          data.value.payList[i] = Number((data.value.payList[i]) / 10000).toFixed(2)
        }
        for (let i = 0; i < data.value.powerList.length; i++) {
          data.value.powerList[i] = Number(data.value.powerList[i]).toFixed(2)
        }
        nextTick(() => {
          chartFun2()
          chartFun1()
          chartFun3()
        })
      }


      num++
      if (router.currentRoute.value.href === "#/businessScreen") {
        dataTimer = setTimeout(() => {
          getScreenData()
        }, 30000)
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    if (router.currentRoute.value.href === "#/businessScreen") {
      dataTimer = setTimeout(() => {
        getScreenData()
      }, 30000)
    }
    console.log(err);
  })
}

// 获取大屏用电量数据
const getScreenPowerData = function () {
  getBusinessScreenPowerApi().then(res => {
    if (res.data.code === 0) {
      if (data1.value.powerEnergyUseAmount !== 0) {
        let num = res.data.result.powerEnergyUseAmount - data1.value.powerEnergyUseAmount
        countStart1.value = Number(data1.value.powerEnergyUseAmount)
        data1.value.powerEnergyUseAmount = Number(data1.value.powerEnergyUseAmount) + num / 5
        setTimeout(() => {
          countStart1.value = Number(data1.value.powerEnergyUseAmount)
          data1.value.powerEnergyUseAmount = Number(data1.value.powerEnergyUseAmount) + num / 2
        }, 1000)
        setTimeout(() => {
          countStart1.value = Number(data1.value.powerEnergyUseAmount)
          data1.value.powerEnergyUseAmount = Number(data1.value.powerEnergyUseAmount) + (num / 10) * 3
        }, 2000)
      } else {
        data1.value.powerEnergyUseAmount = res.data.result.powerEnergyUseAmount
      }
      if (router.currentRoute.value.href === "#/businessScreen") {
        dataPowerTimer = setTimeout(() => {
          getScreenPowerData()
        }, 3000)
      }
    } else {
      if (router.currentRoute.value.href === "#/businessScreen") {
        dataPowerTimer = setTimeout(() => {
          getScreenPowerData()
        }, 3000)
      }
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
    if (router.currentRoute.value.href === "#/businessScreen") {
      dataPowerTimer = setTimeout(() => {
        getScreenPowerData()
      }, 3000)
    }
  })
}

// 加载柱状图
const chartFun1 = function () {
  var chartDom = document.querySelector('.chart1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: '用电量(kwh)',
      textStyle: {
        fontSize: 12,
        fontWeight: 300
      }
    },
    grid: {
      left: 80,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: data.value.timeList
    },
    yAxis: {
      type: 'value',
      // min: 120000,
      min: Number(Math.min.apply(null, data.value.powerList)) - Number(Math.min.apply(null, data.value.powerList)) % 10000,
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: [
      // {
      //   type: "pictorialBar", //设置类型为象形柱状图
      //   symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
      //   barWidth: 26,
      //   symbolRepeat: false,
      //   z: 1,
      //   label: {
      //     show: true,
      //     position: 'top',
      //     color: '#2A6EF3',
      //     formatter: '{c}',
      //   },
      //   data: data.value.powerList,
      // },
      {
        type: 'line',
        // smooth: true,
        data: data.value.powerList,
        itemStyle: {
          color: '#00CEE8',
          opacity: 0
        },
        lineStyle: {
          width: 3
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(0, 216, 244, 0.60)'
          },
            {
              offset: 1,
              color: 'rgba(0, 216, 244, 0.20)'
            },
          ])
        }
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载柱状图
const chartFun3 = function () {
  var chartDom = document.querySelector('.chart3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: '收入(万元)',
      textStyle: {
        fontSize: 12,
        fontWeight: 300
      }
    },
    grid: {
      left: 40,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      // boundaryGap:false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: data.value.timeList
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: [
      {
        type: "pictorialBar", //设置类型为象形柱状图
        symbol: "image://https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/businessScreen/chart1.png", //图形类型，带圆角的矩形
        barWidth: 26,
        symbolRepeat: false,
        z: 1,
        label: {
          show: false,
          position: 'top',
          color: '#2A6EF3',
          formatter: '{c}',
        },
        data: data.value.payList,
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);
  setTimeout(function () {
    myChart.setOption({
      series: [{
        label: {
          show: true
        }
      }]
    });
  }, 3000);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}

// 加载饼图
const chartFun2 = function () {
  var chartDom = document.querySelector('.chart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: -70,
      top: 10,
      bottom: 10,
      right: 10,
    },
    tooltip: {
      // trigger: 'axis', // axis   item   none三个值
      show: true,
      position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置
        var pointX = point[0];
        var pointY = point[1];
        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];
        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = 100;
        } else { // 左边放的下
          x = pointX - boxWidth;
        }
        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5;
        } else { // 上边放得下
          y = pointY - boxHeight;
        }
        return [x, y];
      },
    },
    series: [
      {
        name: '收款数据',
        type: 'pie',
        radius: ['85%', '100%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        label: {
          show: false  // 隐藏所有标签
        },
        color: ['rgba(40, 196, 69, 1)', 'rgba(52, 109, 249,1)', 'rgba(6, 145, 253,1)'],
        hoverAnimation: false,
        data: data.value.payPieList
      },
      {
        name: '收款数据',
        type: 'pie',
        radius: ['55%', '85%'],
        padAngle: 2,
        labelLine: {
          show: false
        },
        label: {
          show: false  // 隐藏所有标签
        },
        hoverAnimation: false,
        color: ['#dbf6e7', '#dff1ff', '#e0e8ff'],
        data: data.value.payPieList2
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}


let isScreen = ref(false)
// 进入全屏
const enterFullScreen = () => {
  // setTimeout(() => {
  isScreen.value = true
  let element = document.querySelector('body')
  element.requestFullscreen();
  // 进入全屏
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    // IE11
    element.msRequestFullscreen();
  }
  // }, 5000)
};
// 退出全屏
const exitFullscreen = () => {
  isScreen.value = false
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};

let dataTimer
let dataPowerTimer
onMounted(() => {
  getScreenData()
  getScreenPowerData()
  chartFun1()
  chartFun3()

  nextTick(() => {
    document.querySelector('.enterScreenBox .enterBtn').click()
  })
})
let loaderTimer = setInterval(() => {
  location.reload()
}, 1000 * 60 * 60 * 12)
onUnmounted(() => {
  clearTimeout(dataTimer)
  clearTimeout(dataPowerTimer)
  clearInterval(loaderTimer)
})


</script>

<style lang="less" scoped>
</style>
