<template>
  <div class="powerStationVideo">
    <div class="title">
      <p>
        <span @click="goBack">储能电站</span>
        <span> / </span>
        <span>视频监控详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        视频监控详情
      </p>
    </div>
    <div class="content">
      <div class="videoList">
        <div v-for="(item,index) in videoList" :key="index" class="videoListItem" @click="goScreen(index)">
          <video :src="item.url" autoplay="autoplay" controls loop muted></video>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {Back} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {getPhotovoltaicDataApi, getStoreDataApi} from "@/api/modules/powerStation";
import qs from "qs";
import {ElMessage} from "element-plus";


let router = useRouter()

const goBack = function () {
  router.push('/admin/energyStation')
}

const goScreen = function (idx) {
  router.push('/energyStationVideoScreen?id=' + router.currentRoute.value.query.id + '&idx=' + idx)
}


let videoList = ref([])

// 电站数据-------------------------------------------------------------------
let stationData = ref({})
const getPhotovoltaicDataFun = function () {
  getStoreDataApi(qs.stringify({
    id: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      videoList.value = JSON.parse(res.data.result.videoJsonStr)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  })
}
onMounted(() => {
  getPhotovoltaicDataFun()
})
</script>

<style lang="less" scoped>
.powerStationVideo {
  width: 100%;
  height: 100%;
  //标题
  .title {
    width: calc(100% - 36px);
    height: 84px;
    padding-left: 36px;
    line-height: 48px;
    //background-color: #EDF0F6;

    p:nth-child(1) {
      color: rgba(0, 0, 0, .45);

      span:nth-child(1):hover, span:nth-child(3):hover {
        color: #165DFF;
        cursor: pointer;
      }

      span:nth-child(5) {
        color: rgba(0, 0, 0, .85);
      }
    }

    p:nth-child(2) {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;

      .el-icon {
        font-size: 18px;
        font-weight: bold;
        transform: translateY(3px);
        cursor: pointer;
      }

      .el-icon:hover {
        color: #165DFF;
      }
    }
  }

  //内容
  .content {
    width: calc(100% - 56px);
    height: calc(100% - 100px);
    margin: 0 28px 16px 28px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
    overflow: auto;

    .videoList {
      padding: 14px 28px 0;
      width: calc(100% - 56px);
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
      align-items: center;

      .videoListItem {
        width: calc(20% - 16px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 20px;

        video {
          padding: 4px;
          width: calc(100% - 8px);
          max-height: calc(100% - 34px);
          background: url("https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/videoBorder.png") no-repeat 0 0;
          background-size: 100% 100%;
          cursor: pointer;
        }


        p {
          text-align: center;
        }
      }

      .videoListItem:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
</style>
