import {api} from "@/api";

// 查询设备列表
export const getEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/message/rest/device/list',
        method: 'GET',
        params: data
    })
}

// 查询指标列表
export const getDataListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataMetricsSearch/listCollectDict',
        method: 'POST',
        data: data
    })
}
// 获取图表数据
export const getEchartsDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataMetricsSearch/getChartData',
        method: 'POST',
        data: data
    })
}
// 获取表格数据
export const getTableDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataMetricsSearch/getTableData',
        method: 'POST',
        data: data
    })
}


// 查询条件设置---------------------------------------
// 查询列表
export const getSearchListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: `/zouk/rest/query/list/${type}`,
        url: `/zouk/rest/query/list`,
        method: 'GET',
        params: data
    })
}
export const getSearchListApiPag = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/query/page',
        method: 'POST',
        data: data
    })
}


// 保存查询列表
export const saveSearchListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/message/rest/query/save',
        url: '/zouk/rest/query/save',
        method: 'POST',
        data: data
    })
}
// 编辑查询列表
export const editSearchListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/message/rest/query/update',
        url: '/zouk/rest/query/update',
        method: 'POST',
        data: data
    })
}
// 查询列表详情
export const getSearchDetailApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: `/zouk/rest/query/detail/${id}`,
        method: 'GET',
    })
}
// 删除查询列表
export const delSearchListApi = (id) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: `/zouk/message/rest/query/remove/${id}`,
        url: `/zouk/rest/query/remove/${id}`,
        method: 'POST',
    })
}


// 导出
export const exportDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        responseType: "blob",
        url: `/zouk/rest/dataMetricsSearch/exportLocalExcel`,
        method: 'POST',
        data: data
    })
}

// 分组应用导出
export const exportUseDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        responseType: "blob",
        url: `/zouk/rest/dataMetricsSearch/exportExcelWithUse`,
        method: 'POST',
        data: data
    })
}


// 根据数据间隔和查询id获取数据
export const getChartDataByQueryIdApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        // url: '/zouk/rest/dataMetricsSearch/getChartDataByQueryId',
        url: '/zouk/rest/dataMetricsSearch/getChartDataByGroup',
        method: 'POST',
        data: data,
    })
}

// 数据间隔下拉列表
export const getIntervalListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/listTimeUnitSelect',
        method: 'POST',
        data: data,
    })
}


// 方案分组****************************************************************
// 根据项目获取设备选项
export const getEquipmentSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/listEquipmentSelect',
        method: 'POST',
        data: data,
    })
}
// 获取应用设备列表
export const getUseEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/query/getEquipment',
        method: 'POST',
        data: data,
    })
}
// 获取应用设备列表
export const getUseEquipmentListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/query/listUseEquipment',
        method: 'POST',
        data: data,
    })
}
// 保存应用设备
export const saveUseEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/query/saveEquipment',
        method: 'POST',
        data: data,
    })
}


// 获取项目树-id带前缀
export const getTreePrefix = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTreePrefix',
        method: 'POST',
        data: data,
    })
}

