import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import Admin from "@/pages/admin/admin";
import Electrovalence from "@/pages/electrovalence/electrovalence";
import Tissue from "@/pages/tissue/tissue";
import Role from "@/pages/role/role";
import Login from "@/pages/login/login";
import UserSetting from "@/pages/userSetting/userSetting";
import MessagePage from "@/pages/messagePage/messagePage";
import VersionDetail from "@/pages/electrovalence/versionDetail/versionDetail"
import EditVersion from "@/pages/electrovalence/editVersion/editVersion"
import Menu from "@/pages/menu/menu"
import RateAllocation from "@/pages/rateAllocation/rateAllocation"
import EditConfig from "@/pages/rateAllocation/editConfig/editConfig"
import ConfigDetail from "@/pages/rateAllocation/configDetail/configDetail"
import Configuration from '@/pages/micrigrid/configuration/configuration'
import Control from '@/pages/micrigrid/control/control'
import RelatedItems from '@/pages/micrigrid/control/relatedItems'
import Strategy from '@/pages/micrigrid/strategy/strategy'
import SaveStrategy from '@/pages/micrigrid/strategy/saveStrategy/saveStrategy'
import StrategyLog from '@/pages/micrigrid/strategy/strategyLog/strategyLog'
import ExecutionLog from '@/pages/micrigrid/executionLog/executionLog'
import DictionarySet from '@/pages/dictionary/dictionarySet'
import DictionaryClassify from '@/pages/dictionary/dictionaryClassify'
import WorkOrder from '@/pages/workOrder/workOrder'
import Warn from '@/pages/warn/warn'
import WarnDetail from '@/pages/warn/warnDetail'
import ConfigPage from '@/pages/configPage/configPage'
// import Project from '@/pages/project/projectCopy/project'
import Project from '@/pages/project/project'
import ProjectLocal from '@/pages/projectLocal/project'
import ProjectData from '@/pages/project/projectData/projectData'
import ProjectWarning from '@/pages/project/projectWarning/projectWarning'
import Parser from '@/pages/parser/parser'
import ParserItem from '@/pages/parser/parserItem'
import ProductCategory from '@/pages/productCategory/productCategory'
import Product from '@/pages/product/product'
import ProductDetail from '@/pages/product/productDetail'
import Device from '@/pages/device/device'
import DeviceLocal from '@/pages/deviceLocal/device'
import DeviceManagementLocal from '@/pages/deviceLocal/deviceManagement'
import MicroInterDevice from '@/pages/microInterDevice/device'
import DeviceManagement from '@/pages/device/deviceManagement'
import OldDevice from '@/pages/oldDevice/device'
import OldDeviceManagement from '@/pages/oldDevice/deviceManagement'
import Log from '@/pages/log/log'
import CompoundQuery from '@/pages/compoundQuery/compoundQuery'
import CompoundQueryPlan from '@/pages/compoundQuery/compoundQueryPlan/compoundQueryPlan'
import Gateway from '@/pages/gateway/gateway'
import GatewayDetail from '@/pages/gateway/gatewayDetail'
import DataSearch from '@/pages/dataSearch/dataSearch'
import ReadSearch from '@/pages/readSearch/readSearch'
import ReadSearchPlan from '@/pages/readSearch/readSearchPlan'
import SearchPlan from '@/pages/dataSearch/searchPlan/searchPlan'
import ApplicationPlan from '@/pages/dataSearch/searchPlan/applicationPlan'
import Alarm from '@/pages/warn/alarm/alarm'
import Offline from '@/pages/warn/offline/offline'
import Subscription from '@/pages/subscription/subscription'
import Customized from '@/pages/project/customized/customized'
import CustomizedLocal from '@/pages/projectLocal/customized/customized'
import Dashboard from '@/pages/dashboard/dashboard'
import HomePage from '@/pages/homePage/homePage'
import HomeSearchScreen from '@/pages/homePage/homeSearchScreen'
import EnergyLargeScreen from '@/pages/homePage/energyLargeScreen'
import OnceImg from '@/pages/onceImg/onceImg'
import OnceImgScreen from '@/pages/onceImg/onceImgScreen'
import WaterMeter from '@/pages/waterMeter/waterMeter'
import Programme from '@/pages/micrigrid/programme/programme'
import HegfScreen from '@/pages/bigScreen/hegfScreen/hegfScreen'
import HecnScreen from '@/pages/bigScreen/hecnScreen/hecnScreen'
import HewwScreen from '@/pages/bigScreen/hewwScreen/hewwScreen'
import LjsScreen from '@/pages/bigScreen/ljsScreen/ljsScreen'
import FxlcnScreen from '@/pages/bigScreen/fxlcnScreen/fxlcnScreen'
import FxlwwScreen from '@/pages/bigScreen/fxlwwScreen/fxlwwScreen'
import BigScreenConfigure from '@/pages/bigScreenManage/bigScreenConfigure/bigScreenConfigure'
import BigScreenAuthority from '@/pages/bigScreenManage/bigScreenAuthority/bigScreenAuthority'
import UpPrise from '@/pages/rateAllocation/upPrise/upPrise'
import Monitoring from '@/pages/dataAcquisition/monitoring/monitoring'
import NorthDriver from '@/pages/dataAcquisition/northDriver/northDriver'  //北向应用
import NorthGroup from '@/pages/dataAcquisition/northDriver/northGroup'  //北向应用组
import NorthDetail from '@/pages/dataAcquisition/northDriver/northDetail'  //应用详情
import SouthDriver from '@/pages/dataAcquisition/southDriver/southDriver'
import DeviceDetail from '@/pages/dataAcquisition/southDriver/deviceDetail'
import DeviceDetailLocal from '@/pages/dataAcquisition/southDriverLocal/deviceDetail'
import Group from '@/pages/dataAcquisition/southDriver/group/group'
import GroupLocal from '@/pages/dataAcquisition/southDriverLocal/group/group'
import Point from '@/pages/dataAcquisition/southDriver/point/point'
import PointLocal from '@/pages/dataAcquisition/southDriverLocal/point/point'
import SourcePage from '@/pages/dataProcessing/sourcePage/sourcePage'
import RulesPage from '@/pages/dataProcessing/rulesPage/rulesPage'
import AddRules from '@/pages/dataProcessing/rulesPage/addRules'
import AddAction from '@/pages/dataProcessing/rulesPage/addAction'
import DataStatistics from '@/pages/dataProcessing/rulesPage/dataStatistics'
import DataConfigurationPage from '@/pages/dataProcessing/configurationPage/configurationPage'
import EleStatement from '@/pages/statement/eleStatement/eleStatement'
import WaterStatement from '@/pages/statement/waterStatement/waterStatement'
import GasStatement from '@/pages/statement/gasStatement/gasStatement'
import LogQuery from '@/pages/logQuery/logQuery'
import EleChargeQuery from '@/pages/eleChargeQuery/eleChargeQuery'
import EleQuery from '@/pages/eleQuery/eleQuery'
import AlarmQuery from '@/pages/alarmQuery/alarmQuery'
import AlarmTemplate from '@/pages/alarmQuery/alarmTemplate/alarmTemplate'
import AlarmRule from '@/pages/alarmQuery/alarmRule/alarmRule'
import EnergyFlowChart from '@/pages/energyFlowChart/energyFlowChart'  //能流图
import NowData from '@/pages/energySearch/nowData'
import BusinessScreen from '@/pages/bigScreen/businessScreen/businessScreen'  //实时业务概览
import SmartFactory from '@/pages/bigScreen/smartFactory/smartFactory'  //智能工厂数据中心


//光伏电站----------------------------
import PowerStation from '@/pages/powerStation/powerStation'   //光伏电站
import PowerStationDetail from '@/pages/powerStation/powerStationDetail/powerStationDetail'   //光伏电站详情
import PowerStationData from '@/pages/powerStation/powerStationDetail/powerStationData'   //光伏电站数据
import PowerStationWarn from '@/pages/powerStation/powerStationWarn/powerStationWarn'   //光伏电站告警
import PowerStationEquipment from '@/pages/powerStation/powerStationEquipment/powerStationEquipment'   //光伏电站设备
import PowerStationSetting from '@/pages/powerStation/powerStationSetting/powerStationSetting'   //光伏电站配置
import PowerStationVideo from '@/pages/powerStation/powerStationVideo/powerStationVideo'   //光伏电站监控
import PowerStationVideoScreen from '@/pages/powerStation/powerStationVideo/powerStationVideoScreen'   //光伏电站监控
import DataHistory from '@/pages/powerStation/powerStationItem/dataHistory'   //光伏电站追溯历史
//储能电站----------------------------
import EnergyStation from '@/pages/energyStation/powerStation'   //光伏电站
import EnergyStationDetail from '@/pages/energyStation/powerStationDetail/powerStationDetail'   //光伏电站详情
import EnergyStationData from '@/pages/energyStation/powerStationDetail/powerStationData'   //光伏电站数据
import EnergyStationWarn from '@/pages/energyStation/powerStationWarn/powerStationWarn'   //光伏电站告警
import EnergyStationEquipment from '@/pages/energyStation/powerStationEquipment/powerStationEquipment'   //光伏电站设备
import EnergyStationSetting from '@/pages/energyStation/powerStationSetting/powerStationSetting'   //光伏电站配置
import EnergyStationVideo from '@/pages/energyStation/powerStationVideo/powerStationVideo'   //光伏电站监控
import EnergyStationVideoScreen from '@/pages/energyStation/powerStationVideo/powerStationVideoScreen'   //光伏电站监控


// 费率----------------------------
import RateAllocationV2 from '@/pages/rateManagement/rateAllocation/rateAllocation'
import AddRateAllocationV2 from '@/pages/rateManagement/addRateAllocation/addRateAllocation'
import RateAllocationDetailV2 from '@/pages/rateManagement/addRateAllocation/rateAllocationDetail'
import RateMixV2 from '@/pages/rateManagement/rateMix/rateMix'
import AddRateMixV2 from '@/pages/rateManagement/addRateMix/addRateMix'
import HistoricalRecord from '@/pages/rateManagement/addRateMix/historicalRecord'
import AddRateMixDetailV2 from '@/pages/rateManagement/addRateMix/addRateMixDetail'
import RateApplicationV2 from '@/pages/rateManagement/rateApplication/rateApplication'


// 惠生大屏
import HsHomeScreen from '@/pages/bigScreen/hsScreen/hsHomeScreen/hsHomeScreen'
import HsyqScreen from '@/pages/bigScreen/hsScreen/hsyqScreen/hsyqScreen'
import HsysScreen from '@/pages/bigScreen/hsScreen/hsysScreen/hsysScreen'
import HsydScreen from '@/pages/bigScreen/hsScreen/hsydScreen/hsydScreen'
// 武汉大屏
import WhydScreen from '@/pages/bigScreen/whScreen/whydScreen/whydScreen'


import SourcePageEditor from "@/pages/dataProcessing/sourcePage/sourcePageEditor.vue";
import resourcesPage from "@/pages/dataProcessing/configurationPage/resourcesPage.vue";
import Charge from '@/pages/charge/charge.vue';
import StatisticsAnalysisLandlord from '@/pages/statisticsAnalysisLandlord/statisticsAnalysisLandlord.vue';
import StatisticsAnalysisTenant from '@/pages/statisticsAnalysisTenant/statisticsAnalysisTenant.vue';
import Billing from '@/pages/billing/billing.vue';
import Electricity from '@/pages/electricity/electricity.vue';
import Config from '@/pages/config/config.vue';
import Statistics from '@/pages/statistics/statistics.vue';
import User from '@/pages/user/user.vue';
import Order from '@/pages/order/order.vue';
import Box from '@/pages/box/box.vue';
import Model from '@/pages/model/model.vue';
import Permit from '@/pages/permit/permit.vue';
import BoxRole from '@/pages/boxRole/boxRole.vue';
import BoxDetail from '@/pages/box/boxDetail.vue';
import PermitDetail from '@/pages/permit/permitDetail.vue';

// 路由文件
const routes = [
    // 路由的默认路径
    {
        path: '/',
        redirect: "/admin",
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/login', //登陆页
        component: Login,
    },
    {
        path: '/hegfScreen', //洪恩光伏大屏
        component: HegfScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/ljsScreen', //洪恩光伏大屏
        component: LjsScreen,
        meta: {
//          requireAuth: true
        },
    },
    {
        path: '/hecnScreen', //洪恩储能大屏
        component: HecnScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/hewwScreen', //洪恩微网大屏
        component: HewwScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/fxlcnScreen', //丰轩乐储能大屏
        component: FxlcnScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/fxlwwScreen', //丰轩乐微网大屏
        component: FxlwwScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/hsHomeScreen', //惠生首页大屏
        component: HsHomeScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/hsHomeScreen/hsyqScreen', //惠生用气大屏
        component: HsyqScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/hsHomeScreen/hsysScreen', //惠生用水大屏
        component: HsysScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/hsHomeScreen/hsydScreen', //惠生用电大屏
        component: HsydScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/whydScreen', //惠生用水大屏
        component: WhydScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/businessScreen', //实时业务概览大屏
        component: BusinessScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/smartFactory', //实时业务概览大屏
        component: SmartFactory,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/onceImgScreen', //一次图大屏
        component: OnceImgScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/homeSearchScreen', //数据查询大屏
        component: HomeSearchScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/powerStationVideoScreen', //电站管理详情--监控
        component: PowerStationVideoScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/energyStationVideoScreen', //电站管理详情--监控
        component: EnergyStationVideoScreen,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/admin',
        redirect: "/admin/homePage",
        component: Admin,
        meta: {
            requireAuth: true
        },
        children: [{
            path: '/admin/homePage', //首页
            component: HomePage,
            meta: {
                requireAuth: true
            },
        },
            {
                path: '/admin/energyLargeScreen', //能耗大屏
                component: EnergyLargeScreen,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/bigScreenConfigure', //大屏配置
                component: BigScreenConfigure,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/bigScreenAuthority', //大屏权限
                component: BigScreenAuthority,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rateAllocation', //地区电价配置
                component: RateAllocation,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/UpPrise', //上网电价
                component: UpPrise,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/configDetail', //地区电价配置-版本详情
                component: ConfigDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/editConfig', //地区电价配置-版本修改
                component: EditConfig,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/electrovalence', //电价配置页
                component: Electrovalence,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/versionDetail', //电价配置-版本详情
                component: VersionDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/editVersion', //电价配置-版本修改
                component: EditVersion,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/configuration', //参数管理
                component: Configuration,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/control', //控制管理
                component: Control,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/relatedItems', //导入控制
                component: RelatedItems,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/strategy', //策略管理
                component: Strategy,
                name: 'strategy',
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/programme', //方案配置
                component: Programme,
                name: 'programme',
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/saveStrategy', //新增策略
                component: SaveStrategy,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/strategyLog', //策略日志
                component: StrategyLog,
                meta: {
                    requireAuth: true
                },
            },

            {
                path: '/admin/executionLog', //日志管理
                component: ExecutionLog,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dictionarySet', //字典设置
                component: DictionarySet,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dictionaryClassify', //字典分类
                component: DictionaryClassify,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/workOrder', //工单管理
                component: WorkOrder,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/warn', //告警管理
                component: Warn,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/warnDetail', //告警详情
                component: WarnDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/configPage', //配置
                component: ConfigPage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/alarm', //告警详情
                component: Alarm,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/offline', //告警详情
                component: Offline,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/subscription', //告警详情
                component: Subscription,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/project', //项目管理
                component: Project,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/projectLocal', //本地项目管理
                component: ProjectLocal,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/customized', //项目仪表盘管理
                component: Customized,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/customizedLocal', //本地项目仪表盘管理
                component: CustomizedLocal,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/dashboard', //仪表盘配置
                component: Dashboard,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/parser', //抄读管理
                component: Parser,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/parserItem', //抄读项页面
                component: ParserItem,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/productCategory', //产品分类管理
                component: ProductCategory,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/product', //产品管理
                component: Product,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/productDetail', //产品详情页面
                component: ProductDetail,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/device', //设备页面
                component: Device,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/projectData', //设备指标
                component: ProjectData,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/projectWarning', //设备报警
                component: ProjectWarning,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/deviceLocal', //本地设备页面
                component: DeviceLocal,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/microInterDevice', //微断设备页面
                component: MicroInterDevice,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/deviceManagement', //设备管理页面
                component: DeviceManagement,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/deviceManagementLocal', //设备管理页面
                component: DeviceManagementLocal,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/oldDevice', //老设备页面
                component: OldDevice,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/oldDeviceManagement', //老设备管理页面
                component: OldDeviceManagement,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/gateway', //网关页面
                component: Gateway,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/gatewayDetail', //网关详情
                component: GatewayDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dataSearch', //数据查询
                component: DataSearch,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/readSearch', //数据查询
                component: ReadSearch,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/readSearchPlan', //数据查询
                component: ReadSearchPlan,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/searchPlan', //查询方案
                component: SearchPlan,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/applicationPlan', //查询方案 ---- 应用
                component: ApplicationPlan,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/log', //日志
                component: Log,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/compoundQuery', //复合查询
                component: CompoundQuery,
                meta: {
                    requireAuth: true
                },
            }, {
                path: '/admin/compoundQueryPlan', //复合查询方案
                component: CompoundQueryPlan,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/menu', //菜单配置
                component: Menu,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/tissue', //组织管理页
                component: Tissue,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/role', //角色管理页
                component: Role,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/userSetting', //用户设置页
                component: UserSetting,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/messagePage', //消息页
                component: MessagePage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/onceImg', //一次图
                component: OnceImg,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/waterMeter', //水表管理
                component: WaterMeter,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/monitoring', //数据监控
                component: Monitoring,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/northDriver', //北向应用
                component: NorthDriver,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/northGroup', //北向应用组
                component: NorthGroup,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/northDetail', //北向应用详情
                component: NorthDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/southDriver', //南向设备
                component: SouthDriver,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/deviceDetail', //设备详情页
                component: DeviceDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/deviceDetailLocal', //本地设备详情页
                component: DeviceDetailLocal,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/group', //组列表页
                component: Group,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/groupLocal', //本地组列表页
                component: GroupLocal,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/point', //点位列表页
                component: Point,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/pointLocal', //本地点位列表页
                component: PointLocal,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/sourcePage', //源管理
                component: SourcePage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rulesPage', //规则
                component: RulesPage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/addRules/:actionObj', //规则
                component: AddRules,
                name: 'AddRules',
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/addAction/:actionObj', //添加动作
                component: AddAction,
                name: 'AddAction',
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dataStatistics', //配置
                component: DataStatistics,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dataConfigurationPage', //配置
                component: DataConfigurationPage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/sourcePage/sourcePageEditor', //源管理编辑页
                component: SourcePageEditor,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/eleStatement', //用电报表
                component: EleStatement,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/waterStatement', //用水报表
                component: WaterStatement,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/gasStatement', //用气报表
                component: GasStatement,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/logQuery', //日志查询
                component: LogQuery,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/eleChargeQuery', //电费查询
                component: EleChargeQuery,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/eleQuery', //电量查询
                component: EleQuery,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/alarmQuery', //告警查询
                component: AlarmQuery,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/alarmTemplate', //告警模版
                component: AlarmTemplate,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/alarmRule', //告警规则
                component: AlarmRule,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyFlowChart', //能流图
                component: EnergyFlowChart,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStation', //电站管理
                component: PowerStation,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationDetail', //电站管理详情
                component: PowerStationDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationData', //电站管理详情--数据
                component: PowerStationData,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationWarn', //电站管理详情--告警
                component: PowerStationWarn,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationEquipment', //电站管理详情--设备
                component: PowerStationEquipment,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationSetting', //电站管理详情--配置
                component: PowerStationSetting,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dataHistory', //电站管理详情--追溯历史
                component: DataHistory,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/powerStationVideo', //电站管理详情--监控
                component: PowerStationVideo,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStation', //电站管理
                component: EnergyStation,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationDetail', //电站管理详情
                component: EnergyStationDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationData', //电站管理详情--数据
                component: EnergyStationData,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationWarn', //电站管理详情--告警
                component: EnergyStationWarn,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationEquipment', //电站管理详情--设备
                component: EnergyStationEquipment,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationSetting', //电站管理详情--配置
                component: EnergyStationSetting,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/energyStationVideo', //电站管理详情--监控
                component: EnergyStationVideo,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/dataConfigurationPage/resourcesPage', //配置编辑页
                component: resourcesPage,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/nowData', //实时数据页面
                component: NowData,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/charge', //结算管理
                component: Charge,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/statisticsAnalysisLandlord', //房东统计分析
                component: StatisticsAnalysisLandlord,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/statisticsAnalysisTenant', //租户统计分析
                component: StatisticsAnalysisTenant,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/billing', //计费管理
                component: Billing,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/electricity', //电费管理
                component: Electricity,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/config', //配置管理
                component: Config,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/statistics', //统计分析
                component: Statistics,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/user', //用户管理
                component: User,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/order', //工单管理
                component: Order,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/box', //用电箱管理
                component: Box,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/boxDetail', //用电箱管理详情
                component: BoxDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/model', //用电箱型号管理
                component: Model,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/permit', //用电作业许可证
                component: Permit,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/permitDetail', //用电作业许可证详情
                component: PermitDetail,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/boxRole', //角色管理
                component: BoxRole,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rateAllocationV2', //费率配置
                component: RateAllocationV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/addRateAllocationV2', //新增费率配置
                component: AddRateAllocationV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rateAllocationDetailV2', //新增费率配置
                component: RateAllocationDetailV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rateMixV2', //电价组合包
                component: RateMixV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/addRateMixV2', //新增电价组合包
                component: AddRateMixV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/historicalRecord', //新增电价组合包
                component: HistoricalRecord,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/addRateMixDetailV2', //新增电价组合包详情
                component: AddRateMixDetailV2,
                meta: {
                    requireAuth: true
                },
            },
            {
                path: '/admin/rateApplicationV2', //电价应用管理
                component: RateApplicationV2,
                meta: {
                    requireAuth: true
                },
            },

        ]
    },
    {
        path: '/fengxuanle', //丰轩乐电力一次图
        component: () =>
            import('@/pages/fengxuanle/fengxuanle.vue'),
        meta: {
            requireAuth: true
        },
    },
]

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})
export default router;
