<template>
  <div class="hsysScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head"></div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
        </div>
        <!--        <div class="logo">-->
        <!--          <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/fsScreen/hsHomeLogo.png">-->
        <!--        </div>-->
        <div class="leftBox">
          <div class="leftBoxItem">
            <div class="getWhydScreenApi chart"></div>
          </div>
          <div class="leftBoxItem">
            <div class="legend">
              <p>用电量</p>
              <p class="rect"></p>
            </div>
            <div class="chart7_2 chart"></div>
          </div>
          <div class="leftBoxItem">
            <div class="chart7_3 chart"></div>
            <div class="echartsBox">
            </div>
          </div>
          <img alt="" class="line1" src="../../../../assets/img/fxlLine.png">
          <img alt="" class="line2" src="../../../../assets/img/fxlLine.png">
        </div>
        <div class="centerBox">
          <div class="leftImgBox">
            <count-up :end-val="data.totalAmount" :start-val="countStart1" class="val"></count-up>
            <span class="title">总用电量(kwh)</span>
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/whScreen/whydItem1.png"/>
          </div>
          <div class="leftImgBox">
            <count-up :end-val="data.totalRate" :start-val="countStart2" class="val"></count-up>
            <span class="title">总功率(W)</span>
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/whScreen/whydItem2.png"/>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightBoxItem">
            <div class="chart7_4 chart"></div>
          </div>
          <div class="rightBoxItem">
            <div class="chart7_5 chart"></div>
          </div>
          <div class="rightBoxItem">
            <div class="chart7_6 chart"></div>
          </div>
          <img alt="" class="line1" src="../../../../assets/img/fxlLine.png">
          <img alt="" class="line2" src="../../../../assets/img/fxlLine.png">
        </div>
      </div>
    </v-scale-screen>
  </div>
</template>

<script setup>
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import './whydScreen.less'
import * as echarts from "echarts";
import {getWhydScreenApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import Circuit from "@/components/fxlcircuit.vue";
import CountUp from 'vue-countup-v3'
import useCircuit from "@/hooks/useCircuit";

// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hngfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '.' + month + '.' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}
let data = ref({})

//数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)

// 获取大屏数据
const getDataFun = async function () {
  await getWhydScreenApi().then(res => {
    //处理刷新
    if (data.value.totalRate != res.data.result.totalRate) {
      countStart1.value = data.value.totalRate
    }
    if (data.value.totalAmount != res.data.result.totalAmount) {
      countStart2.value = data.value.totalAmount
    }
    if (data.value.rankList && data.value.rankList.length > 0) {
      if (JSON.stringify(data.value.rankList) != JSON.stringify(res.data.result.rankList)) {
        nextTick(() => {
          chartLoad1()
          chartLoad3()
        })
      }
    }
    if (data.value.hourList && data.value.hourList.length > 0) {
      if (JSON.stringify(data.value.hourList) != JSON.stringify(res.data.result.hourList)) {
        nextTick(() => {
          chartLoad2()
        })
      }
    }
    //			if(data.value.rateList && data.value.rateList.length > 0) {
    //				if(JSON.stringify(data.value.rateList) != JSON.stringify(res.data.result.rateList)) {
    //					chartLoad3()
    //				}
    //			}
    if (data.value.day && data.value.day.length > 0) {
      if (JSON.stringify(data.value.day) != JSON.stringify(res.data.result.day)) {
        nextTick(() => {
          chartLoad4()
        })
      }
    }
    if (data.value.month && data.value.month.length > 0) {
      if (JSON.stringify(data.value.month) != JSON.stringify(res.data.result.month)) {
        nextTick(() => {
          chartLoad5()
        })
      }
    }
    if (data.value.year && data.value.year.length > 0) {
      if (JSON.stringify(data.value.year) != JSON.stringify(res.data.result.year)) {
        nextTick(() => {
          chartLoad6()
        })
      }
    }
    /*处理数据*/
    if (data.value.rankList && data.value.rankList.length > 0) {
      if (JSON.stringify(data.value.rankList) != JSON.stringify(res.data.result.rankList)) {
        chartLoad1()
      }
    }
    if (data.value.hourList && data.value.hourList.length > 0) {
      if (JSON.stringify(data.value.hourList) != JSON.stringify(res.data.result.hourList)) {
        chartLoad2()
      }
    }
    if (data.value.rateList && data.value.rateList.length > 0) {
      if (JSON.stringify(data.value.rateList) != JSON.stringify(res.data.result.rateList)) {
        chartLoad3()
      }
    }

    if (data.value.day && data.value.day.length > 0) {
      if (JSON.stringify(data.value.day) != JSON.stringify(res.data.result.day)) {
        chartLoad4()
      }
    }
    if (data.value.month && data.value.month.length > 0) {
      if (JSON.stringify(data.value.month) != JSON.stringify(res.data.result.month)) {
        chartLoad5()
      }
    }
    if (data.value.year && data.value.year.length > 0) {
      if (JSON.stringify(data.value.year) != JSON.stringify(res.data.result.year)) {
        chartLoad6()
      }
    }
    if (res.data.code === 0) {
      data.value = res.data.result
      //当日用电量排名
      data.value.rankListX = []
      data.value.rankListY = []
      for (let i = 0; i < data.value.rankList.length; i++) {
        data.value.rankListX.push(data.value.rankList[i].key + ' ')
        data.value.rankListY.push(Number(data.value.rankList[i].value))
      }
      //每小时用电量
      // data.value.hourListX = []
      // data.value.hourListY = []
      // for (let i = 0; i < data.value.hourList.length; i++) {
      //   data.value.hourListX.push(data.value.hourList[i].key + ' ')
      //   data.value.hourListY.push(Number(data.value.hourList[i].value))
      // }
      data.value.hourListX = data.value.day.timeList
      data.value.hourListY = []
      for (let i = 0; i < data.value.day.timeList.length; i++) {
        let total = 0
        for (let j = 0; j < data.value.day.list.length; j++) {
          total = total + Number(data.value.day.list[j].value[i])
        }
        data.value.hourListY[i] = total
      }
      //用电占比
      data.value.rateListY = []
      data.value.rateListY = JSON.parse(JSON.stringify(data.value.rankList))
      data.value.rateListY.forEach(item => {
        item.name = item.key
        item.value = Number(item.value)
      })
      //当日用电量数据
      data.value.dayX = []
      data.value.dayY = []
      data.value.dayX = data.value.day.timeList
      data.value.dayY = data.value.day.list
      colorFun(data.value.dayY)
      data.value.dayY.forEach((item, index) => {
        item.name = item.key
        // item.type = 'bar'
        item.type = 'line'
        // item.stack = '当日用电量'
        item.barWidth = 16
        item.data = item.value
      })
      //当月用电量数据
      data.value.monthX = []
      data.value.monthY = []
      data.value.monthX = data.value.month.timeList
      data.value.monthY = data.value.month.list
      colorFun(data.value.monthY)
      data.value.monthY.forEach((item, index) => {
        item.name = item.key
        // item.type = 'bar'
        item.type = 'line'
        // item.stack = '当日用电量'
        item.barWidth = 16
        item.data = item.value
      })
      //当年用电量数据
      data.value.yearX = []
      data.value.yearY = []
      data.value.yearX = data.value.year.timeList
      data.value.yearY = data.value.year.list
      colorFun(data.value.yearY)
      data.value.yearY.forEach((item, index) => {
        item.name = item.key
        item.type = 'bar'
        item.stack = '当日用电量'
        item.barWidth = 16
        item.data = item.value
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const colorFun = function (arr) {
  arr.forEach((item, index) => {
    if (index == 0) {
      item.itemStyle = {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{
              offset: 0,
              color: '#1B63CC'
            }, //柱图渐变色
              {
                offset: 1,
                color: '#03DFFC'
              }
            ]
        )
      }
    } else if (index == 1) {
      item.itemStyle = {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{
              offset: 0,
              color: '#099D4E'
            },
              {
                offset: 1,
                color: '#6FE3BE'
              }
            ]
        )
      }
    } else if (index == 2) {
      item.itemStyle = {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{
              offset: 0,
              color: '#05A998'
            },
              {
                offset: 1,
                color: '#63FBFC'
              }
            ]
        )
      }
    } else if (index == 3) {
      item.itemStyle = {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{
              offset: 0,
              color: '#4827BC'
            },
              {
                offset: 1,
                color: '#7199EF'
              }
            ]
        )
      }
    } else if (index == 4) {
      item.itemStyle = {
        color: new echarts.graphic.LinearGradient(
            0, 1, 0, 0, [{
              offset: 0,
              color: '#2D5CBD'
            },
              {
                offset: 1,
                color: '#40A0FA'
              }
            ]
        )
      }
    }
  })
}

// 加载左边第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.getWhydScreenApi');
  var myChart = echarts.init(chartDom);
  var option;
  var datav = [891, 1220, 660, 1670, 103],
      option = {
        tooltip: {
          trigger: 'axis', // axis   item   none三个值
          show: true
        },
        grid: {
          top: 20,
          height: 180,
          left: 90,
          right: 80,
          bottom: 100
        },
        xAxis: {
          name: '(kwh)', // 这个设置只在末尾添加单位
          nameTextStyle: {
            //x轴上方单位的颜色
            color: "#CFE1FF",
          },
          type: 'value',
          show: true,
          splitLine: {
            show: false
          },
          offset: 10,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#1E3572'
            }
          },
          axisLabel: {
            margin: 10,
            color: '#CFE1FF',
            /*formatter: function(value, index) {
              if(index + 1 == datav.length) {
                return value + '(kwh)'
              } else {
                return value
              }
            }*/
          }
        },
        yAxis: {
          show: true,
          data: data.value.rankListX,
          inverse: true,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 10,
            color: '#6FA7FF',
            fontSize: 14,
          }
        },
        series: [
          {
            type: 'pictorialBar',
            symbol: 'rect',
            symbolRepeat: 'fixed',
            symbolClip: true,
            symbolSize: [4, 12],
            symbolPosition: "start",
            symbolOffset: [3, -4],
            itemStyle: {
              normal: {
                color: "#B6D5FF",
                shadowColor: '#0078FF',
                shadowBlur: 12,
                shadowOffsetX: 1
              },
            },
            data: data.value.rankListY,
            z: 10,
            label: {
              color: '#CFE1FF',
              show: true,
              position: 'left', // 显示位置
              formatter: function (params) {
                return params.dataIndex + 1 // 自定义显示格式
              }
            }
          },
          //背景
          {
            type: 'pictorialBar',
            itemStyle: {
              opacity: 1,
              normal: {
                color: "#0B3174",
              },
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value
              },
              position: 'right',
              offset: [10, 0],
              color: '#D5E9FF',
              fontSize: 14
            },
            animationDuration: 0,
            symbolRepeat: 'fixed',
            symbol: 'rect',
            symbolSize: [4, 12],
            symbolPosition: "start",
            symbolOffset: [3, -4],
            data: data.value.rankListY,
            z: 5,
            tooltip: {
              show: false
            } //隐藏提示
          }
        ]
      };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }

}
// 加载左边第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.chart7_2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          color: '#CFE1FF'
        }
      },
      axisTick: {
        show: false // 不显示坐标轴刻度线
      },
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      data: data.value.hourListX
    },
    yAxis: {
      type: 'value',
      //				min: function(value) {
      //					return value.min;
      //				},
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(23, 43, 94, 1)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      // {
      //   data: JSON.parse(JSON.stringify(data.value.hourListY)),
      //   type: 'bar',
      //   barWidth: 18,
      //   itemStyle: {
      //     color: 'transparent',
      //     borderWidth: 1,
      //     borderColor: 'rgba(255,255,255,.5)'
      //   },
      //   tooltip: {
      //     show: false
      //   },
      // },
      {
        // type: "pictorialBar", //设置类型为象形柱状图
        name: '用电量',
        type: "line", //设置类型为象形柱状图
        border: 12,
        // symbol: "rect", //图形类型，带圆角的矩形
        symbolMargin: "1", //图形垂直间隔
        // 分隔
        itemStyle: {
          normal: {
            color: "#B0FFEB",
            shadowColor: '#0078FF',
            shadowBlur: 12,
            shadowOffsetX: 1,
          }
        },
        symbolRepeat: "true",
        // symbolSize: [14, 5],
        z: 1,
        data: data.value.hourListY,
      },
      // {
      //   type: "pictorialBar", //设置类型为象形柱状图
      //   symbol: "rect", //图形类型，带圆角的矩形
      //   symbolMargin: "1", //图形垂直间隔
      //   // 分隔
      //   itemStyle: {
      //     normal: {
      //       color: "#094E66",
      //       barBorderColor: "#FFFFFF", // 边框色
      //       barBorderWidth: 1, // 边框宽度
      //     }
      //   },
      //   tooltip: {
      //     show: false
      //   },
      //   label: {
      //     show: false,
      //     position: 'top', // 位置
      //     color: '#f0f0f0',
      //     fontSize: 14,
      //   },
      //   symbolRepeat: "fixed",
      //   symbolSize: [14, 5],
      //   z: 0,
      //   data: data.value.hourListY,
      // }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);
  // nextTick(() => {
  //   let max = myChart.getModel().getComponent('yAxis', 0).axis.scale.getExtent()[1]
  //   for (let i = 0; i < option.series[0].data.length; i++) {
  //     option.series[0].data[i] = max
  //   }
  //   myChart.setOption(option);
  // })

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
//加载左边第三个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.chart7_3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      height: 190,
      right: '20',
      top: 20,
      bottom: 20,
      itemGap: 20,
      textStyle: {
        color: '#D5E9FF',
        fontSize: 14,
        width: 170,
        overflow: 'break',
      },
      tooltip: {
        show: true
      },
      icon: 'circle',
      formatter: function (name) {
        var dataIndex = 0;
        var data = option.series[0].data;
        for (var i = 0; i < data.length; i++) {
          if (data[i].name === name) {
            dataIndex = i;
            break;
          }
        }
        return name + ' ' + (data[dataIndex].value / option.series[0].data.reduce((acc, cur) => acc + cur.value, 0) * 100).toFixed(1) + '%';
      }
    },
    series: [{
      name: '用电量',
      type: 'pie',
      radius: ['30%', '45%'],
      center: ['27.1%', '37%'],
      itemStyle: {
        normal: {
          color: function (colors) {
            var colorList = [
              '#0CE8E1', '#0B86F3', '#F0C944', '#BA58EA', '#62D273'
            ];
            return colorList[colors.dataIndex];
          }
        },
      },
      //						avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'left'
      },
      labelLine: {
        show: false
      },
      data: data.value.rateListY
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
//加载右边第一个
const chartLoad4 = function () {
  var chartDom = document.querySelector('.chart7_4');
  var myChart = echarts.init(chartDom);
  var option;
  app.title = '堆叠柱状图';
  option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      textStyle: {
        color: '#6FA7FF',
        fontSize: 10
      },
      icon: 'rect',
      itemWidth: 13,
      itemHeight: 6,
      left: 'right'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: data.value.dayX,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      axisTick: {
        show: false // 不显示坐标轴刻度线
      },
      axisLabel: {
        textStyle: {
          color: '#CFE1FF'
        }
      },
    }],
    yAxis: [{
      type: 'value',
      splitLine: {
        //分割线配置
        show: true,
        lineStyle: {
          color: "#172B5E",
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    }],
    series: data.value.dayY
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
//加载右边第二个
const chartLoad5 = function () {
  var chartDom = document.querySelector('.chart7_5');
  var myChart = echarts.init(chartDom);
  var option;
  app.title = '堆叠柱状图';
  option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      textStyle: {
        color: '#6FA7FF',
        fontSize: 10
      },
      icon: 'rect',
      itemWidth: 13,
      itemHeight: 6,
      left: 'right'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: data.value.monthX,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      axisTick: {
        show: false // 不显示坐标轴刻度线
      },
      axisLabel: {
        textStyle: {
          color: '#CFE1FF'
        }
      },
    }],
    yAxis: [{
      type: 'value',
      splitLine: {
        //分割线配置
        show: true,
        lineStyle: {
          color: "#172B5E",
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    }],
    series: data.value.monthY
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
//加载右边第三个
const chartLoad6 = function () {
  var chartDom = document.querySelector('.chart7_6');
  var myChart = echarts.init(chartDom);
  var option;
  app.title = '堆叠柱状图';
  option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      textStyle: {
        color: '#6FA7FF',
        fontSize: 10
      },
      icon: 'rect',
      itemWidth: 13,
      itemHeight: 6,
      left: 'right'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: data.value.yearX,
      splitLine: {
        show: false
      },
      axisLine: {
        show: false, // 不显示坐标轴线
      },
      axisTick: {
        show: false // 不显示坐标轴刻度线
      },
      axisLabel: {
        textStyle: {
          color: '#CFE1FF'
        }
      },
    }],
    yAxis: [{
      type: 'value',
      splitLine: {
        //分割线配置
        show: true,
        lineStyle: {
          color: "#172B5E",
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    }],
    series: data.value.yearY,
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId = setInterval(updateTime, 1000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
})
onMounted(async () => {
  document.title = '武汉越秀国际金融中心'
  await getDataFun()
  updateTime()
  nextTick(() => {
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad4()
    chartLoad5()
    chartLoad6()
  })
})
</script>

<style lang="less" scoped>

</style>
