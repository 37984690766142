<template>
  <div class="readSearchPlan">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="router.push('/admin/dataSearch')">读数查询</span>
        <span> / </span>
        <span>读数查询管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        读数查询管理
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增查询-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增查询
        </el-button>
        <!--查询列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增查询方案</p>
            </el-empty>
          </template>
          <el-table-column label="查询名称" prop="queryName"></el-table-column>
          <el-table-column label="创建时间" prop="ct" sortable></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column fixed="right" label="操作" width="200px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="openClick(scope.row.id,scope.row)">打开</span>
                <span v-if="scope.row.canCopyFlag" class="editClass btn"
                      @click.prevent="editClick(scope.row.id,scope.row,'copy')">复制</span>
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass btn delClass" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--新增编辑弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑查询':'新建查询'"
               width="1000px">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="方案名称" prop="queryName">
          <el-input v-model="formData.queryName" placeholder="请输入方案名称"></el-input>
        </el-form-item>
        <el-form-item label="查询日期" prop="timeFlag">
          <el-radio-group v-model="formData.timeFlag" style="margin-bottom: 10px;" @change="timeFlagSelect">
            <el-radio value="now">瞬时</el-radio>
            <el-radio value="-1">自定义时间段</el-radio>
          </el-radio-group>
          <el-date-picker v-if="formData.timeFlag=='-1'" v-model="formData.date" end-placeholder="结束时间"
                          popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" style="margin-left: 40px"
                          type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <div style="display: flex">
          <!--          <el-tree-select-->
          <!--              ref="treeRef"-->
          <!--              v-model="equipmentIdList"-->
          <!--              :check-strictly="true"-->
          <!--              :load="treeList"-->
          <!--              :props="props"-->
          <!--              :render-after-expand="false"-->
          <!--              :render-content="renderContent"-->
          <!--              highlight-current-->
          <!--              lazy-->
          <!--              multiple-->
          <!--              node-key="id"-->
          <!--              popper-class="devicePopper"-->
          <!--              style="height: auto!important;"-->
          <!--              @change="selectFun"-->
          <!--              @remove-tag="removeTag"-->
          <!--          />-->
          <el-select ref="elSelectTable" v-model="equipmentIdList"
                     :disabled="true" :popper-append-to-body="false" :suffix-icon="Plus"
                     class="timeSelect" multiple popper-class="timeSelectPopper"
                     @click="selectDeviceFun">
            <el-option v-for="(item,index) in formData.equipmentList" :key="index"
                       :label="item.value"
                       :value="item.key"></el-option>
          </el-select>
          <el-select v-model="formData.productIdList" class="item" clearable filterable multiple placeholder="请选择产品类型"
                     style="margin: 0 20px;" @change="productChange">
            <el-option v-for="(item_d,index_d) in productList" :key="index_d" :label="item_d.name"
                       :value="item_d.id"></el-option>
          </el-select>
          <el-select v-model="formData.dictIdList" class="item" clearable filterable multiple
                     placeholder="请选择指标">
            <el-option v-for="(item_d,index_d) in dataList" :key="index_d" :label="item_d.name"
                       :value="item_d.id"></el-option>
          </el-select>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <DeviceSelect :checkDeviceList="formData.equipmentList" :deviceVisible="deviceVisible"
                  @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"

import {CloseBold, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  delSearchListApi,
  editSearchListApi,
  getDataListApi,
  getEquipmentListApi, getIntervalListApi, getSearchDetailApi,
  getSearchListApi, getSearchListApiPag, getTreePrefix, getUseEquipmentApi,
  saveSearchListApi
} from "@/api/modules/dataSearch";
import moment from "moment";
import {getDictSelectApi, getDictSelectApi2, getProductSelectApi} from "@/api/modules/alarm";
import DeviceSelect from '@/components/deviceSelect3'


let router = useRouter()
let route = useRouter()

// 返回
const goBack = function () {
  // router.push('/admin/dataSearch')
  router.go(-1)
}


// 方案列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getSearchListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getSearchListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getSearchListFun = function () {
  loading.value = true
  getSearchListApiPag(qs.stringify({
    type: 2,
    pageSize: pageSize.value,
    pageNumber: pageNumber.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)
// 新增编辑表单数据
let formData = ref({
  id: 0,
  queryName: '',
  queryType: 2,
  timeFlag: 'now',
  date: [],
  startTime: '',
  endTime: '',
  creator: window.localStorage.getItem('memberId'),
  equipmentList: [],
  productIdList: [],
  dictIdList: [],
})
// 表单规则
let formRules = ref({
  queryName: [
    {required: true, message: '请输入查询方案名称', trigger: 'blur'},
  ],
  timeFlag: [
    {required: true, message: '请选择日期期限', trigger: 'blur'},
  ],
})
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    id: 0,
    queryName: '',
    queryType: 2,
    timeFlag: 'now',
    date: [],
    startTime: '',
    endTime: '',
    creator: window.localStorage.getItem('memberId'),
    equipmentList: [],
    productIdList: [],
    dictIdList: [],
  }
}
// 点击编辑
let isCopy = ref()
const editClick = function (id, row, copyFlag) {
  isCopy.value = copyFlag
  dialogFormVisible.value = true
  getSearchDetailApi(id).then(res => {
    if (res.data.code === 200) {
      formData.value = {
        id: id,
        queryName: row.queryName,
        queryType: 2,
        timeFlag: res.data.data.timeFlag,
        date: [res.data.data.startTime, res.data.data.endTime],
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        creator: window.localStorage.getItem('memberId'),
        equipmentList: res.data.data.equipmentList,
        productIdList: res.data.data.productIdList,
        dictIdList: res.data.data.dictIdList,
      }
      equipmentIdList.value = []
      for (let i = 0; i < formData.value.equipmentList.length; i++) {
        equipmentIdList.value.push(formData.value.equipmentList[i].key)
      }
      getDataSelectFun()
      if (copyFlag) {
        formData.value.id = 0
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if ((formData.value.timeFlag === '-1' && formData.value.date.length === 0) || formData.value.equipmentList.length === 0 || formData.value.productIdList.length === 0 || formData.value.dictIdList.length === 0) {
          ElMessage({
            type: 'info',
            message: '请输入必填项'
          })
        } else {
          let data = JSON.parse(JSON.stringify(formData.value))
          data.startTime = data.date[0]
          data.endTime = data.date[1]
          if (formData.value.id) {
            editSearchListApi(data).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          } else {
            saveSearchListApi(data).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
        }
      }
    })
  }
}


// 设备选择-----------------------------------------------------------------
let equipmentIdList = ref([])
let deviceVisible = ref(false)
const selectDeviceFun = function () {
  deviceVisible.value = true
}
const deviceVisibleFun = function (data) {
  deviceVisible.value = data
}
const checkDeviceListFun = function (data) {
  formData.value.equipmentList = data
}
watch(() => formData.value.equipmentList, (newVal) => {
  equipmentIdList.value = []
  for (let i = 0; i < newVal.length; i++) {
    equipmentIdList.value.push(newVal[i].key)
  }
}, {deep: true, immediate: true})


// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除查询方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此查询方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delSearchListApi(id).then(res => {
      if (res.data.code === 200) {
        getSearchListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}

// 点击打开
const openClick = function (id, row) {
  router.push('/admin/readSearch?id=' + id)
}


// 日期快捷选择事件
const timeFlagSelect = function () {
  if (formData.value.timeFlag === '-1') {
    formData.value.date = []
  } else if (formData.value.timeFlag === '1H') {
    formData.value.date = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '12H') {
    formData.value.date = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1D') {
    formData.value.date = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '3D') {
    formData.value.date = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1W') {
    formData.value.date = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === 'THIS_WEEK') {
    formData.value.date = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1M') {
    formData.value.date = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1Q') {
    formData.value.date = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // formData.value.date = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
// 产品列表
let productList = ref([])
// 获取产品列表
const getProductListFun = function () {
  getProductSelectApi().then(res => {
    if (res.data.code === 0) {
      productList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const productChange = function () {
  getDataSelectFun()
  formData.value.dictIdList = []
}


// 树形结构处理-----------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  if (node.level === 0) {
    await getTreePrefix(qs.stringify({
      id: 0,
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (node.data && node.data.subList && node.data.subList.length > 0) {
    return resolve(node.data.subList)
  } else {
    await getTreePrefix(qs.stringify({
      id: node.data.id,
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  // await getTreePrefix(qs.stringify({
  //   id: node.data.id ? node.data.id : 0,
  // })).then(res => {
  //   if (res.data.code === 0) {
  //     return resolve(res.data.result)
  //   } else {
  //     ElMessage({
  //       type: 'error',
  //       message: res.data.message
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })

}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data && node.data.id && node.data.id.includes('e')) {
      return true
    }
  },
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}
// 自定义树形结构的前面图标
const renderContent = (h, {node, data}) => {
  return h('div', [
    h('img', {
      src: data.id.includes('p') ? 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon5.png' : 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon4.png',
      style: {
        width: '16px',
        height: '16px',
        transform: 'translateY(3px)',
        marginRight: '5px'
      }
    }, ''),
    h('span', null, node.label)
  ])
}


// 获取指标列表
let dataList = ref([])
const getDataSelectFun = function () {
  getDictSelectApi2(qs.stringify({
    productIdList: formData.value.productIdList.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      dataList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


let isAddBtn = ref(false) //判断是否是新增按钮进来
onMounted(() => {
  getSearchListFun()
  getProductListFun()


  if (router.currentRoute.value.query.flag) {
    isAddBtn.value = true
    dialogFormVisible.value = true
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})


watch(dialogFormVisible, (newVal) => {
  if (!newVal) {
    isAddBtn.value = false
  }
})

</script>

<style lang="less">
.readSearchPlan {
  width: 100%;
  height: 100%;

  //标题
  .title {
    width: calc(100% - 36px);
    height: 84px;
    padding-left: 36px;
    line-height: 48px;
    //background-color: #EDF0F6;

    p:nth-child(1) {
      color: rgba(0, 0, 0, .45);

      span:nth-child(1):hover, span:nth-child(3):hover {
        color: #165DFF;
        cursor: pointer;
      }

      span:nth-child(5) {
        color: rgba(0, 0, 0, .85);
      }
    }

    p:nth-child(2) {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;

      .el-icon {
        font-size: 18px;
        font-weight: bold;
        transform: translateY(3px);
        cursor: pointer;
      }

      .el-icon:hover {
        color: #165DFF;
      }
    }
  }

  //内容
  .content {
    width: calc(100% - 56px);
    height: calc(100% - 108px);
    padding: 0 28px 24px;
    overflow: auto;


    //列表内容
    .contain {
      width: calc(100% - 48px);
      padding: 16px 24px 0;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

      //新增
      .addBtn {
        font-weight: 400;
        margin-bottom: 16px;

        .el-icon {
          margin-right: 5px;
        }
      }

      //表格
      .el-table {
        color: rgba(3, 10, 26, 0.85);

        .el-table__body-header, .el-table__header {
          th {
            background-color: rgb(250, 250, 250);
            font-weight: 500;
          }

          //th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before, th:nth-child(8)::before, th:nth-child(9)::before {
          //  content: '';
          //  width: 1px;
          //  height: 1.6em;
          //  position: absolute;
          //  left: 0;
          //  top: 50%;
          //  transform: translateY(-50%);
          //  background-color: rgba(0, 0, 0, 0.06);
          //}
        }

        th, td {
          padding: 0;
        }

        .cell {
          padding: 14px 16px;

          .opacity {
            display: flex;
            align-items: center;

            .btn {
              color: #165DFF;
              cursor: pointer;
            }

            .delClass {
              color: #EF2121;
            }

            img {
              width: 24px;
              cursor: pointer;
            }

            .btn, img {
              margin-right: 12px;
            }
          }

          .confBox {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .contBox {
              display: flex;
              align-items: center;
            }

            .contItem {
              /*width: 100px;*/
              max-width: 150px;
              height: 60px;
              padding: 0 5px;
              background-color: #f6f6f6;
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              margin: 5px 10px;
              //color: #ccc;

              .name {
                font-size: 13px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }


              .time, .valUnit {
                font-size: 12px;
              }

              .val {
                font-size: 16px;
              }

              .valcopy {
                font-size: 12px;
                text-align: left !important;
                line-height: 14px;
              }
            }
          }
        }
      }

      //分页
      .pag {
        width: 100%;
        height: 32px;
        padding: 16px 0;

        .el-pagination {
          float: right;
        }
      }
    }
  }

  //弹窗
  .el-dialog {
    min-width: 1000px;

    .el-dialog__header {
      border-bottom: 1px solid #efefef;
      margin: 0;
    }

    .el-dialog__footer {
      border-top: 1px solid #efefef;
      margin: 0;
    }

    .el-form {
      //display: flex;
      //flex-wrap: wrap;

      > .el-form-item {
        //width: 40%;
        margin-right: 0;
        display: flex;
        height: 32px;

        .el-select {
          width: 100%;
        }

        .el-input, .el-select, .el-select__wrapper {
          width: 220px;
        }

        .el-input-group__append {
          .el-input.el-input--suffix {
            width: 90px !important;

            .el-input__wrapper {
              background-color: #fff !important;
            }
          }
        }
      }

      .rowItem {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 18px;

        > span {
          margin-right: 10px;
        }

        .el-form-item:first-child {
          .el-input, .el-select, .el-select__wrapper {
            width: 220px;
          }
        }


        .el-form-item {
          margin-bottom: 0 !important;
          margin-right: 16px;
        }

        .data {
          width: 360px;

          .el-input.el-input--suffix {
            width: 360px;
          }
        }

        .addIcon, .delIcon {
          font-size: 18px;
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    .el-select__wrapper.is-disabled.el-tooltip__trigger.el-tooltip__trigger {
      background: #fff;
      cursor: pointer;
    }

  }

  //方案应用弹窗
  .el-drawer {
    .el-drawer__body {
      padding: 0;
      border-top: 1px solid #ccc;
    }

    .searchDrawerBox {
      padding: 20px 20px 20px 26px;
      width: 390px;
    }

    .el-drawer__header {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 30px);
      padding-top: 15px;
      margin-bottom: 15px;
    }

    .el-drawer__footer {
      border-top: 1px solid #E5E6EB;
    }

    .closeClass {
      display: inline-block;
      vertical-align: middle;
      width: 6%;
    }

    .tltleClass {
      display: inline-block;
      width: 30%;
      font-size: 16px;
      color: #000;
    }

    .operBox {
      width: calc(64% - 40px);
      display: inline-block;
      text-align: right;
    }

    .el-form-item {
      display: flex;
    }

    .el-form-item__label {
      font-size: 15px;
    }

    .el-input__validateIcon {
      display: none;
    }

    .el-form-item {
      label {
        color: #000;
        font-size: 14px;
      }

      p {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .el-form {
      p {
        color: rgba(0, 0, 0, 0.45);
      }
    }


    .transferRightBox {
      flex: 57;
      height: calc(100% - 100px);
      margin-left: 20px;
      border-radius: 3px;
      border: 1px solid #DDDDDD;

      .head {
        width: 100%;
        height: 39px;
        background: #F5F7FA;
        text-indent: 10px;
        line-height: 39px;
        font-weight: 400;
        color: #000000;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          margin-right: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 16px;
            height: 16px;
          }

          span {
            color: #165DFF;
          }
        }
      }

      .transferContain {
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 62px);
        overflow: auto;


        .transferContainHead {
          background-color: #F5F7FA;
          height: 40px;
          line-height: 40px;
          border: 1px solid #E5E6EB;
          border-collapse: collapse;
          font-weight: 500;
          display: flex;

          p:first-of-type {
            max-width: 40px;
            border-left: 0;
          }

          p:nth-child(2) {
            max-width: 60px;
          }

          p {
            flex: 1;
            text-indent: 10px;
            border-left: 1px solid #E5E6EB;
          }
        }

        .transferContainCont {
          .transferContainItem {
            display: flex;
            height: 40px;
            line-height: 40px;

            .border {
              height: 40px;
              border: 1px solid #E5E6EB;
            }

            p {
              flex: 1;
              text-indent: 10px;
              border: 1px solid #E5E6EB;
              position: relative;

              > span {
                display: block;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .el-icon {
                position: absolute;
                font-size: 16px;
                color: #FF798B;
                top: 12px;
                right: 16px;
                cursor: pointer;
                display: none;
              }
            }

            p:hover {
              .el-icon {
                display: block;
              }
            }

            p:first-of-type {
              max-width: 40px;
            }

            p:nth-child(2) {
              max-width: 60px;
            }
          }
        }
      }
    }
  }
}
</style>
