<template>
  <div class="addRateMix">
    <div class="title">
      <p>
        <span @click="goBack">费率组合包</span>
        <span> / </span>
        <span>{{ router.currentRoute.value.query.id ? '编辑' : '新增' }}费率组合包</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        {{ router.currentRoute.value.query.id ? '编辑' : '新增' }}费率组合包
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <el-form
            ref="strategyFormRef"
            :model="strategyForm"
            :rules="strategyRules"
            class="strategyForm"
            status-icon
        >
          <!--组合包选项-->
          <div class="topBox">
            <el-form-item class="topFormItem" label="组合包名称" prop="name">
              <el-input v-model="strategyForm.name" placeholder="请输入组合包名称"/>
            </el-form-item>
            <el-form-item class="topFormItem" label="是否公开" prop="openFlag" style="width: 180px;">
              <el-radio-group v-model="strategyForm.openFlag">
                <el-radio :value="1">是</el-radio>
                <el-radio :value="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="topFormItem topFormItem2" label="绑定设备" prop="bindDevice" style="width: 100%">
              <el-select ref="elSelectTable" v-model="strategyForm.equipmentIdList"
                         :disabled="true" :popper-append-to-body="false" :suffix-icon="Plus"
                         class="timeSelect" multiple popper-class="timeSelectPopper"
                         @click="selectTimeFun">
                <el-option v-for="(item,index) in bindDevice" :key="index"
                           :label="item.value"
                           :value="item.key"></el-option>
              </el-select>
              <p class="more" @click="moreClick">更多
                <el-icon>
                  <DArrowRight/>
                </el-icon>
              </p>
            </el-form-item>
          </div>
          <div style="text-indent: 5px;margin-bottom: 10px;">绑定时段方案:</div>
          <!--分时策略数据-->
          <div v-for="(item_p,index_p) in strategyForm.priceList" :key="index_p" class="strategy">
            <p class="delIcon" @click="delPlanClick(index_p)">
              <el-icon>
                <CircleCloseFilled/>
              </el-icon>
            </p>
            <!--选择方案-->
            <div class="topBox">
              <el-form-item class="topFormItem" label="方案名称">
                <el-select v-model="item_p.id" placeholder="请选择方案名称" @change="planSelectFun(index_p)">
                  <el-option v-for="(item_t,index_t) in ratePriceSelect" :key="index_t" :label="item_t.name"
                             :value="item_t.id">{{ item_t.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="item_p.type!=2" class="topFormItem" label="生效日期">
                <el-date-picker v-model="item_p.startTime" popper-class="date-style"
                                type="datetime"
                                value-format="YYYY-MM-DD HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-if="item_p.type==1" class="topFormItem timeFormItem" label="时间段"
                            required>
                <el-date-picker v-model="item_p.monthTimeList[0].monthTime"
                                :disabled="true"
                                format="MM月"
                                placeholder="请选择月份"
                                style="width: 500px;"
                                type="months"
                                value-format="MM"
                />
              </el-form-item>
              <el-form-item v-if="item_p.type==2" class="topFormItem timeFormItem" label="时间段">
                <el-select ref="elSelectTable" v-model="item_p.monthTimeList" :disabled="true"
                           :popper-append-to-body="false" :suffix-icon="Plus"
                           class="timeSelect" multiple popper-class="timeSelectPopper">
                </el-select>
              </el-form-item>
              <!--展开收起-->
              <div class="isUnfoldBox">
                <p v-show="!item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                  <span>展开</span>
                  <el-icon>
                    <CaretBottom/>
                  </el-icon>
                </p>
                <p v-show="item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                  <span>收起</span>
                  <el-icon>
                    <CaretTop/>
                  </el-icon>
                </p>
              </div>
            </div>
            <div v-show="item_p.id" class="strategy">
              <div class="strategyBox strategyBox1">
                <div :class="'chart'+index_p" class="chart"></div>
                <el-table v-show="item_p.isUnfold" :data="item_p.timeList" style="width: 100%;margin: 16px 0 ;">
                  <el-table-column label="时间范围" min-width="400">
                    <template #default="scope">
                      <div class="timerBox" style="max-width: 300px">
                        <el-time-picker v-model="scope.row.listTime"
                                        :disabled="true"
                                        end-placeholder="结束时间"
                                        format="HH:mm"
                                        is-range
                                        range-separator="-"
                                        start-placeholder="开始时间"
                                        value-format="HH:mm"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="计价类型" min-width="220">
                    <template #default="scope">
                      <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                        <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                   :value="item.key"></el-option>
                        <template #label="{ label, value }">
                        <span v-if="value==1"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                          <span v-if="value==2"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                          <span v-if="value==3"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                          <span v-if="value==4"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                          <span v-if="value==5"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                        </template>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table v-if="false" v-show="item_p.isUnfold" :data="item_p.priceList">
                  <el-table-column label="计价类型" min-width="220">
                    <template #default="scope">
                      <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                        <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                   :value="item.key"></el-option>
                        <template #label="{ label, value }">
                         <span v-if="value==1"
                               style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                          <span v-if="value==2"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                          <span v-if="value==3"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                          <span v-if="value==4"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                          <span v-if="value==5"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                        </template>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="电价" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.value" :disabled="true">
                        <template #append>元/kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="false" v-show="item_p.isUnfold" class="strategyBox strategyBox2">
                <div class="pricingMethod">
                  <span>计价方式：{{ item_p.ladderType == 1 ? '按月用量' : '按年用量' }}</span>
                </div>
                <el-table :data="item_p.ladderList" style="width: 100%;margin: 16px 0 ;">
                  <el-table-column label="档级" min-width="150">
                    <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}档</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="下限（>）" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.downValue" :disabled="true">
                        <template #append>kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="上限（≤）" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.upValue" :disabled="true">
                        <template #append>kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="电价浮动" min-width="220">
                    <template #default="scope">
                      <span style="margin-right: 5px">+</span>
                      <el-input v-model="scope.row.value" :disabled="true">
                        <template #append>元/kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <!--新增电价方案-->
          <div class="addPlanBox">
            <el-button type="primary" @click="addPlanClick">
              <el-icon>
                <Plus/>
              </el-icon>
              添加时段方案
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="contain" style="margin: 20px 0;">
        <!--电价表格********************************************-->
        <div class="priceBox">
          <div class="priceHeadBox">
            <p class="name">电价方案：</p>
            <div class="operation">
              <el-button :icon="Plus" type="primary" @click="addPriceClick">新增</el-button>
              <el-button v-if="router.currentRoute.value.query.type==='edit'" :icon="Clock" el-icon
                         @click="lookHistorical">历史记录
              </el-button>
            </div>
          </div>
          <el-table v-loading="loading" :data="tableData" class="tableBox" style="width: 100%">
            <template #empty>
              <el-empty class="emptyClass" description="暂无数据信息"
                        image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                <p>您可以在此页面新增电价</p>
              </el-empty>
            </template>
            <el-table-column label="生效日期" prop="startTime"/>
            <el-table-column label="费率" prop="priceList">
              <template #default="scope">
                <div v-for="(item,index) in scope.row.priceList" :key="index" class="item">
                  <p v-if="item.type==1" class="type1 typeBox">尖</p>
                  <p v-if="item.type==2" class="type2 typeBox">峰</p>
                  <p v-if="item.type==3" class="type3 typeBox">平</p>
                  <p v-if="item.type==4" class="type4 typeBox">谷</p>
                  <p v-if="item.type==5" class="type5 typeBox">深谷</p>
                  <el-input v-model="item.value" disabled>
                    <template #append>元/kwh</template>
                  </el-input>
                </div>
                <div v-for="(item,index) in scope.row.ladderList" :key="index" class="item">
                  <p class="type6 typeBox">第{{ index + 1 }}档</p>
                  <p>{{ item.downValue }}-{{ item.upValue }} kwh &nbsp; +{{ item.value }} 元/kwh</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="inUseFlag">
              <template #default="scope">
                <p :style="{background:scope.row.inUseFlag == 1?'#E8FFEA':'#FEF4E5',color:scope.row.inUseFlag == 1?'#00B42A':'#F78E00'}"
                   class="status">
                  {{
                    scope.row.inUseFlag == 1 ? '正在使用' : '等待中'
                  }}</p>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <div class="opacity">
                  <span class="btn" style="cursor: pointer;color: #165DFF;margin-right: 16px"
                        @click="editClick(scope,scope.$index)">修改</span>
                  <span class="btn delClass" style="color: #EF2121;cursor: pointer"
                        @click="delClick(scope,scope.$index)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--取消保存操作-->
      <div class="footer">
        <el-button class="resetButton" @click="goBack">取消</el-button>
        <el-button type="primary" @click="submitClick">确认</el-button>
      </div>
    </div>
    <!--绑定弹窗-->
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" class="bindDialog" title="绑定设备" width="80%">
      <div class="dialogCont">
        <div class="leftBox">
          <div class="head">待选项:</div>
          <div class="leftBoxCont">
            <div class="transferContentLeft">
              <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                       :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                       :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
                <template #default="{ node, data }">
                  <span>{{ node.label }}</span>
                </template>
              </el-tree>
            </div>
            <div class="transferContRight">
              <el-popover :visible="checkAllVisible" :width="224" placement="top">
                <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                  <div style="display:flex;align-items: center;">确定要将设备全部添加到应用中吗？</div>
                  <div style="display:flex;justify-content: end;height: 32px;">
                    <el-button size="small" @click="checkAllVisible = false">取消</el-button>
                    <el-button size="small" type="primary" @click="equipmentSelectAllClick">确定</el-button>
                  </div>
                </div>
                <template #reference>
                  <!--                  <p @click="checkAllVisible=true">全选</p>-->
                  <el-button type="primary" @click="checkAllVisible=true">全选</el-button>
                </template>
              </el-popover>
              <p v-for="(item,index) in equipmentSelectList" :key="index"
                 :style="{cursor:item.enableFlag==1?'no-drop':'pointer'}"
                 @click="addEquipmentFun(item)">
                {{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="head" style="display: flex;justify-content: space-between">
            <p>已绑定的设备:</p>
            <el-popover :visible="delAllVisible" :width="224" placement="top">
              <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                <div style="display:flex;align-items: center;">确定要将应用设备设备清空吗？</div>
                <div style="display:flex;justify-content: end;height: 32px;">
                  <el-button size="small" @click="delAllVisible = false">取消</el-button>
                  <el-button size="small" type="primary" @click="delAllClick">确定</el-button>
                </div>
              </div>
              <template #reference>
                <div style="display: flex;align-items: center;cursor: pointer" @click="delAllVisible=true">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/clear.png"
                       style="width: 16px;height: 16px;">
                  <span style="color: #165DFF;transform: translateX(-10px)">清空</span>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="deviceBox">
            <p v-for="(item,index) in checkList" :key="index">
              <span>{{ item.value }}</span>
              <el-icon @click="delEquipmentFun(index)">
                <CircleCloseFilled/>
              </el-icon>
            </p>
          </div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addDeviceFun">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--已绑定的设备弹窗-->
    <el-dialog v-model="dialogVisible2" :close-on-click-modal="false" title="已绑定的设备" width="80%">
      <div class="dialogCont">
        <div class="deviceBox">
          <p v-for="(item,index) in bindDevice" :key="index">
            <span>{{ item.value }}</span>
          </p>
        </div>
      </div>
    </el-dialog>
    <!--新增电价弹窗-->
    <el-dialog v-model="dialogVisible3" :close-on-click-modal="false" class="priceDialog" title="新建" width="80%">
      <div class="dialogCont" style="display: block">
        <div class="timeBox">
          <span>生效日期</span>
          <el-date-picker
              v-model="priceFormData.startTime"
              :disabled-date="disabledDate"
              placeholder="请选择生效时间"
              type="date"
              value-format="YYYY-MM-DD"
          />
        </div>
        <div class="titName">分时计费</div>
        <el-table :data="priceFormData.priceList">
          <el-table-column label="计价类型" min-width="220">
            <template #default="scope">
              <span v-if="scope.row.type==1"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
              <span v-if="scope.row.type==2"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
              <span v-if="scope.row.type==3"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
              <span v-if="scope.row.type==4"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
              <span v-if="scope.row.type==5"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
            </template>
          </el-table-column>
          <el-table-column label="电价" min-width="220">
            <template #default="scope">
              <el-input v-model="scope.row.value" style="width: 180px">
                <template #append>元/kwh</template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
        <div class="strategyBox strategyBox2">
          <div class="titName">阶梯计费</div>
          <div class="pricingMethod">
            <span>计价方式：</span>
            <el-radio-group v-model="priceFormData.ladderType">
              <el-radio :value="1">按月用量</el-radio>
              <el-radio :value="2">按年用量</el-radio>
            </el-radio-group>
          </div>
          <el-table :data="priceFormData.ladderList" style="width: 100%;margin: 16px 0 ;">
            <el-table-column label="档级" min-width="150">
              <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}档</span>
              </template>
            </el-table-column>
            <el-table-column label="下限（>）" min-width="220">
              <template #default="scope">
                <el-input v-model="scope.row.downValue">
                  <template #append>kwh</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="上限（≤）" min-width="220">
              <template #default="scope">
                <el-input v-model="scope.row.upValue">
                  <template #append>kwh</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="电价浮动" min-width="220">
              <template #default="scope">
                <div style="display: flex;align-items: center">
                  <span style="margin-right: 5px">+</span>
                  <el-input v-model="scope.row.value">
                    <template #append>元/kwh</template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100">
              <template #default="scope">
                <ul class="operationUl">
                  <li v-if="scope.$index!==0" class="deleteClass" @click="delTime2(scope.$index)">
                    <el-icon>
                      <Delete/>
                    </el-icon>
                  </li>
                  <li class="editClass" @click="addTime2(scope.$index)">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="dialogVisible3 = false">取消</el-button>
		    		<el-button type="primary" @click="addPriceFun">确定</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import './addRateMix.less'
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"
import {
  Back,
  Clock,
  Plus, Delete,
  QuestionFilled,
  Timer,
  CaretBottom,
  CaretTop,
  CircleCloseFilled, DArrowRight
} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import * as echarts from "echarts";
import {
  addPowerPriceGroupListApi,
  addPowerPriceListApi,
  getPowerPriceDetailApi, getPowerPriceGroupDetailApi, getPowerPriceGroupTreeApi,
  getPowerPriceSelectApi,
  getTimeShareSelectApi,
  getPowerPriceGroupPriceListApi,
  addPowerPriceGroupPriceListApi,
  delPowerPriceGroupPriceListApi
} from "@/api/modules/rate";
import {delSearchListApi, getEquipmentSelectApi, getTreePrefix} from "@/api/modules/dataSearch";
import {getProjectListApi} from "@/api/modules/project";

let router = useRouter()
// 返回-------------------------------------------------------------------
const goBack = function () {
  router.push('/admin/rateMixV2')
}


// 时间列表
let timeList = ref(['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'])


// 绑定设备操作-------------------------------------------------------------------
// 点击新增设备
let checkList = ref([])   //选中的设备
const selectTimeFun = function () {
  dialogVisible.value = true
  checkList.value = bindDevice.value
}
let dialogVisible = ref(false)  //时间段弹窗
// 点击添加
const addEquipmentFun = function (item) {
  if (item.enableFlag == 0) {
    let flag = false
    for (let i = 0; i < checkList.value.length; i++) {
      if (checkList.value[i].key === item.id) {
        flag = true
      }
    }
    if (!flag) {
      checkList.value.push({
        key: item.id,
        value: item.name
      })
    } else {
      ElMessage({
        type: 'info',
        message: '该设备已选中'
      })
    }
  } else {
    ElMessage({
      type: 'info',
      message: '该设备在其他组合包中已使用'
    })
  }
}
const delEquipmentFun = function (idx) {
  checkList.value.splice(idx, 1)
}
// 全选应用设备
let checkAllVisible = ref(false)
const equipmentSelectAllClick = function () {
  for (let i = 0; i < equipmentSelectList.value.length; i++) {
    addEquipmentFun(equipmentSelectList.value[i])
  }
  checkAllVisible.value = false
}
// 点击将设备添加到选择框内
let bindDevice = ref([])
const addDeviceFun = function () {
  bindDevice.value = checkList.value
  strategyForm.value.equipmentIdList = []
  for (let i = 0; i < checkList.value.length; i++) {
    strategyForm.value.equipmentIdList.push(checkList.value[i].key)
  }
  dialogVisible.value = false
}
// 清空应用设备
let delAllVisible = ref(false)
const delAllClick = function () {
  checkList.value = []
  delAllVisible.value = false
}
// 树级列表
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let defaultExpandKeys = ref() //默认选中树级列表第一级
let treeRef = ref()
let customerList = ref([])
//获取项目树级列表
const getProjectListFun = async function () {
  await getPowerPriceGroupTreeApi(qs.stringify({
    projectId: 0,
    id: router.currentRoute.value.query.id ? router.currentRoute.value.query.id : 0
  })).then(res => {
    if (res.data.code === 0) {
      customerList.value = res.data.result
      defaultExpandKeys.value = [customerList.value[0].id]
      //有无路由参数
      currentNodeKey.value = customerList.value[0].id
      custerObj.value = customerList.value[0]
      nextTick(() => {
        if (treeRef.value) {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        }
      })
      getEquipmentSelectFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  defaultExpandKeys.value.push(e.parentId)
  getEquipmentSelectFun()
}
// 根据项目获取设备选项
let equipmentSelectList = ref([])
const getEquipmentSelectFun = function () {
  getPowerPriceGroupTreeApi(qs.stringify({
    projectId: custerObj.value.id,
    id: router.currentRoute.value.query.id ? router.currentRoute.value.query.id : 0
  })).then(res => {
    if (res.data.code === 0) {
      equipmentSelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 加载图表
let xAxios = ref([])
let yAxios = ref([])
const chartLoad = function (idx) {
  var chartDom = document.querySelector(`.chart${idx}`);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 20,
      right: 20,
      bottom: 20,
      top: 40,
    },
    legend: {
      show: true,
    },
    tooltip: {
      show: false
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        },
        interval: 59
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      data: xAxios.value[idx]
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: yAxios.value[idx],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 点击查看更多设备
let dialogVisible2 = ref(false)
const moreClick = function () {
  dialogVisible2.value = true
}


// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: router.currentRoute.value.query.id && router.currentRoute.value.query.type === 'edit' ? router.currentRoute.value.query.id : 0,
  name: '',
  openFlag: 0,
  equipmentIdList: [],
  priceList: [{
    id: '',
    provinceName: '',
    startTime: '',
    monthTimeList: [],
    isUnfold: true,
    timeList: [{
      listTime: [],
      type: '',
    }],
    priceList: [],
    ladderType: 1,
    ladderList: [{
      downValue: 0,
      upValue: '∞',
      value: '',
    }],
  }]
})
// 版本表单验证规则
let strategyRules = ref({
  name: [
    {required: true, message: '请输入名称', trigger: 'blur'},
  ]
})


// 新增删除电价方案-------------------------------------------------------------------
// 新增
const addPlanClick = function () {
  strategyForm.value.priceList.push({
    id: '',
    provinceName: '',
    startTime: '',
    monthTimeList: [],
    isUnfold: false,
    timeList: [{
      listTime: [],
      type: '',
    }],
    priceList: [],
    ladderType: 1,
    ladderList: [{
      downValue: 0,
      upValue: '∞',
      value: '',
    }],
  })
}
// 删除
const delPlanClick = function (idx) {
  strategyForm.value.priceList.splice(idx, 1)
}


// 提交表单----------------------------------------------------------------------
// 表单元素
let strategyFormRef = ref()
// 提交表单
const submitClick = () => {
  // 提交表单
  if (strategyFormRef) {
    strategyFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let flag = false
        if (router.currentRoute.value.query.type !== 'edit' && tableData.value.length === 0) {
          flag = true
        }
        if (flag) {
          ElMessageBox.confirm('', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            title: '提示',
            message: h('p', null, [
              h('img', {
                src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
                style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
              }, ''),
              h('span', {style: 'color:#595959;'}, '暂无电价，是否要继续提交'),
            ])
          }).then(() => {
            let arr = []
            for (let i = 0; i < strategyForm.value.priceList.length; i++) {
              arr.push({
                powerPriceId: strategyForm.value.priceList[i].id,
                startTime: strategyForm.value.priceList[i].startTime,
              })
            }
            let data = {
              id: strategyForm.value.id,
              name: strategyForm.value.name,
              openFlag: strategyForm.value.openFlag,
              equipmentIdList: strategyForm.value.equipmentIdList.join(','),
              subJsonStr: JSON.stringify(arr),
            }
            addPowerPriceGroupListApi(qs.stringify(data)).then(res => {
              if (res.data.code === 0) {
                // 判断如果是编辑就直接返回 新增则提交电价
                if (router.currentRoute.value.query.type === 'edit' || tableData.value.length === 0) {
                  router.push('/admin/rateMixV2')
                } else {
                  strategyForm.value.id = res.data.result
                  let num = 0
                  for (let i = 0; i < tableData.value.length; i++) {
                    num++
                    let data = JSON.parse(JSON.stringify(tableData.value[i]))
                    data.ladderJsonStr = JSON.stringify(data.ladderList)
                    data.priceJsonStr = JSON.stringify(data.priceList)
                    data.groupId = res.data.result
                    data.id = 0
                    addPowerPriceGroupPriceListApi(qs.stringify(data)).then(res => {
                      if (res.data.code === 0) {
                        if (num === tableData.value.length) {
                          router.push('/admin/rateMixV2')
                        }
                      } else {
                        ElMessage({
                          type: 'error',
                          message: res.data.message
                        })
                      }
                    }).catch(err => {
                      console.log(err);
                    })
                  }
                }
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message,
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消提交'
            });
          });
        } else {
          let arr = []
          for (let i = 0; i < strategyForm.value.priceList.length; i++) {
            arr.push({
              powerPriceId: strategyForm.value.priceList[i].id,
              startTime: strategyForm.value.priceList[i].startTime,
            })
          }
          let data = {
            id: strategyForm.value.id,
            name: strategyForm.value.name,
            openFlag: strategyForm.value.openFlag,
            equipmentIdList: strategyForm.value.equipmentIdList.join(','),
            subJsonStr: JSON.stringify(arr),
          }
          addPowerPriceGroupListApi(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              // 判断如果是编辑就直接返回 新增则提交电价
              if (router.currentRoute.value.query.type === 'edit' || tableData.value.length === 0) {
                router.push('/admin/rateMixV2')
              } else {
                strategyForm.value.id = res.data.result
                let num = 0
                for (let i = 0; i < tableData.value.length; i++) {
                  num++
                  let data = JSON.parse(JSON.stringify(tableData.value[i]))
                  data.ladderJsonStr = JSON.stringify(data.ladderList)
                  data.priceJsonStr = JSON.stringify(data.priceList)
                  data.groupId = res.data.result
                  data.id = 0
                  addPowerPriceGroupPriceListApi(qs.stringify(data)).then(res => {
                    if (res.data.code === 0) {
                      if (num === tableData.value.length) {
                        router.push('/admin/rateMixV2')
                      }
                    } else {
                      ElMessage({
                        type: 'error',
                        message: res.data.message
                      })
                    }
                  }).catch(err => {
                    console.log(err);
                  })
                }
              }
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message,
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      }
    })
  }
};


const planSelectFun = function (idx) {
  ratePlanDetailFun(strategyForm.value.priceList[idx].id)
}
// 获取电价方案详情
const ratePlanDetailFun = function (id) {
  getPowerPriceDetailApi(qs.stringify({id: id})).then(res => {
    if (res.data.code === 0) {
      for (let i = 0; i < strategyForm.value.priceList.length; i++) {
        if (id == strategyForm.value.priceList[i].id) {
          strategyForm.value.priceList[i] = JSON.parse(JSON.stringify(res.data.result))
          // 处理时间段
          if (strategyForm.value.priceList[i].type === 2) {
            let data = []
            for (let j = 0; j < strategyForm.value.priceList[i].monthTimeList.length; j++) {
              data.push(strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(8, 2) + '日-' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(8, 2) + '日')
            }
            strategyForm.value.priceList[i].monthTimeList = data
          } else {
            strategyForm.value.priceList[i].monthTimeList[0].monthTime = strategyForm.value.priceList[i].monthTimeList[0].monthTime.split(',')
          }


          // 处理电价时间段
          for (let j = 0; j < strategyForm.value.priceList[i].timeList.length; j++) {
            strategyForm.value.priceList[i].timeList[j].listTime = [strategyForm.value.priceList[i].timeList[j].startTime, strategyForm.value.priceList[i].timeList[j].endTime]
          }
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 监听方案变化
watch(() => strategyForm.value.priceList, (newVal) => {
  yAxios.value = []
  xAxios.value = []
  let list = []
  let xAxiosArr = []
  for (let i = 0; i < newVal.length; i++) {
    list[i] = []
    for (let j = 0; j < newVal[i].timeList.length; j++) {
      list[i].push({
        type: 'line',
        data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0],
        color: '#3587DA',
        lineStyle: {
          width: 1,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, null, 0, 1, [
            {
              offset: 0,
              color: 'rgb(188, 221, 246)'
            },
            {
              offset: 1,
              color: 'rgba(188, 221, 246,0)'
            }
          ])
        }
      })
      if (newVal[i].timeList[j].type == 1) {
        list[i][j].color = '#F21110'
        list[i][j].name = '尖'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(246, 165, 167)'
            },
            {
              offset: 1,
              color: 'rgba(246, 165, 167,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 2) {
        list[i][j].color = '#F57244'
        list[i][j].name = '峰'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(247, 216, 200)'
            },
            {
              offset: 1,
              color: 'rgba(247, 216, 200,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 3) {
        list[i][j].color = '#50D5D9'
        list[i][j].name = '平'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(202, 242, 246)'
            },
            {
              offset: 1,
              color: 'rgba(202, 242, 246,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 4) {
        list[i][j].color = '#3587DA'
        list[i][j].name = '谷'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(189, 222, 247)'
            },
            {
              offset: 1,
              color: 'rgba(189, 222, 247,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 5) {
        list[i][j].color = '#748EE3'
        list[i][j].name = '深谷'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(216, 226, 248)'
            },
            {
              offset: 1,
              color: 'rgba(216, 226, 248,0)'
            }
          ])
        }
      }
    }

    // 时间排序
    // xAxiosArr[i] = []
    // for (let j = 0; j < newVal[i].timeList.length; j++) {
    //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[0]) && newVal[i].timeList[j].listTime[0]) {
    //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[0])
    //   }
    //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[1]) && newVal[i].timeList[j].listTime[1]) {
    //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[1])
    //   }
    //   // 时间排序
    //   xAxiosArr[i].sort(function (a, b) {
    //     // 将时间字符串转换为24小时制的分钟数
    //     var minA = new Date('1970-01-01T' + a + 'Z').getTime();
    //     var minB = new Date('1970-01-01T' + b + 'Z').getTime();
    //     return minA - minB;
    //   });
    //   // xAxios.value = xAxiosArr
    // }
    xAxiosArr[i] = []
    for (let k = 0; k <= 23; k++) {
      for (let j = 0; j <= 59; j = j + 1) {
        let hour = k < 10 ? "0" + k : k.toString();
        let minute = j < 10 ? "0" + j : j.toString();
        xAxiosArr[i].push(hour + ":" + minute);
      }
    }


    for (let j = 0; j < newVal[i].timeList.length; j++) {
      let startTime = ''
      let endTime = ''
      if (newVal[i].timeList[j].startTime && newVal[i].timeList[j].endTime && newVal[i].timeList[j].type) {
        for (let k = 0; k < xAxiosArr[i].length; k++) {
          if (newVal[i].timeList[j].startTime === xAxiosArr[i][k]) {
            startTime = k
          }
          if (newVal[i].timeList[j].endTime === xAxiosArr[i][k]) {
            endTime = k
          }
        }
      }
      for (let k = startTime; k < endTime + 1; k++) {
        // list[i][j].data[k] = Number(newVal[i].timeList[j].price)
        if (newVal[i].timeList[j].type == 1) {
          list[i][j].data[k] = 10
        } else if (newVal[i].timeList[j].type == 2) {
          list[i][j].data[k] = 8
        } else if (newVal[i].timeList[j].type == 3) {
          list[i][j].data[k] = 6
        } else if (newVal[i].timeList[j].type == 4) {
          list[i][j].data[k] = 4
        } else if (newVal[i].timeList[j].type == 5) {
          list[i][j].data[k] = 2
        }
      }
    }

    xAxios.value = xAxiosArr
    yAxios.value = list
    nextTick(() => {
      chartLoad(i)
    })
  }


}, {deep: true, immediate: true})


// 组合包对应电价的处理****************************************************************
let loading = ref(false)   // 表格加载
// 电价表格
let tableData = ref([])
// 获取电价列表
const getPowerPriceGroupPriceListFun = function () {
  if (router.currentRoute.value.query.id) {
    loading.value = true
    getPowerPriceGroupPriceListApi(qs.stringify({groupId: router.currentRoute.value.query.id, type: 1})).then(res => {
      loading.value = false
      if (res.data.code === 0) {
        tableData.value = res.data.result
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      loading.value = false
      console.log(err);
    })
  } else {
    // tableData.value = []
  }
}
// 禁用今天之前的时间
const disabledDate = function (time) {
  return time.getTime() < Date.now() - 8.64e7; // 8.64e7 毫秒数代表一天
}
// 新增电价弹窗
let dialogVisible3 = ref(false)
let priceFormData = ref({
  id: 0,
  startTime: '',
  priceList: [
    {
      type: 1,
      value: ''
    },
    {
      type: 2,
      value: ''
    },
    {
      type: 3,
      value: ''
    },
    {
      type: 4,
      value: ''
    },
    {
      type: 5,
      value: ''
    }
  ],
  ladderType: 1,
  ladderList: [{
    downValue: '',
    upValue: '',
    value: ''
  }]
})
// 点击新增
const addPriceClick = function () {
  editIdx.value = ''
  editData.value = {}
  dialogVisible3.value = true
  priceFormData.value = {
    id: 0,
    startTime: '',
    priceList: [
      {
        type: 1,
        value: ''
      },
      {
        type: 2,
        value: ''
      },
      {
        type: 3,
        value: ''
      },
      {
        type: 4,
        value: ''
      },
      {
        type: 5,
        value: ''
      }
    ],
    ladderType: 1,
    ladderList: [{
      downValue: '',
      upValue: '',
      value: ''
    }]
  }
}
// 点击编辑
let editIdx = ref()
let editData = ref()
const editClick = function (scope, index) {
  dialogVisible3.value = true
  editIdx.value = index
  editData.value = scope
  if (scope.row.id) {
    priceFormData.value = scope.row
  } else {
    priceFormData.value = tableData.value[index]
  }
}
// 添加档级
const addTime2 = function (idx) {
  priceFormData.value.ladderList.splice(idx + 1, 0, {
    downValue: '',
    upValue: '',
    value: '',
  })
  priceFormData.value.ladderList[idx + 1].downValue = priceFormData.value.ladderList[idx].upValue
}
// 删除档级
const delTime2 = function (idx) {
  priceFormData.value.ladderList.splice(idx, 1)
}
// 新增电价提交
const addPriceFun = function () {
  if (priceFormData.value.startTime) {
    // 判断是新增还是编辑
    if (router.currentRoute.value.query.id && router.currentRoute.value.query.type === 'edit') {
      let data = JSON.parse(JSON.stringify(priceFormData.value))
      data.ladderJsonStr = JSON.stringify(data.ladderList)
      data.priceJsonStr = JSON.stringify(data.priceList)
      data.groupId = router.currentRoute.value.query.id
      delete data.ladderList
      delete data.priceList
      addPowerPriceGroupPriceListApi(qs.stringify(data)).then(res => {
        if (res.data.code === 0) {
          dialogVisible3.value = false
          getPowerPriceGroupPriceListFun()
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      if (editIdx.value !== '') {
        tableData.value[editIdx.value] = priceFormData.value
      } else {
        tableData.value.push(priceFormData.value)
      }
      dialogVisible3.value = false
    }
  } else {
    ElMessage({
      type: 'info',
      message: '请选择生效日期'
    })
  }
}
// 点击删除
const delClick = function (scope, index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除电价方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此电价方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    if (scope.row.id) {
      delPowerPriceGroupPriceListApi(qs.stringify({id: scope.row.id})).then(res => {
        if (res.data.code === 0) {
          getPowerPriceGroupPriceListFun()
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      tableData.value.splice(index, 1)
    }
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 点击查看历史记录
const lookHistorical = function () {
  router.push({
    path: '/admin/historicalRecord',
    query: {
      id: router.currentRoute.value.query.id
    }
  })
}


let powerPriceSelect = ref()
let ratePriceSelect = ref()
onMounted(() => {
  getProjectListFun()
  // 获取电价组合包电价列表
  getPowerPriceGroupPriceListFun()
  // 获取电价类型选项
  getTimeShareSelectApi().then(res => {
    if (res.data.code === 0) {
      powerPriceSelect.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取方案选项
  getPowerPriceSelectApi().then(res => {
    if (res.data.code === 0) {
      ratePriceSelect.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取电价组合包详情
  if (router.currentRoute.value.query.id) {
    // 获取电价组合包详情
    getPowerPriceGroupDetailApi(qs.stringify({
      id: router.currentRoute.value.query.id
    })).then(res => {
      if (res.data.code === 0) {
        bindDevice.value = res.data.result.equipmentList
        strategyForm.value = {
          id: router.currentRoute.value.query.type === 'copy' ? 0 : router.currentRoute.value.query.id,
          equipmentIdList: res.data.result.equipmentIdList,
          name: res.data.result.name,
          openFlag: res.data.result.openFlag,
          priceList: res.data.result.priceList,
        }
        for (let i = 0; i < strategyForm.value.priceList.length; i++) {
          // 处理时间段
          if (strategyForm.value.priceList[i].type === 2) {
            let data = []
            for (let j = 0; j < strategyForm.value.priceList[i].monthTimeList.length; j++) {
              data.push(strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(8, 2) + '日-' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(8, 2) + '日')
            }
            strategyForm.value.priceList[i].monthTimeList = data

          } else {
            strategyForm.value.priceList[i].monthTimeList[0].monthTime = strategyForm.value.priceList[i].monthTimeList[0].monthTime.split(',')
          }
          // 处理电价时间段
          for (let j = 0; j < strategyForm.value.priceList[i].timeList.length; j++) {
            strategyForm.value.priceList[i].timeList[j].listTime = [strategyForm.value.priceList[i].timeList[j].startTime, strategyForm.value.priceList[i].timeList[j].endTime]
          }
        }
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    })
  }
})

</script>

<style lang="less" scoped>
.addRateMix {
  .priceBox {
    .priceHeadBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px;

      .name {

      }

      .operation {

      }
    }

    .tableBox {
      .item {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .el-input {
          width: 160px;
        }

        .typeBox {
          padding: 0 4px;
          color: #fff;
          border-radius: 3px;
          height: 22px;
          line-height: 22px;
          margin-right: 6px;
        }

        .type1 {
          background: #F21110;
        }

        .type2 {
          background: #F57244;
        }

        .type3 {
          background: #50D5D9;
        }

        .type4 {
          background: #3587DA;
        }

        .type5 {
          background: #748EE3;
        }

        .type6 {
          background: #A084FA;
        }

        > p {
          display: inline-block;
        }
      }

      .status {
        padding: 0 8px;
        border-radius: 3px;
        display: inline-block;
      }
    }

  }


  .priceDialog {
    .dialogCont {
      .timeBox {
        display: flex;
        align-items: center;

        > span {
          margin-right: 12px;
        }
      }

      .titName {
        text-indent: 16px;
        margin: 16px 0;
        position: relative;
        color: #333333;
        font-weight: 400;
      }

      .titName::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 16px;
        background: #165DFF;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

    .operationUl {
      display: flex;
      font-size: 16px;
      color: #2064FF;

      .deleteClass {
        color: #EF2121;
        margin-right: 12px;
      }

      .editClass, .deleteClass {
        font-weight: bold;
        transform: translateY(3px);
      }
    }
  }
}
</style>
