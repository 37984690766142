<template>
  <div class="onceImgScreen">
    <div class="el-carousel">
      <p class="el-carousel__arrow el-carousel__arrow--left" @click="leftClick">
        <el-icon>
          <ArrowLeftBold/>
        </el-icon>
      </p>
      <p class="el-carousel__arrow el-carousel__arrow--right" @click="rightClick">
        <el-icon>
          <ArrowRightBold/>
        </el-icon>
      </p>
      <OnceItem1 v-if="onePicAddress.onePicAddress==='OnceItem1'"/>
      <OnceItem2 v-if="onePicAddress.onePicAddress==='OnceItem2'"/>
      <!--得力-->
      <DeliOnceImg1 v-if="onePicAddress.onePicAddress==='DeliOnceImg1'"/>
      <DeliOnceImg2 v-if="onePicAddress.onePicAddress==='DeliOnceImg2'"/>
      <DeliOnceImg3 v-if="onePicAddress.onePicAddress==='DeliOnceImg3'"/>
      <DeliOnceImg4 v-if="onePicAddress.onePicAddress==='DeliOnceImg4'"/>
      <DeliOnceImg5 v-if="onePicAddress.onePicAddress==='DeliOnceImg5'"/>
      <DeliOnceImg6 v-if="onePicAddress.onePicAddress==='DeliOnceImg6'"/>
      <DeliOnceImg7 v-if="onePicAddress.onePicAddress==='DeliOnceImg7'"/>
      <DeliOnceImg8 v-if="onePicAddress.onePicAddress==='DeliOnceImg8'"/>
      <DeliOnceImg9 v-if="onePicAddress.onePicAddress==='DeliOnceImg9'"/>
      <DeliOnceImg10 v-if="onePicAddress.onePicAddress==='DeliOnceImg10'"/>


      <!--武汉-->
      <WhGOnceImg1 v-if="onePicAddress.onePicAddress==='WhGOnceImg1'"/>
      <WhGOnceImg2 v-if="onePicAddress.onePicAddress==='WhGOnceImg2'"/>
      <WhGOnceImg3 v-if="onePicAddress.onePicAddress==='WhGOnceImg3'"/>
      <WhGOnceImg4 v-if="onePicAddress.onePicAddress==='WhGOnceImg4'"/>
      <WhGOnceImg5 v-if="onePicAddress.onePicAddress==='WhGOnceImg5'"/>
      <WhGOnceImg6 v-if="onePicAddress.onePicAddress==='WhGOnceImg6'"/>
      <WhGOnceImg7 v-if="onePicAddress.onePicAddress==='WhGOnceImg7'"/>
      <WhGOnceImg8 v-if="onePicAddress.onePicAddress==='WhGOnceImg8'"/>
      <WhDOnceImg1T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg1T1A'"/>
      <WhDOnceImg1T1B v-if="onePicAddress.onePicAddress==='WhDOnceImg1T1B'"/>
      <WhDOnceImg1T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg1T2A'"/>
      <WhDOnceImg1T2B v-if="onePicAddress.onePicAddress==='WhDOnceImg1T2B'"/>
      <WhDOnceImg1T3A v-if="onePicAddress.onePicAddress==='WhDOnceImg1T3A'"/>
      <WhDOnceImg1T4A v-if="onePicAddress.onePicAddress==='WhDOnceImg1T4A'"/>
      <WhDOnceImg2T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg2T1A'"/>
      <WhDOnceImg2T1B v-if="onePicAddress.onePicAddress==='WhDOnceImg2T1B'"/>
      <WhDOnceImg2T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg2T2A'"/>
      <WhDOnceImg2T2B v-if="onePicAddress.onePicAddress==='WhDOnceImg2T2B'"/>
      <WhDOnceImg2T3A v-if="onePicAddress.onePicAddress==='WhDOnceImg2T3A'"/>
      <WhDOnceImg2T4A v-if="onePicAddress.onePicAddress==='WhDOnceImg2T4A'"/>
      <WhDOnceImg3T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg3T1A'"/>
      <WhDOnceImg3T1B v-if="onePicAddress.onePicAddress==='WhDOnceImg3T1B'"/>
      <WhDOnceImg3T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg3T2A'"/>
      <WhDOnceImg3T2B v-if="onePicAddress.onePicAddress==='WhDOnceImg3T2B'"/>
      <WhDOnceImg3T3A v-if="onePicAddress.onePicAddress==='WhDOnceImg3T3A'"/>
      <WhDOnceImg3T4A v-if="onePicAddress.onePicAddress==='WhDOnceImg3T4A'"/>
      <WhDOnceImg4T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg4T1A'"/>
      <WhDOnceImg4T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg4T2A'"/>
      <WhDOnceImg4T3A v-if="onePicAddress.onePicAddress==='WhDOnceImg4T3A'"/>
      <WhDOnceImg4T3B v-if="onePicAddress.onePicAddress==='WhDOnceImg4T3B'"/>
      <WhDOnceImg4T4A v-if="onePicAddress.onePicAddress==='WhDOnceImg4T4A'"/>
      <WhDOnceImg4T4B v-if="onePicAddress.onePicAddress==='WhDOnceImg4T4B'"/>
      <WhDOnceImg5T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg5T1A'"/>
      <WhDOnceImg5T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg5T2A'"/>
      <WhDOnceImg5T3A v-if="onePicAddress.onePicAddress==='WhDOnceImg5T3A'"/>
      <WhDOnceImg5T3B v-if="onePicAddress.onePicAddress==='WhDOnceImg5T3B'"/>
      <WhDOnceImg5T4A v-if="onePicAddress.onePicAddress==='WhDOnceImg5T4A'"/>
      <WhDOnceImg5T4B v-if="onePicAddress.onePicAddress==='WhDOnceImg5T4B'"/>
      <WhDOnceImg6T1A v-if="onePicAddress.onePicAddress==='WhDOnceImg6T1A'"/>
      <WhDOnceImg6T2A v-if="onePicAddress.onePicAddress==='WhDOnceImg6T2A'"/>
      <WhCOnceImg1 v-if="onePicAddress.onePicAddress==='WhCOnceImg1'"/>
      <WhCOnceImg2 v-if="onePicAddress.onePicAddress==='WhCOnceImg2'"/>


      <!--海盐秦编-->
      <HyOnceImg1 v-if="onePicAddress.onePicAddress==='HyOnceImg1'"/>
      <HyOnceImg2 v-if="onePicAddress.onePicAddress==='HyOnceImg2'"/>


      <!--凯旋校区-->
      <KxOnceImg1 v-if="onePicAddress.onePicAddress==='KxOnceImg1'"></KxOnceImg1>
      <KxOnceImg2 v-if="onePicAddress.onePicAddress==='KxOnceImg2'"></KxOnceImg2>
      <KxOnceImg3 v-if="onePicAddress.onePicAddress==='KxOnceImg3'"></KxOnceImg3>


    </div>
    <el-icon class="close" @click="backClick">
      <CloseBold/>
    </el-icon>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {ArrowLeftBold, ArrowRightBold, CloseBold} from '@element-plus/icons-vue'
import {markRaw, nextTick, onMounted, reactive, ref, shallowRef, watch} from "vue";
import OnceItem1 from "@/pages/onceImg/onceItem/onceItem1";
import OnceItem2 from "@/pages/onceImg/onceItem/onceItem2";
import {getOnceImgDataApiV2} from "@/api/modules/onceImg";
import qs from "qs";
import {ElMessage} from "element-plus";
import router from "@/router";

// 得力一次图
import DeliOnceImg1 from './onceItem/deli/deliOnceImg1'
import DeliOnceImg2 from './onceItem/deli/deliOnceImg2'
import DeliOnceImg3 from './onceItem/deli/deliOnceImg3'
import DeliOnceImg4 from './onceItem/deli/deliOnceImg4'
import DeliOnceImg5 from './onceItem/deli/deliOnceImg5'
import DeliOnceImg6 from './onceItem/deli/deliOnceImg6'
import DeliOnceImg7 from './onceItem/deli/deliOnceImg7'
import DeliOnceImg8 from './onceItem/deli/deliOnceImg8'
import DeliOnceImg9 from './onceItem/deli/deliOnceImg9'
import DeliOnceImg10 from './onceItem/deli/deliOnceImg10'


// 武汉一次图
import WhGOnceImg1 from './onceItem/wuhan/whGOnceImg1'  //10kV中心配电室高压配电系统图一  ---  高压
import WhGOnceImg2 from './onceItem/wuhan/whGOnceImg2'  //10kV中心配电室高压配电系统图二  ---  高压
import WhGOnceImg3 from './onceItem/wuhan/whGOnceImg3'  //2#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg4 from './onceItem/wuhan/whGOnceImg4'  //3#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg5 from './onceItem/wuhan/whGOnceImg5'  //4#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg6 from './onceItem/wuhan/whGOnceImg6'  //5#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg7 from './onceItem/wuhan/whGOnceImg7'  //6#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg8 from './onceItem/wuhan/whGOnceImg8'  //6#变配电房10KV高压配电系统图  ---  高压
import WhDOnceImg1T1A from './onceItem/wuhan/whDOnceImg1_t1A'  //1#变配电房 1-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg1T1B from './onceItem/wuhan/whDOnceImg1_t1B'  //1#变配电房 1-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg1T2A from './onceItem/wuhan/whDOnceImg1_t2A'  //1#变配电房 1-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg1T2B from './onceItem/wuhan/whDOnceImg1_t2B'  //1#变配电房 1-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg1T3A from './onceItem/wuhan/whDOnceImg1_t3A'  //1#变配电房 1-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg1T4A from './onceItem/wuhan/whDOnceImg1_t4A'  //1#变配电房 1-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg2T1A from './onceItem/wuhan/whDOnceImg2_t1A'  //2#变配电房 2-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg2T1B from './onceItem/wuhan/whDOnceImg2_t1B'  //2#变配电房 2-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg2T2A from './onceItem/wuhan/whDOnceImg2_t2A'  //2#变配电房 2-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg2T2B from './onceItem/wuhan/whDOnceImg2_t2B'  //2#变配电房 2-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg2T3A from './onceItem/wuhan/whDOnceImg2_t3A'  //2#变配电房 2-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg2T4A from './onceItem/wuhan/whDOnceImg2_t4A'  //2#变配电房 2-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg3T1A from './onceItem/wuhan/whDOnceImg3_t1A'  //3#变配电房 3-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg3T1B from './onceItem/wuhan/whDOnceImg3_t1B'  //3#变配电房 3-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg3T2A from './onceItem/wuhan/whDOnceImg3_t2A'  //3#变配电房 3-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg3T2B from './onceItem/wuhan/whDOnceImg3_t2B'  //3#变配电房 3-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg3T3A from './onceItem/wuhan/whDOnceImg3_t3A'  //3#变配电房 3-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg3T4A from './onceItem/wuhan/whDOnceImg3_t4A'  //3#变配电房 3-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg4T1A from './onceItem/wuhan/whDOnceImg4_t1A'  //4#变配电房 4-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg4T2A from './onceItem/wuhan/whDOnceImg4_t2A'  //4#变配电房 4-T2 变压器低压配电系统图   ---  低压
import WhDOnceImg4T3A from './onceItem/wuhan/whDOnceImg4_t3A'  //4#变配电房 4-T3 变压器低压配电系统图一   ---  低压
import WhDOnceImg4T3B from './onceItem/wuhan/whDOnceImg4_t3B'  //4#变配电房 4-T3 变压器低压配电系统图二   ---  低压
import WhDOnceImg4T4A from './onceItem/wuhan/whDOnceImg4_t4A'  //4#变配电房 4-T4 变压器低压配电系统图一   ---  低压
import WhDOnceImg4T4B from './onceItem/wuhan/whDOnceImg4_t4B'  //4#变配电房 4-T4 变压器低压配电系统图二   ---  低压
import WhDOnceImg5T1A from './onceItem/wuhan/whDOnceImg5_t1A'  //5#变配电房 5-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg5T2A from './onceItem/wuhan/whDOnceImg5_t2A'  //5#变配电房 5-T2 变压器低压配电系统图   ---  低压
import WhDOnceImg5T3A from './onceItem/wuhan/whDOnceImg5_t3A'  //5#变配电房 5-T3 变压器低压配电系统图一   ---  低压
import WhDOnceImg5T3B from './onceItem/wuhan/whDOnceImg5_t3B'  //5#变配电房 5-T3 变压器低压配电系统图二   ---  低压
import WhDOnceImg5T4A from './onceItem/wuhan/whDOnceImg5_t4A'  //5#变配电房 5-T4 变压器低压配电系统图一   ---  低压
import WhDOnceImg5T4B from './onceItem/wuhan/whDOnceImg5_t4B'  //5#变配电房 5-T4 变压器低压配电系统图二   ---  低压
import WhDOnceImg6T1A from './onceItem/wuhan/whDOnceImg6_t1A'  //6#变配电房 6-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg6T2A from './onceItem/wuhan/whDOnceImg6_t2A'  //6#变配电房 6-T2 变压器低压配电系统图   ---  低压
import WhCOnceImg1 from './onceItem/wuhan/whCOnceImg1'  //1#柴油发电机房低压配电系统图   ---  低压
import WhCOnceImg2 from './onceItem/wuhan/whCOnceImg2'  //2#柴油发电机房低压配电系统图   ---  低压


// 海盐
import HyOnceImg1 from './onceItem/haiyan/hyOnceImg1'  //海盐秦编科技
import HyOnceImg2 from './onceItem/haiyan/hyOnceImg2'  //嘉乐创紧固件


// 凯旋校区
import KxOnceImg1 from './onceItem/kaixuan/kxOnceImg1'  //10kv配电系统图
import KxOnceImg2 from './onceItem/kaixuan/kxOnceImg2'  //TM1低压系统图
import KxOnceImg3 from './onceItem/kaixuan/kxOnceImg3'  //TM2低压系统图


const backClick = function () {
  router.push('/admin/onceImg')
}

const data = reactive([
  {
    name: 'A组件',
    com: markRaw(OnceItem1),
  },
  {
    name: 'B组件',
    com: markRaw(OnceItem2),
  },
])

let onePicAddress = ref({})
let onePicAddressList = ref([])
let idx = ref(router.currentRoute.value.query.idx)
// 获取一次图配置
const getOnceImgDataFun = function () {
  getOnceImgDataApiV2(qs.stringify({
    projectId: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      onePicAddressList.value = res.data.result
      onePicAddress.value = res.data.result[idx.value]
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


const leftClick = function () {
  if (idx.value !== 0) {
    idx.value = Number(idx.value) - 1
    onePicAddress.value = onePicAddressList.value[idx.value]
  }
}

const rightClick = function () {
  if (idx.value !== (onePicAddressList.value.length - 1)) {
    idx.value = Number(idx.value) + 1
    onePicAddress.value = onePicAddressList.value[idx.value]
  }
}

watch(idx, (newVal) => {
  if (newVal !== undefined) {
    nextTick(() => {
      if (newVal === 0) {
        document.querySelector('.el-carousel__arrow--left').style.cursor = 'no-drop'
        document.querySelector('.el-carousel__arrow--left .el-icon').style.cursor = 'no-drop'
      } else {
        document.querySelector('.el-carousel__arrow--left').style.cursor = 'pointer'
        document.querySelector('.el-carousel__arrow--left .el-icon').style.cursor = 'pointer'
      }
      if (newVal === (onePicAddressList.value.length - 1)) {
        document.querySelector('.el-carousel__arrow--right').style.cursor = 'no-drop'
        document.querySelector('.el-carousel__arrow--right .el-icon').style.cursor = 'no-drop'
      } else {
        document.querySelector('.el-carousel__arrow--right').style.cursor = 'pointer'
        document.querySelector('.el-carousel__arrow--right .el-icon').style.cursor = 'pointer'
      }
    })
  }

}, {immediate: true, deep: true})

onMounted(() => {
  getOnceImgDataFun()
})


</script>

<style lang="less">
.onceImgScreen {
  width: calc(100vw - 440px);
  height: calc(100vh - 160px);
  background: #000;
  padding: 80px 220px;
  position: relative;

  .el-carousel {
    width: 100%;
    height: 100%;


    .el-carousel__arrow {
      display: block !important;
      width: 48px;
      height: 48px;
      background: #2F2F2F;
      color: #FFFFFF;
      line-height: 48px;
      border-radius: 6px;
      position: absolute;

      .el-icon {
        font-size: 20px;
        font-weight: 800;
        transform: translateY(6px);
      }
    }

    .el-carousel__arrow--left {
      left: -100px;
    }

    .el-carousel__arrow--right {
      right: -100px;
    }
  }

  .close {
    position: absolute;
    font-size: 18px;
    color: #fff;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
</style>
