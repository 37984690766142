<template>
  <div class="historicalRecord">
    <div class="title">
      <p>
        <span @click="goBack">费率组合包</span>
        <span> / </span>
        <span @click="router.go(-1)">费率组合包详情</span>
        <span> / </span>
        <span>历史记录</span>
      </p>
      <p class="tit">
        <el-icon @click="router.go(-1)">
          <Back/>
        </el-icon>
        历史记录
      </p>
    </div>
    <div class="content">
      <div class="contain">
        <el-timeline>
          <el-timeline-item
              v-for="(item, index) in activities"
              :key="index"
              :color="item.color"
              :size="item.size"
              :timestamp="item.startTime"
              placement="top"
          >
            <div class="cont">
              <div class="priceList">
                <div v-for="(item_c,index_c) in item.priceList" :key="index_c" class="priceItem">
                  <p v-if="item_c.type==1"
                     style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">
                    尖</p>
                  <p v-if="item_c.type==2"
                     style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">
                    峰</p>
                  <p v-if="item_c.type==3"
                     style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">
                    平</p>
                  <p v-if="item_c.type==4"
                     style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">
                    谷</p>
                  <p v-if="item_c.type==5"
                     style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff;width: 60px;">
                    深谷</p>
                  <el-input v-model="item_c.value" :disabled="true">
                    <template #append>元/kwh</template>
                  </el-input>
                </div>
              </div>
              <div class="priceList">
                <div v-for="(item_c,index_c) in item.ladderList" :key="index_c" class="priceItem" style="margin-right:
                10px;">
                  <p
                      style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff;min-width: 60px">
                    第{{ index_c + 1 }}档</p>
                  <p>{{ item_c.downValue }}-{{ item_c.upValue }} kwh &nbsp; +{{ item_c.value }} 元/kwh</p>
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty v-show="activities.length===0"
                  class="emptyClass" description="暂无数据信息"
                  image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {
  Back
} from '@element-plus/icons-vue'
import {getPowerPriceGroupPriceListApi} from "@/api/modules/rate";
import qs from "qs";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()


const goBack = function () {
  router.push('/admin/rateMixV2')
}


let activities = ref([])

// 获取电价列表
const getPowerPriceGroupPriceListFun = function () {
  getPowerPriceGroupPriceListApi(qs.stringify({groupId: router.currentRoute.value.query.id, type: 2})).then(res => {
    if (res.data.code === 0) {
      activities.value = res.data.result
      for (let i = 0; i < activities.value.length; i++) {
        activities.value[i].color = '#165DFF'
      }
      activities.value[0].size = 'large'
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
onMounted(() => {
  getPowerPriceGroupPriceListFun()
})

</script>

<style lang="less" scoped>
.historicalRecord {
  width: 100%;
  height: 100%;

  //标题
  .title {
    width: calc(100% - 36px);
    height: 84px;
    padding-left: 36px;
    line-height: 48px;
    //background-color: #EDF0F6;

    p:nth-child(1) {
      color: rgba(0, 0, 0, .45);

      span:nth-child(1):hover, span:nth-child(3):hover {
        color: #165DFF;
        cursor: pointer;
      }

      span:nth-child(5) {
        color: rgba(0, 0, 0, .85);
      }
    }

    p:nth-child(2) {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;

      .el-icon {
        font-size: 18px;
        font-weight: bold;
        transform: translateY(3px);
        cursor: pointer;
      }

      .el-icon:hover {
        color: #165DFF;
      }
    }
  }

  //内容
  .content {
    width: calc(100% - 56px);
    height: calc(100% - 116px);
    padding: 0 28px;
    overflow: auto;
    //列表内容
    .contain {
      width: calc(100% - 32px);
      height: calc(100% - 32px);
      overflow: auto;
      padding: 16px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    }

    .cont {
      width: 100%;


      .name {
        margin: 20px 0;
        font-weight: 500;
        font-size: 14px;
        color: #1D2129;
      }
    }

    .priceList {
      display: flex;
      margin-bottom: 20px;

      .priceItem {
        display: flex;
        align-items: center;

        > p {
          margin-right: 12px;
          height: 22px;
          text-align: center;
          line-height: 22px;
        }

        .el-input {
          margin-right: 18px;
        }
      }
    }
  }
}
</style>
