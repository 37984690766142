<template>
  <div class="rateApplicationPage">
    <div class="title">
      <p>
        <span>应用查看</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
							</span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <!--设备选择-->
        <div class="headBox">
          <div class="headBoxTit">{{ custerObj.name }}/设备</div>
          <div class="deviceBox">
            <p v-for="(item,index) in equipmentList" :key="index" :class="checkEquipment===item.id?'activeP':''"
               @click="equipmentClick(item.id)">
              {{ item.name }}</p>
          </div>
        </div>
        <!--组合包-->
        <div class="rateGroupBox">
          <div class="searchBox">
            <span>组合包名称：{{ name }}</span>
            <!--            <el-select placeholder="请选择组合包名称">-->
            <!--              <el-option v-for="(item,index) in powerPriceGroupList" :key="index" :label="item.name"-->
            <!--                         :value="item.id"></el-option>-->
            <!--            </el-select>-->
          </div>
          <!--分时策略数据-->
          <div v-for="(item_p,index_p) in strategyForm.priceList" :key="index_p" class="strategy">
            <!--选择方案-->
            <div class="topBox">
              <el-form-item class="topFormItem" label="方案名称">
                <el-select v-model="item_p.id" :disabled="true" placeholder="请选择方案名称" @change="planSelectFun(index_p)">
                  <el-option v-for="(item_t,index_t) in ratePriceSelect" :key="index_t" :label="item_t.name"
                             :value="item_t.id">{{ item_t.name }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="item_p.type!=2" class="topFormItem" label="生效日期">
                <el-date-picker v-model="item_p.startTime" :disabled="true" popper-class="date-style"
                                type="datetime"
                                value-format="YYYY-MM-DD HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item v-if="item_p.type==1" class="topFormItem timeFormItem" label="时间段"
                            required>
                <el-date-picker v-model="item_p.monthTimeList[0].monthTime"
                                :disabled="true"
                                format="MM月"
                                placeholder="请选择月份"
                                style="width: 500px;"
                                type="months"
                                value-format="MM"
                />
              </el-form-item>
              <el-form-item v-if="item_p.type==2" class="topFormItem timeFormItem" label="时间段">
                <el-select ref="elSelectTable" v-model="item_p.monthTimeList" :disabled="true"
                           :popper-append-to-body="false" :suffix-icon="Plus"
                           class="timeSelect" multiple popper-class="timeSelectPopper">
                </el-select>
              </el-form-item>
              <!--展开收起-->
              <div class="isUnfoldBox">
                <p v-show="!item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                  <span>展开</span>
                  <el-icon>
                    <CaretBottom/>
                  </el-icon>
                </p>
                <p v-show="item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                  <span>收起</span>
                  <el-icon>
                    <CaretTop/>
                  </el-icon>
                </p>
              </div>
            </div>
            <div class="strategy">
              <div class="strategyBox strategyBox1">
                <div :class="'chart'+index_p" class="chart"></div>
                <el-table v-show="item_p.isUnfold" :data="item_p.timeList" style="width: 100%;margin: 16px 0 ;">
                  <el-table-column label="时间范围" min-width="400">
                    <template #default="scope">
                      <div class="timerBox" style="max-width: 300px">
                        <el-time-picker v-model="scope.row.listTime"
                                        :disabled="true"
                                        end-placeholder="结束时间"
                                        format="HH:mm"
                                        is-range
                                        range-separator="-"
                                        start-placeholder="开始时间"
                                        value-format="HH:mm"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="计价类型" min-width="220">
                    <template #default="scope">
                      <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                        <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                   :value="item.key"></el-option>
                        <template #label="{ label, value }">
                        <span v-if="value==1"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                          <span v-if="value==2"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                          <span v-if="value==3"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                          <span v-if="value==4"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                          <span v-if="value==5"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                        </template>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table v-if="false" v-show="item_p.isUnfold" :data="item_p.priceList">
                  <el-table-column label="计价类型" min-width="220">
                    <template #default="scope">
                      <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                        <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                   :value="item.key"></el-option>
                        <template #label="{ label, value }">
                         <span v-if="value==1"
                               style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                          <span v-if="value==2"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                          <span v-if="value==3"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                          <span v-if="value==4"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                          <span v-if="value==5"
                                style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                        </template>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="电价" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.value" :disabled="true">
                        <template #append>元/kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="false" v-show="item_p.isUnfold" class="strategyBox strategyBox2">
                <div class="pricingMethod">
                  <span>计价方式：{{ item_p.ladderType == 1 ? '按月用量' : '按年用量' }}</span>
                </div>
                <el-table :data="item_p.ladderList" style="width: 100%;margin: 16px 0 ;">
                  <el-table-column label="档级" min-width="150">
                    <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}档</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="下限（>）" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.downValue" :disabled="true">
                        <template #append>kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="上限（≤）" min-width="220">
                    <template #default="scope">
                      <el-input v-model="scope.row.upValue" :disabled="true">
                        <template #append>kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="电价浮动" min-width="220">
                    <template #default="scope">
                      <span style="margin-right: 5px">+</span>
                      <el-input v-model="scope.row.value" :disabled="true">
                        <template #append>元/kwh</template>
                      </el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <!--电价表格********************************************-->
          <div class="contain" style="margin: 20px 0;">
            <div class="priceBox">
              <div class="priceHeadBox">
                <p class="name">电价方案：</p>
                <div class="operation">
                  <el-button v-if="false" :icon="Plus" type="primary" @click="addPriceClick">新增</el-button>
                  <el-button :icon="Clock" el-icon
                             @click="lookHistorical">历史记录
                  </el-button>
                </div>
              </div>
              <el-table v-loading="loading" :data="tableData" class="tableBox" style="width: 100%">
                <template #empty>
                  <el-empty class="emptyClass" description="暂无数据信息"
                            image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                  </el-empty>
                </template>
                <el-table-column label="生效日期" prop="startTime"/>
                <el-table-column label="费率" prop="priceList">
                  <template #default="scope">
                    <div v-for="(item,index) in scope.row.priceList" :key="index" class="item">
                      <p v-if="item.type==1" class="type1 typeBox">尖</p>
                      <p v-if="item.type==2" class="type2 typeBox">峰</p>
                      <p v-if="item.type==3" class="type3 typeBox">平</p>
                      <p v-if="item.type==4" class="type4 typeBox">谷</p>
                      <p v-if="item.type==5" class="type5 typeBox">深谷</p>
                      <el-input v-model="item.value" disabled>
                        <template #append>元/kwh</template>
                      </el-input>
                    </div>
                    <div v-for="(item,index) in scope.row.ladderList" :key="index" class="item">
                      <p class="type6 typeBox">第{{ index + 1 }}档</p>
                      <p>{{ item.downValue }}-{{ item.upValue }} kwh &nbsp; +{{ item.value }} 元/kwh</p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="状态" prop="inUseFlag">
                  <template #default="scope">
                    <p :style="{background:scope.row.inUseFlag == 1?'#E8FFEA':'#FEF4E5',color:scope.row.inUseFlag == 1?'#00B42A':'#F78E00'}"
                       class="status">
                      {{
                        scope.row.inUseFlag == 1 ? '正在使用' : '等待中'
                      }}</p>
                  </template>
                </el-table-column>
                <!--            <el-table-column fixed="right" label="操作">-->
                <!--              <template #default="scope">-->
                <!--                <div class="opacity">-->
                <!--                  <span class="btn" style="cursor: pointer;color: #165DFF;margin-right: 16px"-->
                <!--                        @click="editClick(scope,scope.$index)">修改</span>-->
                <!--                  <span class="btn delClass" style="color: #EF2121;cursor: pointer"-->
                <!--                        @click="delClick(scope,scope.$index)">删除</span>-->
                <!--                </div>-->
                <!--              </template>-->
                <!--            </el-table-column>-->
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, reactive, ref, watch} from "vue"
import './rateApplication.less'
import {getProjectListApi} from "@/api/modules/project";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router/dist/vue-router";
import * as echarts from "echarts";
import {
  getPowerPriceGroupDetailApi,
  getPowerPriceGroupListApi, getPowerPriceGroupTreeApi,
  getPowerPriceSelectApi,
  getTimeShareSelectApi, getDetailByEquipmentIdApi, getDefaultEquipmentApi, getPowerPriceGroupPriceListApi
} from "@/api/modules/rate";
import qs from "qs";

let router = useRouter()


// 设备列表操作----------------------------------------------------------------------------
let equipmentList = ref([])  //设备列表
let checkEquipment = ref()   //选中的设备
let name = ref()
// 点击选择设备
const equipmentClick = async function (id) {
  checkEquipment.value = id
  await getDetailByEquipmentIdApi(qs.stringify({
    equipmentId: id
  })).then(res => {
    if (res.data.code === 0) {
      name.value = res.data.result.name
      strategyForm.value = {
        id: res.data.result.id,
        equipmentIdList: res.data.result.equipmentIdList,
        name: res.data.result.name,
        openFlag: res.data.result.openFlag,
        priceList: res.data.result.priceList,
      }
      for (let i = 0; i < strategyForm.value.priceList.length; i++) {
        // 处理时间段
        if (strategyForm.value.priceList[i].type === 2) {
          let data = []
          for (let j = 0; j < strategyForm.value.priceList[i].monthTimeList.length; j++) {
            data.push(strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(8, 2) + '日-' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(8, 2) + '日')
          }
          strategyForm.value.priceList[i].monthTimeList = data
        } else {
          strategyForm.value.priceList[i].monthTimeList[0].monthTime = strategyForm.value.priceList[i].monthTimeList[0].monthTime.split(',')
        }
        // 处理电价时间段
        for (let j = 0; j < strategyForm.value.priceList[i].timeList.length; j++) {
          strategyForm.value.priceList[i].timeList[j].listTime = [strategyForm.value.priceList[i].timeList[j].startTime, strategyForm.value.priceList[i].timeList[j].endTime]
        }
      }
      getPowerPriceGroupPriceListFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  })

}


// 组合包数据----------------------------------------------------------------------------
// 时间列表
let timeList = ref(['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '23:59'])
// 加载图表
let xAxios = ref([])
let yAxios = ref([])
const chartLoad = function (idx) {
  var chartDom = document.querySelector(`.chart${idx}`);
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 20,
      right: 20,
      bottom: 20,
      top: 40,
    },
    legend: {
      show: true,
    },
    tooltip: {
      show: false
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        },
        interval: 119
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      data: xAxios.value[idx]
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: yAxios.value[idx],
  };
  myChart.clear()
  option && myChart.setOption(option);
  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 电价表单数据-------------------------------------------------------------------
let strategyForm = ref({
  id: router.currentRoute.value.query.id && router.currentRoute.value.query.type === 'edit' ? router.currentRoute.value.query.id : 0,
  name: '',
  openFlag: 0,
  equipmentIdList: [],
  priceList: []
})


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
// 寻找初始化选中的项目数据
const initProjectData = function (id, list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      custerObj.value = list[i]
      return
    } else {
      initProjectData(id, list[i].subList)
    }
  }
}
//获取项目树级列表
const getProjectListFun = async function () {
  await getPowerPriceGroupTreeApi(qs.stringify({
    projectId: 0,
    id: 0
  })).then(async res => {
    if (res.data.ok) {
      customerList.value = res.data.result
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey6')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey6')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey6')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey6')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj6'))
          } else {
            // currentNodeKey.value = customerList.value[0].id
            // custerObj.value = customerList.value[0]

            // 第一次进入获取默认显示的项目设备
            await getDefaultEquipmentApi().then(res => {
              if (res.data.code === 0) {
                currentNodeKey.value = res.data.result.projectId
                checkEquipment.value = res.data.result.equipmentId
                initProjectData(res.data.result.projectId, customerList.value)
              } else {
                ElMessage({
                  message: res.data.message,
                  type: 'error'
                })
              }
            }).catch(err => {
              console.log(err);
            })
            console.log(currentNodeKey.value)
          }
        }
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey6', currentNodeKey.value)
  window.localStorage.setItem('custerObj6', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}


// 监听方案变化
watch(() => strategyForm.value.priceList, (newVal) => {
  yAxios.value = []
  xAxios.value = []
  let list = []
  let xAxiosArr = []
  for (let i = 0; i < newVal.length; i++) {
    list[i] = []
    for (let j = 0; j < newVal[i].timeList.length; j++) {
      list[i].push({
        type: 'line',
        data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0],
        color: '#3587DA',
        lineStyle: {
          width: 1,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, null, 0, 1, [
            {
              offset: 0,
              color: 'rgb(188, 221, 246)'
            },
            {
              offset: 1,
              color: 'rgba(188, 221, 246,0)'
            }
          ])
        }
      })
      if (newVal[i].timeList[j].type == 1) {
        list[i][j].color = '#F21110'
        list[i][j].name = '尖'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(246, 165, 167)'
            },
            {
              offset: 1,
              color: 'rgba(246, 165, 167,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 2) {
        list[i][j].color = '#F57244'
        list[i][j].name = '峰'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(247, 216, 200)'
            },
            {
              offset: 1,
              color: 'rgba(247, 216, 200,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 3) {
        list[i][j].color = '#50D5D9'
        list[i][j].name = '平'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(202, 242, 246)'
            },
            {
              offset: 1,
              color: 'rgba(202, 242, 246,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 4) {
        list[i][j].color = '#3587DA'
        list[i][j].name = '谷'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(189, 222, 247)'
            },
            {
              offset: 1,
              color: 'rgba(189, 222, 247,0)'
            }
          ])
        }
      } else if (newVal[i].timeList[j].type == 5) {
        list[i][j].color = '#748EE3'
        list[i][j].name = '深谷'
        list[i][j].areaStyle = {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(216, 226, 248)'
            },
            {
              offset: 1,
              color: 'rgba(216, 226, 248,0)'
            }
          ])
        }
      }
    }

    // 时间排序
    // xAxiosArr[i] = []
    // for (let j = 0; j < newVal[i].timeList.length; j++) {
    //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[0]) && newVal[i].timeList[j].listTime[0]) {
    //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[0])
    //   }
    //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[1]) && newVal[i].timeList[j].listTime[1]) {
    //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[1])
    //   }
    //   // 时间排序
    //   xAxiosArr[i].sort(function (a, b) {
    //     // 将时间字符串转换为24小时制的分钟数
    //     var minA = new Date('1970-01-01T' + a + 'Z').getTime();
    //     var minB = new Date('1970-01-01T' + b + 'Z').getTime();
    //     return minA - minB;
    //   });
    //   // xAxios.value = xAxiosArr
    // }
    xAxiosArr[i] = []
    for (let k = 0; k <= 23; k++) {
      for (let j = 0; j <= 59; j = j + 1) {
        let hour = k < 10 ? "0" + k : k.toString();
        let minute = j < 10 ? "0" + j : j.toString();
        xAxiosArr[i].push(hour + ":" + minute);
      }
    }

    for (let j = 0; j < newVal[i].timeList.length; j++) {
      let startTime = ''
      let endTime = ''
      if (newVal[i].timeList[j].startTime && newVal[i].timeList[j].endTime && newVal[i].timeList[j].type) {
        for (let k = 0; k < xAxiosArr[i].length; k++) {
          if (newVal[i].timeList[j].startTime === xAxiosArr[i][k]) {
            startTime = k
          }
          if (newVal[i].timeList[j].endTime === xAxiosArr[i][k]) {
            endTime = k
          }
        }
      }
      for (let k = startTime; k < endTime + 1; k++) {
        // list[i][j].data[k] = Number(newVal[i].timeList[j].price)
        if (newVal[i].timeList[j].type == 1) {
          list[i][j].data[k] = 10
        } else if (newVal[i].timeList[j].type == 2) {
          list[i][j].data[k] = 8
        } else if (newVal[i].timeList[j].type == 3) {
          list[i][j].data[k] = 6
        } else if (newVal[i].timeList[j].type == 4) {
          list[i][j].data[k] = 4
        } else if (newVal[i].timeList[j].type == 5) {
          list[i][j].data[k] = 2
        }
      }
    }

    xAxios.value = xAxiosArr
    yAxios.value = list
    nextTick(() => {
      chartLoad(i)
    })
  }


}, {deep: true, immediate: true})

watch(() => custerObj.value, (newVal) => {
  // 获取项目列表
  if (custerObj.value.id) {
    getPowerPriceGroupTreeApi(qs.stringify({
      projectId: custerObj.value.id,
      id: 0
    })).then(async res => {
      if (res.data.ok) {
        equipmentList.value = res.data.result
        await equipmentClick(res.data.result[0].id)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })

  }
}, {deep: true, immediate: true})


// 组合包对应电价的处理****************************************************************
let loading = ref(false)   // 表格加载
// 电价表格
let tableData = ref([])
// 获取电价列表
const getPowerPriceGroupPriceListFun = function () {
  loading.value = true
  getPowerPriceGroupPriceListApi(qs.stringify({groupId: strategyForm.value.id, type: 1})).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    loading.value = false
    console.log(err);
  })
}
// 点击查看历史记录
const lookHistorical = function () {
  router.push({
    path: '/admin/historicalRecord',
    query: {
      id: strategyForm.value.id
    }
  })
}


let powerPriceSelect = ref()
let ratePriceSelect = ref()
let ratePriceGroupSelect = ref([])
let powerPriceGroupList = ref([])
onMounted(() => {
  getProjectListFun()

  // 获取电价类型选项
  getTimeShareSelectApi().then(res => {
    if (res.data.code === 0) {
      powerPriceSelect.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取方案选项
  getPowerPriceSelectApi().then(res => {
    if (res.data.code === 0) {
      ratePriceSelect.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取组合包选项
  getPowerPriceGroupListApi(qs.stringify({
    pageNumber: 1,
    pageSize: 9999999,
  })).then(res => {
    if (res.data.code === 0) {
      powerPriceGroupList.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less" scoped>
.rateApplicationPage {
  .priceBox {
    .priceHeadBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px;

      .name {

      }

      .operation {

      }
    }

    .tableBox {
      .item {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .el-input {
          width: 160px;
        }

        .typeBox {
          padding: 0 4px;
          color: #fff;
          border-radius: 3px;
          height: 22px;
          line-height: 22px;
          margin-right: 6px;
        }

        .type1 {
          background: #F21110;
        }

        .type2 {
          background: #F57244;
        }

        .type3 {
          background: #50D5D9;
        }

        .type4 {
          background: #3587DA;
        }

        .type5 {
          background: #748EE3;
        }

        .type6 {
          background: #A084FA;
        }

        > p {
          display: inline-block;
        }
      }

      .status {
        padding: 0 8px;
        border-radius: 3px;
        display: inline-block;
      }
    }

  }


  .priceDialog {
    .dialogCont {
      .timeBox {
        display: flex;
        align-items: center;

        > span {
          margin-right: 12px;
        }
      }

      .titName {
        text-indent: 16px;
        margin: 16px 0;
        position: relative;
        color: #333333;
        font-weight: 400;
      }

      .titName::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 16px;
        background: #165DFF;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

    .operationUl {
      display: flex;
      font-size: 16px;
      color: #2064FF;

      .deleteClass {
        color: #EF2121;
        margin-right: 12px;
      }

      .editClass, .deleteClass {
        font-weight: bold;
        transform: translateY(3px);
      }
    }
  }
}
</style>
