import {api} from "@/api";

// 获取组织树级列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/customer/getTree',
        method: 'POST',
        data: data
    })
}

// 组织树的新增/编辑
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/customer/save',
        method: 'POST',
        data: data
    })
}

//组织树的删除
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/customer/delete',
        method: 'POST',
        data: data
    })
}
//图片上传
export const uploadImg = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/upload/uploadSingle',
        method: 'POST',
        data: data
    })
}
//角色列表
export const roleList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/role/listAll',
        method: 'POST',
        data: data
    })
}
//用户的列表
export const getMemberList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/page',
        method: 'POST',
        data: data
    })
}

//用户的新增保存
export const addMemberList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/add',
        method: 'POST',
        data: data
    })
}
//用户的更新保存
export const editMemberList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/update',
        method: 'POST',
        data: data
    })
}
//成员的删除
export const memberDeleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/delete',
        method: 'POST',
        data: data
    })
}

//---------------------------
export const deleteList2 = (id) => {
    return api({
        url: '/zouk/rest/bManager/del/' + id,
        method: 'GET'
    })
}

// 更新当前用户密码
export const updatePassApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/updateNowPassword',
        method: 'POST',
        data: data
    })
}
//用户的更新密码
export const updatePassword = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/updatePassword',
        method: 'POST',
        data: data
    })
}

//获取所有分类列表
export const getAllCategory = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/category/listAll',
        method: 'POST',
        data: data
    })
}
//获取产品列表
export const getProductList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/product/listAllSelect',
        method: 'POST',
        data: data
    })
}

//获取产品详情
export const getProductApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/customer/getDetail',
        method: 'POST',
        data: data
    })
}

// 获取用户详情
export const getTreeDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/getDetail',
        method: 'POST',
        data: data
    })
}
// 获取大屏选项
export const getScreenList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataScreen/listSelect',
        method: 'POST',
        data: data
    })
}
//更新数据大屏权限
export const updateDataScreen = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/updateDataScreen',
        method: 'POST',
        data: data
    })
}

// 更新数据权限
export const updateDataPermissionApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/updateDataPermission',
        method: 'POST',
        data: data
    })
}
