<template>
  <div class="eleQuery">
    <div class="title">
      <p>
        <span>电量统计</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
                <el-tooltip content="追溯数据">
                  <el-icon class="opacityIcon" style="margin-left: 10px;" @click="dateFromFun('project')"><RefreshLeft/></el-icon>
                </el-tooltip>
							</span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <!--设备选择-->
        <div class="headBox">
          <div class="headBoxTit" style="display: flex;align-items: center">
            <!--            <p>{{ custerObj.name }}/设备</p>-->
            <el-input v-model="deviceName" placeholder="请输入设备名称" style="width: 220px"
                      @input="deviceSearchFun"></el-input>
            <el-button style="margin-left: 10px" type="primary" @click="dateFromFun('device')">追溯数据</el-button>
          </div>
          <div class="deviceBox">
            <p v-for="(item,index) in equipmentList" :key="index" :class="checkEquipment===item.id?'activeP':''"
               @click="equipmentClick(item.id)">
              {{ item.name }}</p>
          </div>
        </div>
        <div class="contain">
          <!--头部-->
          <!--          <div class="headBox2">-->
          <!--            <p class="title">电量查询</p>-->
          <!--            <div class="tabBox">-->
          <!--              <p :class="activeIdx===1?'active':''" @click="activeClick(1)">日报表</p>-->
          <!--              <p :class="activeIdx===2?'active':''" @click="activeClick(2)">月报表</p>-->
          <!--              <p :class="activeIdx===3?'active':''" @click="activeClick(3)">年报表</p>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--搜索-->
          <div class="searchBox2">
            <div class="liPox">
              <div class="liBox" style="display: flex">
                <el-date-picker v-if="activeIdx===4"
                                v-model="dateTime[0]"
                                placeholder="请选择日期"
                                type="date" value-format="YYYY-MM-DD"/>
                <el-date-picker v-if="activeIdx===1" v-model="dateTime" end-placeholder="结束时间"
                                popper-class="date-style"
                                range-separator="→"
                                start-placeholder="开始时间"
                                type="daterange"
                                value-format="YYYY-MM-DD">
                </el-date-picker>
                <el-date-picker v-if="activeIdx===2" v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                                range-separator="→"
                                start-placeholder="开始时间"
                                type="monthrange"
                                value-format="YYYY-MM-DD">
                </el-date-picker>
                <el-date-picker v-if="activeIdx===3" v-model="dateTime[0]"
                                :disabled-date="disabledDate"
                                placeholder="开始时间"
                                style="width: 120px"
                                type="year" value-format="YYYY-MM-DD"
                />
                <div v-if="activeIdx===3" class="segmentation" style="margin: 0 8px;transform: translateY(5px)">至</div>
                <el-date-picker v-if="activeIdx===3" v-model="dateTime[1]"
                                :disabled-date="disabledDate2"
                                placeholder="结束时间"
                                style="width: 120px"
                                type="year"
                                value-format="YYYY-MM-DD"
                />
              </div>
              <div class="liBox">
                <el-radio-group v-model="activeIdx" @change="activeClick">
                  <el-radio :value="4">小时</el-radio>
                  <el-radio :value="1">日</el-radio>
                  <el-radio :value="2">月</el-radio>
                  <el-radio :value="3">年</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="liBox">
              <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
              <el-button class="searchButton" type="primary" @click="lookFun">查看费率</el-button>
              <el-button class="searchButton resetButton" @click="resetFun">重置</el-button>
              <el-button class="searchButton resetButton" @click="exportFun">导出</el-button>
            </div>
          </div>
          <!--内容-->
          <div class="containBox">
            <div class="head">
              <div class="tab">
                <p :class="showFlag?'active':''" @click="showClick(1)">图表</p>
                <p :class="!showFlag?'active':''" @click="showClick(2)">表格</p>
              </div>
            </div>
            <el-empty v-if="echartsDataX.length===0&&showFlag"
                      class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
            <!--图表-->
            <div v-show="echartsDataX.length>0&&showFlag" class="chart"></div>
            <div v-show="echartsDataX.length>0&&showFlag" class="chartCircle"></div>

            <!--表格-->
            <el-table v-show="!showFlag" v-loading="loading" :data="tableData" style="width: 100%;margin-top: 20px">
              <template #empty>
                <el-empty class="emptyClass" description="暂无数据信息"
                          image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                </el-empty>
              </template>
              <el-table-column label="日期" prop="time"></el-table-column>
              <el-table-column label="总" prop="total"></el-table-column>
              <el-table-column v-if="tableData[0]&&tableData[0].sharp!==''" label="尖" prop="sharp"></el-table-column>
              <el-table-column v-if="tableData[0]&&tableData[0].peak!==''" label="峰" prop="peak"></el-table-column>
              <el-table-column v-if="tableData[0]&&tableData[0].flat!==''" label="平" prop="flat"></el-table-column>
              <el-table-column v-if="tableData[0]&&tableData[0].valley!==''" label="谷" prop="valley"></el-table-column>
              <el-table-column v-if="tableData[0]&&tableData[0].deepValley!==''" label="深谷"
                               prop="deepValley"></el-table-column>
            </el-table>
            <div v-show="!showFlag" class="pag">
              <el-pagination v-model:page-size="pageSize" :current-page="pageNumber" :page-sizes="[10, 20, 50, 100]"
                             :total="total" background
                             layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                             @current-change="pageChange"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--追溯数据时间选择-->
    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="追溯数据" width="80%">
      <div class="dialogCont">
        <!--        <el-date-picker-->
        <!--            v-model="dateFromTime"-->
        <!--            :disabled-date="disabledDate3"-->
        <!--            end-placeholder="请选择结束时间"-->
        <!--            start-placeholder="请选择开始时间"-->
        <!--            type="daterange"-->
        <!--            value-format="YYYY-MM-DD"-->
        <!--        />-->
        <el-select v-model="dateFromGroupId" clearable placeholder="请选择电价组合包"
                   style="width: 220px;margin-bottom: 16px;" @change="getPowerPriceGroupPriceListFun2">
          <el-option v-for="(item,index) in electrovalenceBagList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <br>
        <el-date-picker
            v-model="dateFromTime"
            :disabled-date="disabledDate3"
            end-placeholder="请选择结束时间"
            start-placeholder="请选择开始时间"
            type="daterange"
            value-format="YYYY-MM-DD"
        />
        <!--电价表格********************************************-->
        <div class="strategyBox strategyBox2">
          <div class="titName">分时计费</div>
          <el-table :data="priceFormData.priceList">
            <el-table-column label="计价类型" min-width="220">
              <template #default="scope">
              <span v-if="scope.row.type==1"
                    style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                <span v-if="scope.row.type==2"
                      style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                <span v-if="scope.row.type==3"
                      style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                <span v-if="scope.row.type==4"
                      style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                <span v-if="scope.row.type==5"
                      style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
              </template>
            </el-table-column>
            <el-table-column label="电价" min-width="220">
              <template #default="scope">
                <el-input v-model="scope.row.value" style="width: 180px">
                  <template #append>元/kwh</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="strategyBox strategyBox2">
          <div class="titName">阶梯计费</div>
          <div class="pricingMethod">
            <span>计价方式：</span>
            <el-radio-group v-model="priceFormData.ladderType">
              <el-radio :value="1">按月用量</el-radio>
              <el-radio :value="2">按年用量</el-radio>
            </el-radio-group>
          </div>
          <el-table :data="priceFormData.ladderList" style="width: 100%;margin: 16px 0 ;">
            <el-table-column label="档级" min-width="150">
              <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}档</span>
              </template>
            </el-table-column>
            <el-table-column label="下限（>）" min-width="220">
              <template #default="scope">
                <el-input v-model="scope.row.downValue">
                  <template #append>kwh</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="上限（≤）" min-width="220">
              <template #default="scope">
                <el-input v-model="scope.row.upValue">
                  <template #append>kwh</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="电价浮动" min-width="220">
              <template #default="scope">
                <div style="display: flex;align-items: center">
                  <span style="margin-right: 5px">+</span>
                  <el-input v-model="scope.row.value">
                    <template #append>元/kwh</template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100">
              <template #default="scope">
                <ul class="operationUl">
                  <li v-if="scope.$index!==0" class="deleteClass" @click="delTime2(scope.$index)">
                    <el-icon>
                      <Delete/>
                    </el-icon>
                  </li>
                  <li class="editClass" @click="addTime2(scope.$index)">
                    <el-icon>
                      <Plus/>
                    </el-icon>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--设备列表-->
        <div v-if="dateFromType==='project'" class="deviceBox">
          <p v-for="(item,index) in equipmentList" :key="index">{{ item.name }}</p>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
          <el-button class="cancle" @click="dialogVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="dateFromSure()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!--导出-->
    <el-dialog v-model="dialogVisible2" :close-on-click-modal="false" class="exportDialog" title="导出数据" width="60%">
      <div class="dialogCont">
        <div style="margin-bottom: 10px;">
          <span>设备名称：</span>
          <el-input v-model="deviceName" placeholder="请输入设备名称" style="width: 220px"
                    @input="deviceSearchFun"></el-input>
        </div>
        <el-checkbox
            v-model="checkAllFlag"
            :indeterminate="exportList.length>0&&exportList.length!==equipmentList.length"
            @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <el-checkbox-group v-model="exportList">
          <el-checkbox v-for="(item,index) in equipmentList" :key="index" :label="item.name" :value="item.id"/>
        </el-checkbox-group>
      </div>
      <template #footer>
				<span class="dialog-footer">
          <el-button class="cancle" @click="dialogVisible2=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="exportClick()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!--查看费率弹窗-->
    <el-dialog v-model="dialogVisible3" :close-on-click-modal="false" :title="strategyForm.name" class="lookDialog"
               width="90%">
      <div class="dialogCont">
        <div style="text-indent: 5px;margin-bottom: 10px;">组合包名称:{{ strategyForm.name }}</div>
        <div style="text-indent: 5px;margin-bottom: 10px;">绑定时段方案:</div>
        <!--分时策略数据-->
        <div v-for="(item_p,index_p) in strategyForm.priceList" :key="index_p" class="strategy">
          <!--选择方案-->
          <div class="topBox">
            <div style="margin-bottom: 16px;">
              <span>方案名称:{{ item_p.name }}</span>
            </div>
            <el-form-item v-if="item_p.type!=2" class="topFormItem" label="生效日期">
              <el-date-picker v-model="item_p.startTime" :disabled="true" popper-class="date-style"
                              type="datetime"
                              value-format="YYYY-MM-DD HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="item_p.type==1" class="topFormItem timeFormItem" label="时间段"
                          required>
              <el-date-picker v-model="item_p.monthTimeList[0].monthTime"
                              :disabled="true"
                              format="MM月"
                              placeholder="请选择月份"
                              style="width: 500px;"
                              type="months"
                              value-format="MM"
              />
            </el-form-item>
            <el-form-item v-if="item_p.type==2" class="topFormItem timeFormItem" label="时间段">
              <el-select ref="elSelectTable" v-model="item_p.monthTimeList" :disabled="true"
                         :popper-append-to-body="false" :suffix-icon="Plus"
                         class="timeSelect" multiple popper-class="timeSelectPopper">
              </el-select>
            </el-form-item>
            <!--展开收起-->
            <div class="isUnfoldBox">
              <p v-show="!item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                <span>展开</span>
                <el-icon>
                  <CaretBottom/>
                </el-icon>
              </p>
              <p v-show="item_p.isUnfold" @click="item_p.isUnfold=!item_p.isUnfold">
                <span>收起</span>
                <el-icon>
                  <CaretTop/>
                </el-icon>
              </p>
            </div>
          </div>
          <div v-show="item_p.id" class="strategy">
            <div class="strategyBox strategyBox1">
              <div :class="'chartStrategy'+index_p" class="chart"></div>
              <el-table v-show="item_p.isUnfold" :data="item_p.timeList" style="width: 100%;margin: 16px 0 ;">
                <el-table-column label="时间范围" min-width="400">
                  <template #default="scope">
                    <div class="timerBox" style="max-width: 300px">
                      <el-time-picker v-model="scope.row.listTime"
                                      :disabled="true"
                                      end-placeholder="结束时间"
                                      format="HH:mm"
                                      is-range
                                      range-separator="-"
                                      start-placeholder="开始时间"
                                      value-format="HH:mm"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="计价类型" min-width="220">
                  <template #default="scope">
                    <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                      <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                 :value="item.key"></el-option>
                      <template #label="{ label, value }">
                        <span v-if="value==1"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                        <span v-if="value==2"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                        <span v-if="value==3"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                        <span v-if="value==4"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                        <span v-if="value==5"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
              <el-table v-if="false" v-show="item_p.isUnfold" :data="item_p.priceList">
                <el-table-column label="计价类型" min-width="220">
                  <template #default="scope">
                    <el-select v-model="scope.row.type" :disabled="true" placeholder="请选择">
                      <el-option v-for="(item,index) in powerPriceSelect" :key="index" :label="item.value"
                                 :value="item.key"></el-option>
                      <template #label="{ label, value }">
                         <span v-if="value==1"
                               style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F21110;color: #fff">尖</span>
                        <span v-if="value==2"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #F57244;color: #fff">峰</span>
                        <span v-if="value==3"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #50D5D9;color: #fff">平</span>
                        <span v-if="value==4"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #3587DA;color: #fff">谷</span>
                        <span v-if="value==5"
                              style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #748EE3;color: #fff">深谷</span>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="电价" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.value" :disabled="true">
                      <template #append>元/kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="false" v-show="item_p.isUnfold" class="strategyBox strategyBox2">
              <div class="pricingMethod">
                <span>计价方式：{{ item_p.ladderType == 1 ? '按月用量' : '按年用量' }}</span>
              </div>
              <el-table :data="item_p.ladderList" style="width: 100%;margin: 16px 0 ;">
                <el-table-column label="档级" min-width="150">
                  <template #default="scope">
                    <span
                        style="display: inline-block;padding: 0 4px;border-radius: 3px;background-color: #A084FA;color: #fff">第{{
                        scope.$index + 1
                      }}档</span>
                  </template>
                </el-table-column>
                <el-table-column label="下限（>）" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.downValue" :disabled="true">
                      <template #append>kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="上限（≤）" min-width="220">
                  <template #default="scope">
                    <el-input v-model="scope.row.upValue" :disabled="true">
                      <template #append>kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="电价浮动" min-width="220">
                  <template #default="scope">
                    <span style="margin-right: 5px">+</span>
                    <el-input v-model="scope.row.value" :disabled="true">
                      <template #append>元/kwh</template>
                    </el-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!--电价表格********************************************-->
        <div class="contain" style="margin: 20px 0;">
          <div class="priceBox">
            <div class="priceHeadBox">
              <p class="name">电价方案：</p>
              <div class="operation">
                <el-button v-if="false" :icon="Plus" type="primary" @click="addPriceClick">新增</el-button>
                <el-button :icon="Clock" el-icon
                           @click="lookHistorical">历史记录
                </el-button>
              </div>
            </div>
            <el-table v-loading="loading" :data="tableData2" class="tableBox" style="width: 100%">
              <template #empty>
                <el-empty class="emptyClass" description="暂无数据信息"
                          image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                </el-empty>
              </template>
              <el-table-column label="生效日期" prop="startTime"/>
              <el-table-column label="费率" prop="priceList">
                <template #default="scope">
                  <div v-for="(item,index) in scope.row.priceList" :key="index" class="item">
                    <p v-if="item.type==1" class="type1 typeBox">尖</p>
                    <p v-if="item.type==2" class="type2 typeBox">峰</p>
                    <p v-if="item.type==3" class="type3 typeBox">平</p>
                    <p v-if="item.type==4" class="type4 typeBox">谷</p>
                    <p v-if="item.type==5" class="type5 typeBox">深谷</p>
                    <el-input v-model="item.value" disabled>
                      <template #append>元/kwh</template>
                    </el-input>
                  </div>
                  <div v-for="(item,index) in scope.row.ladderList" :key="index" class="item">
                    <p class="type6 typeBox">第{{ index + 1 }}档</p>
                    <p>{{ item.downValue }}-{{ item.upValue }} kwh &nbsp; +{{ item.value }} 元/kwh</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" prop="inUseFlag">
                <template #default="scope">
                  <p :style="{background:scope.row.inUseFlag == 1?'#E8FFEA':'#FEF4E5',color:scope.row.inUseFlag == 1?'#00B42A':'#F78E00'}"
                     class="status">
                    {{
                      scope.row.inUseFlag == 1 ? '正在使用' : '等待中'
                    }}</p>
                </template>
              </el-table-column>
              <!--            <el-table-column fixed="right" label="操作">-->
              <!--              <template #default="scope">-->
              <!--                <div class="opacity">-->
              <!--                  <span class="btn" style="cursor: pointer;color: #165DFF;margin-right: 16px"-->
              <!--                        @click="editClick(scope,scope.$index)">修改</span>-->
              <!--                  <span class="btn delClass" style="color: #EF2121;cursor: pointer"-->
              <!--                        @click="delClick(scope,scope.$index)">删除</span>-->
              <!--                </div>-->
              <!--              </template>-->
              <!--            </el-table-column>-->
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch} from "vue"
import './eleQuery.less'


import * as echarts from "echarts";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {RefreshLeft} from '@element-plus/icons-vue'
import moment from "moment";
import {getAllTreeApi} from "@/api/modules/bigScreen";
import qs from "qs";
import {
  getEleChartApi,
  getEleTableApi,
  getTreePrefixApi,
  traceWithEquipmentApi,
  traceWithProjectApi
} from "@/api/modules/eleChargeQuery";
import {http} from "@/api";
import {
  getPowerPriceGroupDetailApi,
  getPowerPriceGroupPriceListApi,
  getPowerPriceGroupSelectApi,
  getUsePriceApi
} from "@/api/modules/rate";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()
let checkEquipmentList = ref([])

// 追溯数据*********************************************************************************
// 点击追溯数据
let dateFromTime = ref()
let dateFromType = ref()
let dateFromGroupId = ref()
let priceFormData = ref({
  priceList: [
    {
      type: 1,
      value: ''
    },
    {
      type: 2,
      value: ''
    },
    {
      type: 3,
      value: ''
    },
    {
      type: 4,
      value: ''
    },
    {
      type: 5,
      value: ''
    }
  ],
  ladderType: 1,
  ladderList: [{
    downValue: '',
    upValue: '',
    value: ''
  }]
})  //电价列表
let dialogVisible = ref(false)
const dateFromFun = function (type) {
  dateFromType.value = type
  dateFromTime.value = []
  dateFromGroupId.value = ''
  dialogVisible.value = true
}
// 禁用今天之后的时间
const disabledDate3 = function (time) {
  return time.getTime() > Date.now(); // 禁用当前时间之后的日期
}
// 提交追溯时间
const dateFromSure = function () {
  if (dateFromTime.value.length > 0) {
    ElMessageBox.confirm('', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      title: '确认追溯',
      message: h('p', null, [
        h('img', {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
        }, ''),
        h('span', {style: 'color:#595959;'}, '请确认是否要将数据追溯到所选时间，追溯后数据不可恢复'),
      ])
    }).then(() => {
      dateFromType.value === 'device' ? traceWithEquipmentApi(qs.stringify({
        equipmentId: checkEquipment.value,
        startTime: dateFromTime.value[0],
        endTime: dateFromTime.value[1],
        powerPriceGroupId: dateFromGroupId.value,
        ladderType: priceFormData.value.ladderType,
        ladderJsonStr: JSON.stringify(priceFormData.value.ladderList),
        priceJsonStr: JSON.stringify(priceFormData.value.priceList)
      })).then(res => {
        if (res.data.code === 0) {
          ElMessage({
            type: 'success',
            message: '正在追溯中，请稍后查看数据',
          })
          dialogVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
      }) : traceWithProjectApi(qs.stringify({
        projectId: custerObj.value.id,
        startTime: dateFromTime.value[0],
        endTime: dateFromTime.value[1],
        powerPriceGroupId: dateFromGroupId.value,
        ladderType: priceFormData.value.ladderType,
        ladderJsonStr: JSON.stringify(priceFormData.value.ladderList),
        priceJsonStr: JSON.stringify(priceFormData.value.priceList)
      })).then(res => {
        if (res.data.code === 0) {
          ElMessage({
            type: 'success',
            message: '追溯成功',
          })
          dialogVisible.value = false
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message,
          })
        }
      }).catch(err => {
        console.log(err);
      })

    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '取消追溯',
      })
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择追溯时间'
    })
  }
}
// 组合包对应电价的处理****************************************************************
// 添加档级
const addTime2 = function (idx) {
  priceFormData.value.ladderList.splice(idx + 1, 0, {
    downValue: '',
    upValue: '',
    value: '',
  })
  priceFormData.value.ladderList[idx + 1].downValue = priceFormData.value.ladderList[idx].upValue
}
// 删除档级
const delTime2 = function (idx) {
  priceFormData.value.ladderList.splice(idx, 1)
}
// 获取电价列表
const getPowerPriceGroupPriceListFun2 = function () {
  getUsePriceApi(qs.stringify({groupId: dateFromGroupId.value})).then(res => {
    if (res.data.code === 0) {
      priceFormData.value = res.data.result
      if (priceFormData.value.priceList.length === 0) {
        priceFormData.value.priceList = [
          {
            type: 1,
            value: ""
          },
          {
            type: 2,
            value: ""
          },
          {
            type: 3,
            value: ""
          },
          {
            type: 4,
            value: ""
          },
          {
            type: 5,
            value: ""
          }
        ]
      }
      if (priceFormData.value.ladderList.length === 0) {
        priceFormData.value.ladderList = [
          {
            downValue: '',
            upValue: '',
            value: ''
          }
        ]
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  if (flag === 1) {
    showFlag.value = true
    await getEchartsDataFun()
  } else {
    pageNumber.value = 1
    pageSize.value = 20
    await getTableDataFun()
    showFlag.value = false
  }
}

// 查询字段*********************************************************************************
let dateTime = ref([moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]) //开始结束时间
let ammeter = ref('') //电表

watch(() => dateTime.value, (val) => {
  if (activeIdx == 3) {
    disabledDate(new Date(val[0]))
    disabledDate2(new Date(val[1]))
  }
})
const disabledDate = (time) => {
  if (dateTime.value[1]) {
    return time.getTime() >= moment(dateTime.value[1]).valueOf()
  }
  return false
}
const disabledDate2 = (time) => {
  if (dateTime.value[0]) {
    return time.getTime() < moment(dateTime.value[0]).valueOf()
  }
  return false
}

// 电表列表
let ammeterList = ref()

// 日月年切换
let activeIdx = ref(1)
const activeClick = function (idx) {
  activeIdx.value = idx
  if (activeIdx.value === 1) {
    dateTime.value = [moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 4) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  }
}


// 获取图表数据****************************************************************************************
let echartsDataY = ref([])
let echartsDataX = ref([])
let pieData = ref([])
let groupId = ref([])
const getEchartsDataFun = async function () {
  if (activeIdx.value == 4) {
    dateTime.value[1] = dateTime.value[0]
  }
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1])) {
    echartsDataX.value = []
    echartsDataY.value = []
    pieData.value = []
    openFullScreen()
    await getEleChartApi(qs.stringify({
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentId: checkEquipment.value
    })).then(res => {
      loadingAdd.value.close()
      if (res.data.code === 0) {
        groupId.value = res.data.result.groupId
        for (let i = 0; i < res.data.result.totalData.length; i++) {
          let color
          if (res.data.result.totalData[i].key === '尖') {
            color = '#F21110'
          } else if (res.data.result.totalData[i].key === '峰') {
            color = '#F57244'
          } else if (res.data.result.totalData[i].key === '平') {
            color = '#50D5D9'
          } else if (res.data.result.totalData[i].key === '谷') {
            color = '#3587DA'
          } else if (res.data.result.totalData[i].key === '深谷') {
            color = '#748EE3'
          }
          pieData.value.push({
            name: res.data.result.totalData[i].key,
            value: res.data.result.totalData[i].value,
            itemStyle: {
              color: color,
            }
          })
        }
        echartsDataX.value = res.data.result.chartData.timeList
        for (let i = 0; i < res.data.result.chartData.list.length; i++) {
          let color
          if (res.data.result.chartData.list[i].key === '尖') {
            color = '#F21110'
          } else if (res.data.result.chartData.list[i].key === '峰') {
            color = '#F57244'
          } else if (res.data.result.chartData.list[i].key === '平') {
            color = '#50D5D9'
          } else if (res.data.result.chartData.list[i].key === '谷') {
            color = '#3587DA'
          } else if (res.data.result.chartData.list[i].key === '深谷') {
            color = '#748EE3'
          }
          echartsDataY.value.push({
            name: res.data.result.chartData.list[i].key,
            type: 'bar',
            stack: 'Ad',
            color: color,
            data: res.data.result.chartData.list[i].value
          })
        }
        nextTick(() => {
          chartLoad()
          chartCircleLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}

// 查询
const searchFun = async function () {
  if (showFlag.value) {
    await getEchartsDataFun()
  } else {
    await getTableDataFun()
  }
}
// 重置
const resetFun = function () {
  checkEquipment.value = ''
  dateTime.value = []
  if (activeIdx.value === 1 || activeIdx.value === 4) {
    dateTime.value = [moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  } else if (activeIdx.value === 2) {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD'), moment().add(1, 'months').startOf('month').format('YYYY-MM-DD')]
  } else if (activeIdx.value === 3) {
    dateTime.value = [moment().startOf('years').format('YYYY-MM-DD'), moment().add(1, 'years').startOf('years').format('YYYY-MM-DD')]
  }

  echartsDataY.value = []
  echartsDataX.value = []
  pieData.value = []

  pageNumber.value = 1
  pageSize.value = 20
  total.value = 0
  tableData.value = []
  nextTick(() => {
    chartLoad()
    chartCircleLoad()
  })
}

// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true,
      formatter: (params) => {
        let str = ''
        params.forEach((item) =>
            str +=
                `<div style="display:flex;justify-content: space-between;align-items: center">
                  <div style="display:flex;align-items: center">
                    <p style="width: 10px;height: 10px;margin-right:10px;border-radius: 50%;background-color: ${item.color}"></p>
                    <p>${item.seriesName}</p>
                  </div>
                  <div>${item.value}</div>
                </div>`
        )
        let total = 0
        for (let i = 0; i < params.length; i++) {
          total += Number(params[i].value)
        }
        return `
        <div>
          <p>${params[0].axisValueLabel}</p>
          <div style="display: flex;justify-content: space-between">
            <p>总</p>

            <p>${total}</p>
          </div>
          ${str}
        </div>
        `
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
    },
    series: echartsDataY.value
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载饼图
const chartCircleLoad = function () {
  var chartDom = document.querySelector('.chartCircle');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      top: 50,
      bottom: 50,
      right: 50
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      // top: '2%',
      left: 'center'
    },
    series: [
      {
        name: '电量分析',
        type: 'pie',
        radius: ['20%', '70%'],
        avoidLabelOverlap: false,
        left: 30,
        right: 30,
        label: {
          normal: {
            show: true,
            formatter: '{b}: {d}%'
          }
        },
        data: pieData.value
      }
    ]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}


// 获取表格数据****************************************************************************************
let tableData = ref([])
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(0)
let loading = ref(false)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getTableDataFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getTableDataFun()
}
const getTableDataFun = function () {
  if (activeIdx.value == 4) {
    dateTime.value[1] = dateTime.value[0]
  }
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && checkEquipment.value) {
    loading.value = true
    getEleTableApi(qs.stringify({
      pageNumber: pageNumber.value,
      pageSize: pageSize.value,
      timeType: activeIdx.value,
      startTime: dateTime.value[0] ? dateTime.value[0] : '',
      endTime: dateTime.value[1] ? dateTime.value[1] : '',
      equipmentId: checkEquipment.value
    })).then(res => {
      loading.value = false
      if (res.data.code === 0) {
        tableData.value = res.data.result.list
        total.value = Number(res.data.result.total)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}


// 点击导出************************************************************************************
let exportList = ref([])   //需要导出的数据
let dialogVisible2 = ref(false)   //导出弹窗
const exportFun = function () {
  exportList.value = []
  dialogVisible2.value = true
}
const checkAllFlag = ref(false)  //全选按钮
// 点击全选
const handleCheckAllChange = (val) => {
  if (val) {
    let all = []
    for (let i = 0; i < equipmentList.value.length; i++) {
      all.push(equipmentList.value[i].id)
    }
    exportList.value = all
  } else {
    exportList.value = []
  }
}
// 确认导出
const exportClick = function () {
  if (activeIdx.value == 4) {
    dateTime.value[1] = dateTime.value[0]
  }
  if (activeIdx && dateTime.value && dateTime.value.length > 1 && (dateTime.value[0] && dateTime.value[1]) && checkEquipment.value) {
    openFullScreen()
    // window.location.href = http + 'zouk/rest/powerFeeRecord/power/exportTableData?timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentId=' + checkEquipment.value
    // window.location.href = http + 'zouk/rest/powerFeeRecordV2/power/exportTableData?timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentId=' + checkEquipment.value
    window.location.href = http + 'zouk/rest/powerFeeRecordV2/power/exportTableDataV3?timeType=' + activeIdx.value + '&startTime=' + (dateTime.value[0] ? dateTime.value[0] : '') + '&endTime=' + (dateTime.value[0] ? dateTime.value[1] : '') + '&equipmentIdList=' + exportList.value.join(',')
    setTimeout(() => {
      loadingAdd.value.close();
      dialogVisible2.value = false
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 1000);
    loadingAdd.value.close()
  } else {
    ElMessage({
      type: 'info',
      message: '请选择导出条件'
    });
  }
}
watch(() => dialogVisible2.value, (newVal) => {
  deviceName.value = ''
  deviceSearchFun()
})


// 设备列表************************************************************************************
let deviceName = ref('')
let equipmentList = ref([])  //设备列表
let checkEquipment = ref()   //选中的设备
// 点击选择设备
const equipmentClick = async function (id) {
  checkEquipment.value = id
  if (showFlag.value) {
    await getEchartsDataFun()
  } else {
    await getTableDataFun()
  }
}
const deviceSearchFun = function () {
  getTreePrefixApi(qs.stringify({
    projectId: custerObj.value.id,
    equipmentName: deviceName.value
  })).then(res => {
    if (res.data.ok) {
      equipmentList.value = res.data.result
      equipmentClick(res.data.result[0].id)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getTreePrefixApi(qs.stringify({
    projectId: 0,
  })).then(res => {
    if (res.data.ok) {
      customerList.value = res.data.result
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey7')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey7')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey7')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey7')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj7'))
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey7', currentNodeKey.value)
  window.localStorage.setItem('custerObj7', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}

watch(() => custerObj.value, (newVal) => {
  // 获取项目列表
  if (custerObj.value.id) {
    getTreePrefixApi(qs.stringify({
      projectId: custerObj.value.id,
      name: deviceName.value
    })).then(res => {
      if (res.data.ok) {
        equipmentList.value = res.data.result
        equipmentClick(res.data.result[0].id)
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}, {deep: true, immediate: true})


// 查看费率************************************************************************************
let dialogVisible3 = ref(false)   //查看费率弹窗
// 点击查看费率
const lookFun = function () {
  if (groupId.value != 0) {
    dialogVisible3.value = true
    // 获取电价组合包详情
    getPowerPriceGroupDetailApi(qs.stringify({
      id: groupId.value
    })).then(res => {
      if (res.data.code === 0) {
        strategyForm.value = {
          id: res.data.result.id,
          equipmentIdList: res.data.result.equipmentIdList,
          name: res.data.result.name,
          openFlag: res.data.result.openFlag,
          equipmentShowFlag: res.data.result.equipmentShowFlag,
          priceList: res.data.result.priceList,
        }
        for (let i = 0; i < strategyForm.value.priceList.length; i++) {
          // 处理时间段
          if (strategyForm.value.priceList[i].type === 2) {
            let data = []
            for (let j = 0; j < strategyForm.value.priceList[i].monthTimeList.length; j++) {
              data.push(strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].startTime.substr(8, 2) + '日-' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(0, 4) + '年' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(5, 2) + '月' + strategyForm.value.priceList[i].monthTimeList[j].endTime.substr(8, 2) + '日')
            }
            strategyForm.value.priceList[i].monthTimeList = data

          } else {
            strategyForm.value.priceList[i].monthTimeList[0].monthTime = strategyForm.value.priceList[i].monthTimeList[0].monthTime.split(',')
          }
          // 处理电价时间段
          for (let j = 0; j < strategyForm.value.priceList[i].timeList.length; j++) {
            strategyForm.value.priceList[i].timeList[j].listTime = [strategyForm.value.priceList[i].timeList[j].startTime, strategyForm.value.priceList[i].timeList[j].endTime]
          }
        }
        getPowerPriceGroupPriceListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    })
  } else {
    ElMessage({
      type: 'info',
      message: '该设备没有绑定组合包'
    })
  }
}
let strategyForm = ref({})   //费率数据


// 组合包对应电价的处理****************************************************************
let loading2 = ref(false)   // 表格加载
// 电价表格
let tableData2 = ref([])
// 获取电价列表
const getPowerPriceGroupPriceListFun = function () {
  loading2.value = true
  getPowerPriceGroupPriceListApi(qs.stringify({groupId: strategyForm.value.id, type: 1})).then(res => {
    loading2.value = false
    if (res.data.code === 0) {
      tableData2.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    loading2.value = false
    console.log(err);
  })
}
// 点击查看历史记录
const lookHistorical = function () {
  router.push({
    path: '/admin/historicalRecord',
    query: {
      id: strategyForm.value.id
    }
  })
}


// 加载图表
let xAxios = ref([])
let yAxios = ref([])
// 监听方案变化
watch(() => strategyForm.value.priceList, (newVal) => {
  yAxios.value = []
  xAxios.value = []
  let list = []
  let xAxiosArr = []
  if (newVal) {
    for (let i = 0; i < newVal.length; i++) {
      list[i] = []
      for (let j = 0; j < newVal[i].timeList.length; j++) {
        list[i].push({
          type: 'line',
          data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 0],
          color: '#3587DA',
          lineStyle: {
            width: 1,
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, null, 0, 1, [
              {
                offset: 0,
                color: 'rgb(188, 221, 246)'
              },
              {
                offset: 1,
                color: 'rgba(188, 221, 246,0)'
              }
            ])
          }
        })
        if (newVal[i].timeList[j].type == 1) {
          list[i][j].color = '#F21110'
          list[i][j].name = '尖'
          list[i][j].areaStyle = {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(246, 165, 167)'
              },
              {
                offset: 1,
                color: 'rgba(246, 165, 167,0)'
              }
            ])
          }
        } else if (newVal[i].timeList[j].type == 2) {
          list[i][j].color = '#F57244'
          list[i][j].name = '峰'
          list[i][j].areaStyle = {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(247, 216, 200)'
              },
              {
                offset: 1,
                color: 'rgba(247, 216, 200,0)'
              }
            ])
          }
        } else if (newVal[i].timeList[j].type == 3) {
          list[i][j].color = '#50D5D9'
          list[i][j].name = '平'
          list[i][j].areaStyle = {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(202, 242, 246)'
              },
              {
                offset: 1,
                color: 'rgba(202, 242, 246,0)'
              }
            ])
          }
        } else if (newVal[i].timeList[j].type == 4) {
          list[i][j].color = '#3587DA'
          list[i][j].name = '谷'
          list[i][j].areaStyle = {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(189, 222, 247)'
              },
              {
                offset: 1,
                color: 'rgba(189, 222, 247,0)'
              }
            ])
          }
        } else if (newVal[i].timeList[j].type == 5) {
          list[i][j].color = '#748EE3'
          list[i][j].name = '深谷'
          list[i][j].areaStyle = {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(216, 226, 248)'
              },
              {
                offset: 1,
                color: 'rgba(216, 226, 248,0)'
              }
            ])
          }
        }
      }

      // 时间排序
      // xAxiosArr[i] = []
      // for (let j = 0; j < newVal[i].timeList.length; j++) {
      //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[0]) && newVal[i].timeList[j].listTime[0]) {
      //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[0])
      //   }
      //   if (!xAxiosArr[i].includes(newVal[i].timeList[j].listTime[1]) && newVal[i].timeList[j].listTime[1]) {
      //     xAxiosArr[i].push(newVal[i].timeList[j].listTime[1])
      //   }
      //   // 时间排序
      //   xAxiosArr[i].sort(function (a, b) {
      //     // 将时间字符串转换为24小时制的分钟数
      //     var minA = new Date('1970-01-01T' + a + 'Z').getTime();
      //     var minB = new Date('1970-01-01T' + b + 'Z').getTime();
      //     return minA - minB;
      //   });
      //   // xAxios.value = xAxiosArr
      // }
      xAxiosArr[i] = []
      for (let k = 0; k <= 23; k++) {
        for (let j = 0; j <= 59; j = j + 1) {
          let hour = k < 10 ? "0" + k : k.toString();
          let minute = j < 10 ? "0" + j : j.toString();
          xAxiosArr[i].push(hour + ":" + minute);
        }
      }

      for (let j = 0; j < newVal[i].timeList.length; j++) {
        let startTime = ''
        let endTime = ''
        if (newVal[i].timeList[j].startTime && newVal[i].timeList[j].endTime && newVal[i].timeList[j].type) {
          for (let k = 0; k < xAxiosArr[i].length; k++) {
            if (newVal[i].timeList[j].startTime === xAxiosArr[i][k]) {
              startTime = k
            }
            if (newVal[i].timeList[j].endTime === xAxiosArr[i][k]) {
              endTime = k
            }
          }
        }
        for (let k = startTime; k < endTime + 1; k++) {
          // list[i][j].data[k] = Number(newVal[i].timeList[j].price)
          if (newVal[i].timeList[j].type == 1) {
            list[i][j].data[k] = 10
          } else if (newVal[i].timeList[j].type == 2) {
            list[i][j].data[k] = 8
          } else if (newVal[i].timeList[j].type == 3) {
            list[i][j].data[k] = 6
          } else if (newVal[i].timeList[j].type == 4) {
            list[i][j].data[k] = 4
          } else if (newVal[i].timeList[j].type == 5) {
            list[i][j].data[k] = 2
          }
        }
      }

      xAxios.value = xAxiosArr
      yAxios.value = list
      nextTick(() => {
        chartLoad2(i)
      })
    }
  }
}, {deep: true, immediate: true})
const chartLoad2 = function (idx) {
  var chartDom = document.querySelector(`.chartStrategy${idx}`);
  if (chartDom) {
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      grid: {
        left: 20,
        right: 20,
        bottom: 20,
        top: 40,
      },
      legend: {
        show: true,
      },
      tooltip: {
        show: false
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          textStyle: {
            fontWeight: 400,
            color: '#405873'
          },
          interval: 59
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        data: xAxios.value[idx]
      },
      yAxis: {
        type: 'value',
        show: false
      },
      series: yAxios.value[idx],
    };
    myChart.clear()
    option && myChart.setOption(option);
    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }
  }
}

let electrovalenceBagList = ref([])
onMounted(() => {
  getProjectListFun()
  // 获取电价组合包列表
  getPowerPriceGroupSelectApi().then(res => {
    if (res.data.code === 0) {
      electrovalenceBagList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

})

</script>

<style lang="less" scoped>
.eleQuery {
  .priceBox {
    .priceHeadBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px;

      .name {

      }

      .operation {

      }
    }

    .tableBox {
      .item {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .el-input {
          width: 160px;
        }

        .typeBox {
          padding: 0 4px;
          color: #fff;
          border-radius: 3px;
          height: 22px;
          line-height: 22px;
          margin-right: 6px;
        }

        .type1 {
          background: #F21110;
        }

        .type2 {
          background: #F57244;
        }

        .type3 {
          background: #50D5D9;
        }

        .type4 {
          background: #3587DA;
        }

        .type5 {
          background: #748EE3;
        }

        .type6 {
          background: #A084FA;
        }

        > p {
          display: inline-block;
        }
      }

      .status {
        padding: 0 8px;
        border-radius: 3px;
        display: inline-block;
      }
    }

  }


  .priceDialog {
    .dialogCont {
      .timeBox {
        display: flex;
        align-items: center;

        > span {
          margin-right: 12px;
        }
      }

      .titName {
        text-indent: 16px;
        margin: 16px 0;
        position: relative;
        color: #333333;
        font-weight: 400;
      }

      .titName::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 16px;
        background: #165DFF;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }

    .operationUl {
      display: flex;
      font-size: 16px;
      color: #2064FF;

      .deleteClass {
        color: #EF2121;
        margin-right: 12px;
      }

      .editClass, .deleteClass {
        font-weight: bold;
        transform: translateY(3px);
      }
    }
  }

  .el-dialog {
    //分时策略内容
    .strategyBox {
      .titName {
        text-indent: 16px;
        margin: 16px 0 12px;
        position: relative;
        color: #333333;
        font-weight: 400;
      }

      .titName::before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 16px;
        background: #165DFF;
        position: absolute;
        left: 0;
        top: 4px;
      }

      .chart {
        width: 100%;
        height: 212px;
        position: relative;
      }

      .chart::before {
        content: '';
        display: block;
        width: calc(100% - 35px);
        height: 152px;
        background: linear-gradient(90deg, #EEEEEE 20%, transparent 0);
        background-size: 5px 5px;
        position: absolute;
        top: 40px;
        left: 20px;
      }

      .timerBox {
        display: flex;
        align-items: center;

        > span {
          margin: 0 10px;
        }

      }

      .el-select, .el-input {
        width: 160px;
      }

      .operationUl {
        display: flex;

        li {
          margin: 0 5px;

          .el-icon {
            font-weight: bold;
            cursor: pointer;
          }
        }

        .deleteClass {
          color: #EF2121;
        }

        .editClass {
          color: #1890FF;
        }
      }


      .pricingMethod {
        display: flex;
        align-items: center;
      }
    }

    .delIcon {
      font-size: 20px;
      position: absolute;
      right: -8px;
      top: -10px;
      color: #FF0000;
      cursor: pointer;
    }
  }
}
</style>
