<template>
  <div class="onceImgPage">
    <div class="title">
      <p>
        <span>一次图</span>
      </p>
    </div>
    <div class="content">
      <!--左侧内容-->
      <div class="leftBox">
        <div class="searchBox">
          <el-input v-model="searchVal" class="input-with-select" clearable placeholder="请输入">
            <template #append>
              <el-button :icon="Search" @click="searchClick(customerList,searchVal)"/>
            </template>
          </el-input>
        </div>
        <div class="treeBox">
          <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                   :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                   :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
            <template #default="{ node, data }">
							<span class="custom-tree-node" style="width:100%;">
	              <span>{{ node.label }}</span>
							</span>
            </template>
          </el-tree>
        </div>
      </div>
      <!--右侧内容-->
      <div class="rightBox">
        <div style="height: 100% ;overflow: auto">
          <div class="opearBox">
            <el-button type="primary" @click="configurationClick">配置</el-button>
            <!--            <el-button @click="screenClick">全屏展示</el-button>-->
          </div>
          <!--          <div style="height: calc(100% - 64px);overflow: hidden">-->
          <!--            &lt;!&ndash;            <component :is="dynamicComponent"></component>&ndash;&gt;-->
          <!--            <OnceItem1 v-if="onceItemComponent==='OnceItem1'"/>-->
          <!--            <OnceItem2 v-if="onceItemComponent==='OnceItem2'"/>-->
          <!--            &lt;!&ndash;得力&ndash;&gt;-->
          <!--            <DeliOnceImg1 v-if="onceItemComponent==='DeliOnceImg1'"/>-->
          <!--            <DeliOnceImg2 v-if="onceItemComponent==='DeliOnceImg2'"/>-->
          <!--            <DeliOnceImg3 v-if="onceItemComponent==='DeliOnceImg3'"/>-->
          <!--            <DeliOnceImg4 v-if="onceItemComponent==='DeliOnceImg4'"/>-->
          <!--            <DeliOnceImg5 v-if="onceItemComponent==='DeliOnceImg5'"/>-->
          <!--            <DeliOnceImg6 v-if="onceItemComponent==='DeliOnceImg6'"/>-->
          <!--            <DeliOnceImg7 v-if="onceItemComponent==='DeliOnceImg7'"/>-->
          <!--            <DeliOnceImg8 v-if="onceItemComponent==='DeliOnceImg8'"/>-->
          <!--            <DeliOnceImg9 v-if="onceItemComponent==='DeliOnceImg9'"/>-->
          <!--            <DeliOnceImg10 v-if="onceItemComponent==='DeliOnceImg10'"/>-->

          <!--            &lt;!&ndash;武汉&ndash;&gt;-->
          <!--            <WhGOnceImg1 v-if="onceItemComponent==='WhGOnceImg1'"/>-->
          <!--            <WhGOnceImg2 v-if="onceItemComponent==='WhGOnceImg2'"/>-->
          <!--            <WhGOnceImg3 v-if="onceItemComponent==='WhGOnceImg3'"/>-->
          <!--            <WhGOnceImg4 v-if="onceItemComponent==='WhGOnceImg4'"/>-->
          <!--            <WhGOnceImg5 v-if="onceItemComponent==='WhGOnceImg5'"/>-->
          <!--            <WhGOnceImg6 v-if="onceItemComponent==='WhGOnceImg6'"/>-->
          <!--            <WhGOnceImg7 v-if="onceItemComponent==='WhGOnceImg7'"/>-->
          <!--            <WhGOnceImg8 v-if="onceItemComponent==='WhGOnceImg8'"/>-->
          <!--            <WhDOnceImg2T1A v-if="onceItemComponent==='WhDOnceImg2T1A'"/>-->
          <!--            <WhDOnceImg2T1B v-if="onceItemComponent==='WhDOnceImg2T1B'"/>-->
          <!--            <WhDOnceImg2T2A v-if="onceItemComponent==='WhDOnceImg2T2A'"/>-->
          <!--            <WhDOnceImg2T2B v-if="onceItemComponent==='WhDOnceImg2T2B'"/>-->
          <!--            <WhDOnceImg2T3A v-if="onceItemComponent==='WhDOnceImg2T3A'"/>-->
          <!--            <WhDOnceImg2T4A v-if="onceItemComponent==='WhDOnceImg2T4A'"/>-->
          <!--            <WhDOnceImg3T1A v-if="onceItemComponent==='WhDOnceImg3T1A'"/>-->
          <!--            <WhDOnceImg3T1B v-if="onceItemComponent==='WhDOnceImg3T1B'"/>-->
          <!--            <WhDOnceImg3T2A v-if="onceItemComponent==='WhDOnceImg3T2A'"/>-->
          <!--            <WhDOnceImg3T2B v-if="onceItemComponent==='WhDOnceImg3T2B'"/>-->
          <!--            <WhDOnceImg3T3A v-if="onceItemComponent==='WhDOnceImg3T3A'"/>-->
          <!--            <WhDOnceImg3T4A v-if="onceItemComponent==='WhDOnceImg3T4A'"/>-->
          <!--            <WhDOnceImg4T1A v-if="onceItemComponent==='WhDOnceImg4T1A'"/>-->
          <!--            <WhDOnceImg4T2A v-if="onceItemComponent==='WhDOnceImg4T2A'"/>-->
          <!--            <WhDOnceImg4T3A v-if="onceItemComponent==='WhDOnceImg4T3A'"/>-->
          <!--            <WhDOnceImg4T3B v-if="onceItemComponent==='WhDOnceImg4T3B'"/>-->
          <!--            <WhDOnceImg4T4A v-if="onceItemComponent==='WhDOnceImg4T4A'"/>-->
          <!--            <WhDOnceImg4T4B v-if="onceItemComponent==='WhDOnceImg4T4B'"/>-->
          <!--            <WhDOnceImg5T1A v-if="onceItemComponent==='WhDOnceImg5T1A'"/>-->
          <!--            <WhDOnceImg5T2A v-if="onceItemComponent==='WhDOnceImg5T2A'"/>-->
          <!--            <WhDOnceImg5T3A v-if="onceItemComponent==='WhDOnceImg5T3A'"/>-->
          <!--            <WhDOnceImg5T3B v-if="onceItemComponent==='WhDOnceImg5T3B'"/>-->
          <!--            <WhDOnceImg5T4A v-if="onceItemComponent==='WhDOnceImg5T4A'"/>-->
          <!--            <WhDOnceImg5T4B v-if="onceItemComponent==='WhDOnceImg5T4B'"/>-->
          <!--            <WhDOnceImg6T1A v-if="onceItemComponent==='WhDOnceImg6T1A'"/>-->
          <!--            <WhDOnceImg6T2A v-if="onceItemComponent==='WhDOnceImg6T2A'"/>-->
          <!--            <WhCOnceImg1 v-if="onceItemComponent==='WhCOnceImg1'"/>-->
          <!--            <WhCOnceImg2 v-if="onceItemComponent==='WhCOnceImg2'"/>-->
          <!--          </div>-->
          <div class="contBox"
               style="width: calc(100% - 10px); height: calc(100% - 64px);overflow: auto;padding: 16px 5px 0 5px">
            <div v-for="(item,index) in onceImgList" :key="index" class="boxItem">
              <div class="largeScreenTitle">
                <p>
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/homeItem2.png"
                       style="width: 24px;height: 24px;"/>
                  <span style="margin-left: 5px;">{{ item.name }}</span>
                </p>
              </div>
              <div class="largeScreenContent" style="margin: 10px 0">
                <div style="width: 100%;height: 100%;overflow: hidden">
                  <!--            <component :is="dynamicComponent"></component>-->
                  <OnceItem1 v-if="item.onePicAddress==='OnceItem1'"/>
                  <OnceItem2 v-if="item.onePicAddress==='OnceItem2'"/>
                  <!--得力-->
                  <DeliOnceImg1 v-if="item.onePicAddress==='DeliOnceImg1'"/>
                  <DeliOnceImg2 v-if="item.onePicAddress==='DeliOnceImg2'"/>
                  <DeliOnceImg3 v-if="item.onePicAddress==='DeliOnceImg3'"/>
                  <DeliOnceImg4 v-if="item.onePicAddress==='DeliOnceImg4'"/>
                  <DeliOnceImg5 v-if="item.onePicAddress==='DeliOnceImg5'"/>
                  <DeliOnceImg6 v-if="item.onePicAddress==='DeliOnceImg6'"/>
                  <DeliOnceImg7 v-if="item.onePicAddress==='DeliOnceImg7'"/>
                  <DeliOnceImg8 v-if="item.onePicAddress==='DeliOnceImg8'"/>
                  <DeliOnceImg9 v-if="item.onePicAddress==='DeliOnceImg9'"/>
                  <DeliOnceImg10 v-if="item.onePicAddress==='DeliOnceImg10'"/>

                  <!--武汉-->
                  <WhGOnceImg1 v-if="item.onePicAddress==='WhGOnceImg1'"/>
                  <WhGOnceImg2 v-if="item.onePicAddress==='WhGOnceImg2'"/>
                  <WhGOnceImg3 v-if="item.onePicAddress==='WhGOnceImg3'"/>
                  <WhGOnceImg4 v-if="item.onePicAddress==='WhGOnceImg4'"/>
                  <WhGOnceImg5 v-if="item.onePicAddress==='WhGOnceImg5'"/>
                  <WhGOnceImg6 v-if="item.onePicAddress==='WhGOnceImg6'"/>
                  <WhGOnceImg7 v-if="item.onePicAddress==='WhGOnceImg7'"/>
                  <WhGOnceImg8 v-if="item.onePicAddress==='WhGOnceImg8'"/>
                  <WhDOnceImg1T1A v-if="item.onePicAddress==='WhDOnceImg1T1A'"/>
                  <WhDOnceImg1T1B v-if="item.onePicAddress==='WhDOnceImg1T1B'"/>
                  <WhDOnceImg1T2A v-if="item.onePicAddress==='WhDOnceImg1T2A'"/>
                  <WhDOnceImg1T2B v-if="item.onePicAddress==='WhDOnceImg1T2B'"/>
                  <WhDOnceImg1T3A v-if="item.onePicAddress==='WhDOnceImg1T3A'"/>
                  <WhDOnceImg1T4A v-if="item.onePicAddress==='WhDOnceImg1T4A'"/>
                  <WhDOnceImg2T1A v-if="item.onePicAddress==='WhDOnceImg2T1A'"/>
                  <WhDOnceImg2T1B v-if="item.onePicAddress==='WhDOnceImg2T1B'"/>
                  <WhDOnceImg2T2A v-if="item.onePicAddress==='WhDOnceImg2T2A'"/>
                  <WhDOnceImg2T2B v-if="item.onePicAddress==='WhDOnceImg2T2B'"/>
                  <WhDOnceImg2T3A v-if="item.onePicAddress==='WhDOnceImg2T3A'"/>
                  <WhDOnceImg2T4A v-if="item.onePicAddress==='WhDOnceImg2T4A'"/>
                  <WhDOnceImg3T1A v-if="item.onePicAddress==='WhDOnceImg3T1A'"/>
                  <WhDOnceImg3T1B v-if="item.onePicAddress==='WhDOnceImg3T1B'"/>
                  <WhDOnceImg3T2A v-if="item.onePicAddress==='WhDOnceImg3T2A'"/>
                  <WhDOnceImg3T2B v-if="item.onePicAddress==='WhDOnceImg3T2B'"/>
                  <WhDOnceImg3T3A v-if="item.onePicAddress==='WhDOnceImg3T3A'"/>
                  <WhDOnceImg3T4A v-if="item.onePicAddress==='WhDOnceImg3T4A'"/>
                  <WhDOnceImg4T1A v-if="item.onePicAddress==='WhDOnceImg4T1A'"/>
                  <WhDOnceImg4T2A v-if="item.onePicAddress==='WhDOnceImg4T2A'"/>
                  <WhDOnceImg4T3A v-if="item.onePicAddress==='WhDOnceImg4T3A'"/>
                  <WhDOnceImg4T3B v-if="item.onePicAddress==='WhDOnceImg4T3B'"/>
                  <WhDOnceImg4T4A v-if="item.onePicAddress==='WhDOnceImg4T4A'"/>
                  <WhDOnceImg4T4B v-if="item.onePicAddress==='WhDOnceImg4T4B'"/>
                  <WhDOnceImg5T1A v-if="item.onePicAddress==='WhDOnceImg5T1A'"/>
                  <WhDOnceImg5T2A v-if="item.onePicAddress==='WhDOnceImg5T2A'"/>
                  <WhDOnceImg5T3A v-if="item.onePicAddress==='WhDOnceImg5T3A'"/>
                  <WhDOnceImg5T3B v-if="item.onePicAddress==='WhDOnceImg5T3B'"/>
                  <WhDOnceImg5T4A v-if="item.onePicAddress==='WhDOnceImg5T4A'"/>
                  <WhDOnceImg5T4B v-if="item.onePicAddress==='WhDOnceImg5T4B'"/>
                  <WhDOnceImg6T1A v-if="item.onePicAddress==='WhDOnceImg6T1A'"/>
                  <WhDOnceImg6T2A v-if="item.onePicAddress==='WhDOnceImg6T2A'"/>
                  <WhCOnceImg1 v-if="item.onePicAddress==='WhCOnceImg1'"/>
                  <WhCOnceImg2 v-if="item.onePicAddress==='WhCOnceImg2'"/>

                  <!--海盐秦编-->
                  <HyOnceImg1 v-if="item.onePicAddress==='HyOnceImg1'"/>
                  <HyOnceImg2 v-if="item.onePicAddress==='HyOnceImg2'"/>

                  <!--凯旋校区-->
                  <KxOnceImg1 v-if="item.onePicAddress==='KxOnceImg1'"></KxOnceImg1>
                  <KxOnceImg2 v-if="item.onePicAddress==='KxOnceImg2'"></KxOnceImg2>
                  <KxOnceImg3 v-if="item.onePicAddress==='KxOnceImg3'"></KxOnceImg3>
                </div>
              </div>
              <el-button class="detailButton" style="width:100%" type="primary"
                         @click="screenClick(index)">
                查看详情
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="配置" width="460">
      <!--      <div class="liPox" style="display: flex;align-items: center">-->
      <!--        <span style="width: 100px;">一次图地址</span>-->
      <!--        <el-input v-model="onceImgUrl" placeholder="请输入一次图地址"></el-input>-->
      <!--      </div>-->
      <el-form ref="fromRef" :inline="true" :model="formData">
        <div v-for="(item,index) in formData.targetJsonStr" :key="index" class="rowItem">
          <el-form-item class="data" name="item.name">
            <el-input v-model="item.name" class="item" placeholder="请输入一次图名称"></el-input>
          </el-form-item>
          <el-form-item class="data" name="item.onePicAddress">
            <el-input v-model="item.onePicAddress" class="item" placeholder="请输入一次图地址"></el-input>
          </el-form-item>

          <el-icon class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.targetJsonStr.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
        <el-button v-if="formData.targetJsonStr.length===0" type="primary" @click="addItemClick">添加配置</el-button>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {computed, markRaw, nextTick, onMounted, reactive, ref, resolveComponent, shallowRef, watch} from "vue";
import './onceImg.less'
import * as echarts from "echarts";
import {useRouter} from "vue-router/dist/vue-router";
import {Search, Plus, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {getProjectListApi} from "@/api/modules/project";
import {ElMessage} from "element-plus";
import qs from "qs";
import {editOnceImgDataApiV2, getOnceImgDataApiV2} from "@/api/modules/onceImg";

// 组件
import OnceItem1 from './onceItem/onceItem1'
import OnceItem2 from './onceItem/onceItem2'

// 得力一次图
import DeliOnceImg1 from './onceItem/deli/deliOnceImg1'   //一号车间2D07
import DeliOnceImg2 from './onceItem/deli/deliOnceImg2'   //一号车间2D06
import DeliOnceImg3 from './onceItem/deli/deliOnceImg3'   //二号车间1D08
import DeliOnceImg4 from './onceItem/deli/deliOnceImg4'   //二号车间1D07
import DeliOnceImg5 from './onceItem/deli/deliOnceImg5'   //三号车间3D09
import DeliOnceImg6 from './onceItem/deli/deliOnceImg6'   //三号车间3D08
import DeliOnceImg7 from './onceItem/deli/deliOnceImg7'   //三号宿舍
import DeliOnceImg8 from './onceItem/deli/deliOnceImg8'   //四号车间4D09
import DeliOnceImg9 from './onceItem/deli/deliOnceImg9'   //四号车间4D08
import DeliOnceImg10 from './onceItem/deli/deliOnceImg10' //四号宿舍

// 武汉一次图
import WhGOnceImg1 from './onceItem/wuhan/whGOnceImg1'  //10kV中心配电室高压配电系统图一  ---  高压
import WhGOnceImg2 from './onceItem/wuhan/whGOnceImg2'  //10kV中心配电室高压配电系统图二  ---  高压
import WhGOnceImg3 from './onceItem/wuhan/whGOnceImg3'  //2#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg4 from './onceItem/wuhan/whGOnceImg4'  //3#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg5 from './onceItem/wuhan/whGOnceImg5'  //4#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg6 from './onceItem/wuhan/whGOnceImg6'  //5#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg7 from './onceItem/wuhan/whGOnceImg7'  //6#变配电房10KV高压配电系统图  ---  高压
import WhGOnceImg8 from './onceItem/wuhan/whGOnceImg8'  //1#变配电房10KV高压配电系统图  ---  高压
import WhDOnceImg1T1A from './onceItem/wuhan/whDOnceImg1_t1A'  //1#变配电房 1-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg1T1B from './onceItem/wuhan/whDOnceImg1_t1B'  //1#变配电房 1-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg1T2A from './onceItem/wuhan/whDOnceImg1_t2A'  //1#变配电房 1-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg1T2B from './onceItem/wuhan/whDOnceImg1_t2B'  //1#变配电房 1-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg1T3A from './onceItem/wuhan/whDOnceImg1_t3A'  //1#变配电房 1-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg1T4A from './onceItem/wuhan/whDOnceImg1_t4A'  //1#变配电房 1-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg2T1A from './onceItem/wuhan/whDOnceImg2_t1A'  //2#变配电房 2-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg2T1B from './onceItem/wuhan/whDOnceImg2_t1B'  //2#变配电房 2-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg2T2A from './onceItem/wuhan/whDOnceImg2_t2A'  //2#变配电房 2-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg2T2B from './onceItem/wuhan/whDOnceImg2_t2B'  //2#变配电房 2-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg2T3A from './onceItem/wuhan/whDOnceImg2_t3A'  //2#变配电房 2-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg2T4A from './onceItem/wuhan/whDOnceImg2_t4A'  //2#变配电房 2-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg3T1A from './onceItem/wuhan/whDOnceImg3_t1A'  //3#变配电房 3-T1 变压器低压配电系统图一   ---  低压
import WhDOnceImg3T1B from './onceItem/wuhan/whDOnceImg3_t1B'  //3#变配电房 3-T1 变压器低压配电系统图二   ---  低压
import WhDOnceImg3T2A from './onceItem/wuhan/whDOnceImg3_t2A'  //3#变配电房 3-T2 变压器低压配电系统图一   ---  低压
import WhDOnceImg3T2B from './onceItem/wuhan/whDOnceImg3_t2B'  //3#变配电房 3-T2 变压器低压配电系统图二   ---  低压
import WhDOnceImg3T3A from './onceItem/wuhan/whDOnceImg3_t3A'  //3#变配电房 3-T3 变压器低压配电系统图   ---  低压
import WhDOnceImg3T4A from './onceItem/wuhan/whDOnceImg3_t4A'  //3#变配电房 3-T4 变压器低压配电系统图   ---  低压
import WhDOnceImg4T1A from './onceItem/wuhan/whDOnceImg4_t1A'  //4#变配电房 4-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg4T2A from './onceItem/wuhan/whDOnceImg4_t2A'  //4#变配电房 4-T2 变压器低压配电系统图   ---  低压
import WhDOnceImg4T3A from './onceItem/wuhan/whDOnceImg4_t3A'  //4#变配电房 4-T3 变压器低压配电系统图一   ---  低压
import WhDOnceImg4T3B from './onceItem/wuhan/whDOnceImg4_t3B'  //4#变配电房 4-T3 变压器低压配电系统图二   ---  低压
import WhDOnceImg4T4A from './onceItem/wuhan/whDOnceImg4_t4A'  //4#变配电房 4-T4 变压器低压配电系统图一   ---  低压
import WhDOnceImg4T4B from './onceItem/wuhan/whDOnceImg4_t4B'  //4#变配电房 4-T4 变压器低压配电系统图二   ---  低压
import WhDOnceImg5T1A from './onceItem/wuhan/whDOnceImg5_t1A'  //5#变配电房 5-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg5T2A from './onceItem/wuhan/whDOnceImg5_t2A'  //5#变配电房 5-T2 变压器低压配电系统图   ---  低压
import WhDOnceImg5T3A from './onceItem/wuhan/whDOnceImg5_t3A'  //5#变配电房 5-T3 变压器低压配电系统图一   ---  低压
import WhDOnceImg5T3B from './onceItem/wuhan/whDOnceImg5_t3B'  //5#变配电房 5-T3 变压器低压配电系统图二   ---  低压
import WhDOnceImg5T4A from './onceItem/wuhan/whDOnceImg5_t4A'  //5#变配电房 5-T4 变压器低压配电系统图一   ---  低压
import WhDOnceImg5T4B from './onceItem/wuhan/whDOnceImg5_t4B'  //5#变配电房 5-T4 变压器低压配电系统图二   ---  低压
import WhDOnceImg6T1A from './onceItem/wuhan/whDOnceImg6_t1A'  //6#变配电房 6-T1 变压器低压配电系统图   ---  低压
import WhDOnceImg6T2A from './onceItem/wuhan/whDOnceImg6_t2A'  //6#变配电房 6-T2 变压器低压配电系统图   ---  低压
import WhCOnceImg1 from './onceItem/wuhan/whCOnceImg1'  //1#柴油发电机房低压配电系统图   ---  低压
import WhCOnceImg2 from './onceItem/wuhan/whCOnceImg2'  //2#柴油发电机房低压配电系统图   ---  低压


// 海盐
import HyOnceImg1 from './onceItem/haiyan/hyOnceImg1'  //海盐秦编科技
import HyOnceImg2 from './onceItem/haiyan/hyOnceImg2'  //嘉乐创紧固件

// 凯旋校区
import KxOnceImg1 from './onceItem/kaixuan/kxOnceImg1'  //10kv配电系统图
import KxOnceImg2 from './onceItem/kaixuan/kxOnceImg2'  //TM1低压系统图
import KxOnceImg3 from './onceItem/kaixuan/kxOnceImg3'  //TM2低压系统图


let router = useRouter()
let onceItemComponent = ref(null);
const dynamicComponent = computed(() => {
  if (onceItemComponent.value) {
    // 根据组件名字符串转换为组件实例
    return resolveComponent(onceItemComponent.value);
  }
})

// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      //有无路由参数
      if (router.currentRoute.value.query.id) {
        defaultExpandKeys.value = [router.currentRoute.value.query.id]
      } else {
        if (window.localStorage.getItem('currentNodeKey3')) {
          defaultExpandKeys.value = [window.localStorage.getItem('currentNodeKey3')]
        } else {
          defaultExpandKeys.value = [customerList.value[0].id]
        }
      }
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        if (router.currentRoute.value.query.id) {
          currentNodeKey.value = router.currentRoute.value.query.id
          custerObj.value = JSON.parse(window.localStorage.getItem('project'))
        } else {
          if (window.localStorage.getItem('currentNodeKey3')) {
            currentNodeKey.value = window.localStorage.getItem('currentNodeKey3')
            custerObj.value = JSON.parse(window.localStorage.getItem('custerObj3'))
          } else {
            currentNodeKey.value = customerList.value[0].id
            custerObj.value = customerList.value[0]
          }
        }
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  window.localStorage.setItem('currentNodeKey3', currentNodeKey.value)
  window.localStorage.setItem('custerObj3', JSON.stringify(e))
  defaultExpandKeys.value.push(e.parentId)
}


// 一次图
let flag = ref(false)
const clickFun = function () {
  flag.value = !flag.value
}

// 一次图配置
let onceImgUrl = ref() //一次图地址
let dialogFormVisible = ref(false) //一次图弹窗
const configurationClick = function () {
  onceImgUrl.value = ''
  dialogFormVisible.value = true
  if (onceImgList.value.length > 0) {
    formData.value.targetJsonStr = JSON.parse(JSON.stringify(onceImgList.value))
  }

  console.log(formData.value.targetJsonStr)
}

// 一次图配置
let formData = ref({
  targetJsonStr: [
    {
      name: '',
      onePicAddress: ''
    }
  ]
})
// 点击增加设备
const addItemClick = function () {
  formData.value.targetJsonStr.push({
    name: '',
    onePicAddress: ''
  })
}
// 点击删除设备
const delItemClick = function (index) {
  formData.value.targetJsonStr.splice(index, 1)
}

let onceImgList = ref([])


// 提交一次图配置
const save = function () {
  editOnceImgDataApiV2(qs.stringify({
    onePicAddressListJson: JSON.stringify(formData.value.targetJsonStr),
    projectId: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      getOnceImgDataFun()
      dialogFormVisible.value = false
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 获取一次图配置
const getOnceImgDataFun = function () {
  getOnceImgDataApiV2(qs.stringify({
    projectId: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      onceImgList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 全屏展示
const screenClick = function (idx) {
  router.push({
    path: '/onceImgScreen',
    query: {
      id: custerObj.value.id,
      idx: idx
    }
  })
}
watch(custerObj, (newVal) => {
  if (newVal && newVal.id) {
    getOnceImgDataFun()
  }
}, {deep: true, immediate: true})
watch(() => onceImgList.value, () => {
  nextTick(() => {
    for (let i = 0; i < document.querySelectorAll('.largeScreenContent').length; i++) {
      document.querySelectorAll('.largeScreenContent')[i].style.height = document.querySelectorAll('.largeScreenContent')[i].clientWidth * 0.75 + 'px'
    }
  })
}, {deep: true, immediate: true})
onMounted(() => {
  getProjectListFun()

  nextTick(() => {
    for (let i = 0; i < document.querySelectorAll('.largeScreenContent').length; i++) {
      document.querySelectorAll('.largeScreenContent')[i].style.height = document.querySelectorAll('.largeScreenContent')[i].clientWidth * 0.75 + 'px'
    }
  })
})

</script>

<style lang="less" scoped>
</style>
