<template>
  <div class="homePage">
    <div class="content">
      <!--上面-->
      <div class="topBox">
        <div class="topTitle">
          <img alt="" src="../../assets/img/haunying@2x.png"/> 欢迎登录，数字能源中心
        </div>
        <div class="topContain">
          <div class="itemCont" @click="router.push('/admin/project')">
            <p class="tit">总设备数</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.equipmentNumberTotal }}</span>
                    <span class="unit">台</span>
                  </p>
                </div>
                <div class="onlineClass">
                  目前在线 <span>{{ indexDataObj.equipmentNumberOnline }}</span> 台
                </div>
              </div>
              <div class="rightBox">
                <el-progress :indeterminate="true"
                             :percentage="(indexDataObj.equipmentNumberOnline/indexDataObj.equipmentNumberTotal)*100"
                             :show-text="false" :stroke-width="14" color="#165DFF" type="circle"/>
              </div>
            </div>
          </div>
          <div class="itemCont" @click="router.push('/admin/gateway')">
            <p class="tit">总网关数</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.gatewayNumberTotal }}</span>
                    <span class="unit">台</span>
                  </p>
                </div>
                <div class="onlineClass">
                  目前在线 <span>{{ indexDataObj.gatewayNumberOnline }}</span> 台
                </div>
              </div>
              <div class="rightBox">
                <el-progress :indeterminate="true"
                             :percentage="(indexDataObj.gatewayNumberOnline/indexDataObj.gatewayNumberTotal)*100"
                             :show-text="false" :stroke-width="14" color="#8D4EDA" type="circle"/>
              </div>
            </div>
          </div>
          <div class="itemCont" @click="router.push('/admin/workOrder')">
            <div class="echartsBox">
              <div class="kChart2"></div>
            </div>
            <p class="tit">运维工单</p>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{ indexDataObj.ticketNumberTotal }}</span>
                    <span class="unit">单</span>
                  </p>
                </div>
                <!--<div class="weekClass">
                  <label>本周 </label> <span class="weekTitle">{{ indexDataObj.ticketNumberWeek}}</span>
                  <span><img src="../../assets/img/up.png" alt="" /></span>
                  <label> 本月 </label><span class="monthTitle">{{ indexDataObj.ticketNumberMonth}}</span>
                  <span><img src="../../assets/img/down.png" alt="" /></span>
                </div>-->
              </div>
            </div>
          </div>
          <!--        <div class="itemCont" @click="router.push('/admin/alarm')">-->
          <!--          <p class="tit">当前设备告警</p>-->
          <!--          <div class="center">-->
          <!--            <div class="dataCont">-->
          <!--              <img alt="" src="../../assets/img/591.svg">-->
          <!--              <p>-->
          <!--                <span class="val">1041</span>-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <p class="name">佐小二智慧平台</p>-->
          <!--          </div>-->
          <!--          <div class="line">-->
          <!--            <div class="rowChart">-->
          <!--              <p style="width: 60%;background-color: #FF4A4A;"></p>-->
          <!--              <p style="width: 30%;background-color: #FEA553;"></p>-->
          <!--              <p style="width: 10%;background-color: #D4DCDE;"></p>-->
          <!--            </div>-->
          <!--            <div class="rowChartName">-->
          <!--              <div>-->
          <!--                <p style="background-color: #FF4A4A;"></p>-->
          <!--                <span>重要</span>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <p style="background-color: #FEA553;"></p>-->
          <!--                <span>次要</span>-->
          <!--              </div>-->
          <!--              <div>-->
          <!--                <p style="background-color: #D4DCDE;"></p>-->
          <!--                <span>提示</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="itemCont">
            <div class="tit">总记录数
            </div>
            <div class="center">
              <div class="leftBox">
                <div class="dataCont">
                  <p>
                    <span class="val">{{
                        indexDataObj.storeNumber.slice(-1) === '万' ? Math.round(Number(String(indexDataObj.storeNumber).slice(0, -1))) : Math.round(Number(String(indexDataObj.storeNumber)))
                      }}</span>
                    <!--<span class="val">111</span>-->
                    <span class="unit">{{ String(indexDataObj.storeNumber).slice(-1) === '万' ? '万' : '' }}</span>
                  </p>
                </div>
                <!--<div class="weekClass">
                  <label>较昨天 </label> <span class="weekTitle"></span>
                  <span><img src="../../assets/img/up.png" alt="" /></span>
                </div>-->
              </div>
              <div class="rightBox">
                <div class="kChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--数据查询-->
      <div class="title3" style="display: flex;justify-content: space-between;align-items: center;margin-right: 20px;">
        <p style="display:flex;align-items: center">
          <span>数据图表</span>
          <el-button :icon="Plus" style="margin-left: 16px" type="primary" @click="addClick">添加图表</el-button>
        </p>
        <div>
          <el-button @click="router.push('/admin/searchPlan')">添加简单方案</el-button>
          <el-button @click="router.push('/admin/compoundQueryPlan')">添加复合方案</el-button>
        </div>
      </div>
      <div class="largeScreenBox largeScreenBoxCopy">
        <div v-for="(item,index) in indexDataObj.dataSearchList" class="largeScreenUl">
          <div class="largeScreenTitle">
            <p>
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/homeItem2.png"
                   style="width: 24px;height: 24px;"/>
              <span>
                {{ item.title }}
						  </span>
            </p>
            <el-icon @click="deleteClick(item,item.queryId)">
              <CloseBold/>
            </el-icon>
          </div>
          <div class="largeScreenContent">
            <!--单个查询-->
            <div v-if="screenGroup.queryGroupName === ''||!isScreen" class="largeScreenContent2">
              <div :class="'chart'+index"
                   :style="{height:isScreen?'calc(100% - 182px)':'100%',width:isScreen?'calc(100% - 40px)':'100%',marginTop:isScreen?'10px':0,padding:isScreen?'0 20px':0}"
                   class="chart"></div>
            </div>
          </div>
          <el-button class="detailButton" style="width:100%" type="primary"
                     @click="enterFullScreen(index,item.queryId,item)">
            查看详情
          </el-button>
        </div>
      </div>
      <!--数据大屏-->
      <div class="title3">
        <p>
          <span>数据大屏控制</span>
        </p>
      </div>
      <div class="largeScreenBox">
        <el-tooltip v-for="item in indexDataObj.dataScreenList" class="box-item" content="单击查看大屏详情" effect="dark"
                    placement="top">
          <div class="largeScreenUl" @click="detailClick(item.requestUrl)">
            <div class="largeScreenTitle">
              <img alt="" src="../../assets/img/largeScreenTitle.png"/>
              <span>
							{{ item.name }}
						</span>
            </div>
            <div class="mask"></div>
            <div class="largeScreenContent">
              <el-image :src="item.imageUrl" fit="scale-down"/>
            </div>
            <el-button class="detailButton" style="width:100%" type="primary" @click="detailClick(item.requestUrl)">
              查看详情
            </el-button>
          </div>
        </el-tooltip>
      </div>
    </div>
    <!--创建图表弹窗-->
    <el-drawer v-model="drawerChart" :show-close="false" direction="rtl" size="580">
      <template #header>
        <span class="tltleClass">添加图表</span>
        <span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerChart=false">
						<CloseBold/>
					</el-icon>
				</span>
      </template>
      <template #footer>
        <div class="operBox">
          <el-button class="resetButton" @click="drawerChart=false">取消</el-button>
          <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;height: calc(100% - 40px)">
          <el-form ref="chartFormRef" class="demo-ruleForm"
                   status-icon>
            <el-form-item label="分组:">
              <el-select v-model="queryGroup" :teleported="false" allow-create class="planBox"
                         clearable filterable placeholder="请选择已有分组或新增分组">
                <el-option v-for="(item,index) in queryGroupList" :key="index" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="查询类型:">
              <el-radio-group v-model="planType" @change="planTypeSelect">
                <el-radio :value="0">简单查询</el-radio>
                <el-radio :value="1">复合查询</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="查询方案:">
              <el-select v-model="plan" :teleported="false" class="planBox" clearable
                         filterable placeholder="请选择方案" @change="getUseEquipmentFun">
                <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div v-if="checkList.length>0" class="transferRightBox">
            <div class="head">
              <p>已应用的设备</p>
            </div>
            <!--多设备操作-->
            <div class="transferContain">
              <div class="transferContainHead">
                <p>
                  <el-checkbox v-model="checkAll" @change="checkAllFun"></el-checkbox>
                </p>
                <p>序号</p>
                <p v-for="(item,index) in checkList[0].arr" :key="index">设备{{ index + 1 }}</p>
              </div>
              <div class="transferContainCont">
                <div v-for="(item,index) in checkList" :key="index" class="transferContainItem">
                  <p style="width: 40px">
                    <el-checkbox v-model="item.check"></el-checkbox>
                  </p>
                  <p style="width: 60px">
                    <span>{{ index + 1 }}</span>
                  </p>
                  <p v-for="(item_t,index_t) in item.arr" :key="index_t"
                     :style="{width: checkListWidth[index_t+2]+'px'}">
                    <el-tooltip :content="item_t.value">
                      <span style="color: #165DFF">{{ item_t.value }}</span>
                    </el-tooltip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import './homePage.less'
import * as echarts from 'echarts';
import {useRouter, useRoute} from 'vue-router';
import qs from "qs";
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {Delete, ZoomIn, Plus, Back, CircleClose, CirclePlus, Edit, CloseBold} from '@element-plus/icons-vue'
import {
  indexData,
  saveMicroApplication,
  deleteMicroApplication,
  addHomeQueryApi,
  delHomeQueryApi
} from '@/api/modules/homePage.js'
import {
  exportDataApi,
  getChartDataByQueryIdApi,
  getEchartsDataApi, getIntervalListApi,
  getSearchDetailApi,
  getSearchListApi, getUseEquipmentApi
} from "@/api/modules/dataSearch";
import moment from "moment";
import {
  addQueryGroupListApi,
  delProjectQueryApi,
  delQueryGroupListApi, getQueryGroupDataApi,
  getQueryGroupListApi
} from "@/api/modules/project";


let router = useRouter()
const route = useRoute();
let indexDataObj = ref({storeNumber: ''}) //首页数据
//首页数据
const getIndexData = async function () {
  openFullScreen()
  await indexData().then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result
      nextTick(() => {
        chatLoad()
        chatLoad2()
      })

      // 处理加载数据查询图表
      queryChartLoad()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//查看大屏
const detailClick = function (val) {
  window.open(`#${val}`, '_blank');
}
const chatLoad2 = function () {
  var chartDom = document.querySelector('.kChart2')
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 0,
      top: 300,
      right: 0,
      bottom: 0,
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: '5%',
      left: 'center',
      icon: 'circle',
      itemWidth: 6,
      itemHeight: 6,
      textStyle: {
        fontSize: 12,
        color: '#4E5969'
      }
    },
    series: [{
      name: '',
      type: 'pie',
      center: ['50%', '60%'],
      radius: ['50%', '65%'],
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      data: [{
        value: indexDataObj.value.ticketNumberComplete,
        name: '已完结',
        itemStyle: {
          color: '#165DFF'
        }
      },
        {
          value: indexDataObj.value.ticketNumberTotal - indexDataObj.value.ticketNumberComplete,
          name: '待处理',
          itemStyle: {
            color: '#90ACFF'
          }
        }
      ]
    }]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}
const chatLoad = function () {
  var chartDom = document.querySelector('.kChart')
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    grid: {
      x: 0, //左留白
      y: 0, //上留白
      x2: 0, //右留白
      y2: 0 //下留白
    },
    xAxis: {
      type: 'category',
      data: indexDataObj.value.storeWeekTimeList,
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      axisLine: { //坐标轴轴线
        show: false
      },
      axisTick: { //坐标轴刻度
        show: false
      }
    },
    yAxis: {
      splitLine: { //坐标轴在grid区域中的分割线
        show: false
      },
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      min: function (value) {
        return value.min;
      }
      //logBase: 10, // 底数
    },
    series: [{
      data: indexDataObj.value.storeWeekDataList,
      type: 'bar',
      itemStyle: {
        color: function (params) {
          // 定义换色数组
          var colorList = ['#2CAB40', '#86DF6C']
          if (params.dataIndex % 2 == 0) {
            return colorList[0]
          } else {
            return colorList[1]
          }
        },
        borderRadius: [4, 4, 4, 4]

      },
      //barMinHeight:30
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 数据查询操作-------------------------------------------------------------------------------
let planType = ref()  //查询方案类型
// 获取查询方案列表------------------------
let plan = ref()  //查询方案id
let planList = ref([])
// 获取方案列表
const planTypeSelect = async function (e) {
  plan.value = ''
  checkList.value = []
  await getSearchListApi({type: e}).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 新增数据查询------------------------
let drawerChart = ref(false)  //创建图表弹窗
// 点击新增
const addClick = function () {
  drawerChart.value = true
  planType.value = ''
  plan.value = ''
  queryGroup.value = ''
  checkList.value = []
}

// 创建图表提交
const saveProjectSubmit = function () {
  let list = []
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkList.value[i].check) {
      list[i] = []
      for (let j = 0; j < checkList.value[i].arr.length; j++) {
        list[i].push(checkList.value[i].arr[j].key)
      }
    }
  }
  let list1 = list.filter(element => element != null && element !== '' && element !== undefined)
  if (list1.length > 0 && plan.value !== '') {
    addQueryGroupListApi(qs.stringify({
      type: 1,
      groupName: queryGroup.value,
      queryId: plan.value,
      equipmentIdJson: JSON.stringify(list1)
    })).then(res => {
      if (res.data.code === 0) {
        drawerChart.value = false
        getIndexData()
        getQueryGroupListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请输入必填项'
    })
  }
}


// 点击删除
const deleteClick = function (item, id) {
  ElMessageBox.confirm('请确认是否要删除此查询，删除后数据不可恢复', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    delQueryGroupListApi(qs.stringify({id: item.groupId})).then(res => {
      if (res.data.ok) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        getIndexData()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 创建图表设备操作-------------------------------------------------------------------------
let checkList = ref([])  //设备方案已选设备
let checkListWidth = ref([])
// 获取应用设备初始化列表
const getUseEquipmentFun = function (id) {
  getUseEquipmentApi(qs.stringify({
    id: id,
  })).then(res => {
    if (res.data.code === 0) {
      checkList.value = []
      let list = JSON.parse(JSON.stringify(res.data.result))
      for (let i = 0; i < list.length; i++) {
        checkList.value.push({
          check: false,
          arr: []
        })
        for (let j = 0; j < list[i].length; j++) {
          checkList.value[i].arr.push(list[i][j])
        }
      }
      nextTick(() => {
        let arr1 = document.querySelectorAll('.transferContainHead p')
        checkListWidth.value = []
        for (let i = 0; i < arr1.length; i++) {
          checkListWidth.value.push(Number(arr1[i].offsetWidth) - 2)
        }
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 全选
let checkAll = ref(false)
let checkAllFun = function () {
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkAll.value) {
      checkList.value[i].check = true
    } else {
      checkList.value[i].check = false
    }
  }
}


// 处理图表数据--------------------------------------------------------------------------
// 加载图表数据
let echartsData = ref([])
let echartsDataX = ref([])
let strongShowArr = ref([])
let strongShow = ref([])  //强调展示
let strongShow2 = ref([])  //强调展示
let refreshTime = ref(60)  //刷新时间
let timeUnit = ref()  //时间间隔
let timeUnit2 = ref([])  //时间间隔
const queryChartLoad = function () {
  let list = indexDataObj.value.dataSearchList
  for (let j = 0; j < list.length; j++) {
    list[j].dateTime = [list[j].startTime, list[j].endTime]
    strongShowArr.value[j] = []
    // 判断是否为饼图
    if (list[j].chatType == 4) {
      echartsDataX.value[j] = null
      let list2 = list[j].pieList
      for (let i = 0; i < list2.length; i++) {
        list2[i].name = list2[i].key
        list2[i].value = Number(list2[i].value)
        list2[i].itemStyle = {color: colorArr[i]}
        list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
        list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
        strongShowArr.value[j].push({
          name: list2[i].key,
          color: colorArr[i]
        })
      }
      echartsData.value[j] = [{
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          lineHeight: 18
        },
        data: list2
      }]
    } else {
      echartsDataX.value[j] = list[j].xAxis
      echartsData.value[j] = list[j].yAxis
      let isThan = false
      for (let i = 0; i < echartsData.value[j].length; i++) {
        // 把每条数据名称改为设备+指标
        echartsData.value[j][i].name = echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName
        echartsData.value[j][i].sampling = 'lttb'

        if (echartsData.value[j][i].name.includes('同比') || echartsData.value[j][i].name.includes('环比')) {
          isThan = true
        }

        // 数据处理为小数点两位
        for (let k = 0; k < echartsData.value[j][i].data.length; k++) {
          echartsData.value[j][i].data[k] = Number(echartsData.value[j][i].data[k]).toFixed(2)
        }
      }
      // 图表类型样式
      // 如果是属于折线图
      if (list[j].chatType == 1) {
        for (let i = 0; i < echartsData.value[j].length; i++) {
          echartsData.value[j][i].type = 'line'
          // 处理同环比的不同样式
          judgmentRatio(echartsData.value[j][i], i, isThan)
        }
      }
      // 如果是属于柱状图
      if (list[j].chatType == 0) {
        for (let i = 0; i < echartsData.value[j].length; i++) {
          echartsData.value[j][i].type = 'bar'
          // 处理同环比的不同样式
          judgmentRatio(echartsData.value[j][i], i, isThan)
        }
      }

      for (let i = 0; i < echartsData.value[j].length; i++) {
        strongShowArr.value[j].push({
          name: echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName,
          color: echartsData.value[j][i].color
        })
      }
    }


    nextTick(() => {
      chartLoad(j)
    })
  }
}
// 判断同环比方法
const judgmentRatio = function (item, i, isThan) {
  // 如果是带同环比--------------------------------------------
  if (isThan) {
    if (item.name.includes('同比') || item.name.includes('环比')) {
      // 判断当前是同环比********************
      item.color = colorArr3[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
      }
      item.symbol = 'circle'
      item.symbolSize = 7
    } else {
      // 判断当前不是同环比********************
      item.color = colorArr[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
        shadowColor: colorArr2[Math.floor(i / 2)],
        shadowBlur: 4
      }
      item.symbolSize = 10
    }
  } else {
    // 如果不带同环比--------------------------------------------
    item.color = colorArr[i]
    item.lineStyle = {
      width: 3,
      shadowColor: colorArr2[i],
      shadowBlur: 4
    }
    item.symbolSize = 7
  }

}
// 强调展示选择事件
let strongShowChange = function (idx) {
  // 判断是否为饼图
  if (echartsData.value[idx][0].type === 'pie') {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[idx][0].data[i].name === strongShow.value[j]) {
            echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        judgmentRatio(echartsData.value[idx][i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        echartsData.value[idx][i].color = '#DDDDDD'
        echartsData.value[idx][i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[idx][i].name === strongShow.value[j]) {
            judgmentRatio(echartsData.value[idx][i], i, isThan)
          }
        }
      }
    }
  }

  nextTick(() => {
    chartLoad(idx)
  })
}
// 分组图表强调展示选择事件
let strongShowChange2 = function (id, idx) {
  // 判断是否为饼图
  if (echartsData.value[idx][0].type === 'pie') {
    console.log(echartsData.value[idx])
    if (strongShow2.value[idx] && strongShow2.value[idx].length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow2.value[idx].length; j++) {
          if (echartsData.value[idx][0].data[i].name === strongShow2.value[idx][j]) {
            echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow2.value[idx] && strongShow2.value[idx].length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        judgmentRatio(echartsData.value[idx][i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        echartsData.value[idx][i].color = '#DDDDDD'
        echartsData.value[idx][i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow2.value[idx].length; j++) {
          if (echartsData.value[idx][i].name === strongShow2.value[idx][j]) {
            judgmentRatio(echartsData.value[idx][i], i, isThan)
          }
        }
      }
    }
  }


  nextTick(() => {
    chartLoad(idx, `c${idx}`)
  })
}
// 刷新时间选择事件
const refreshTimeChange = function (e) {
  clearInterval(timer)
  timer = setInterval(() => {
    if (screenGroup.value.queryGroupName && isScreen) {
      enterGroupScreenFun(screenGroup.value)
    } else {
      getIndexData()
    }
  }, e * 1000)
}
// 时间间隔选择事件
const timeUnitChange = function (idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表时间间隔选择事件
const timeUnitChange2 = function (id, idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 日期类型选择事件
const timeFlagSelect = function (idx, item) {
  if (item.timeFlag === '-1') {
    item.dateTime = []
  } else if (item.timeFlag === '1H') {
    item.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '12H') {
    item.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1D' || item.timeFlag === 'now') {
    item.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '3D') {
    item.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1W') {
    item.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === 'THIS_WEEK') {
    item.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1M') {
    item.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1Q') {
    item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表日期类型选择事件
const timeFlagSelect2 = function (id, idx, item) {
  if (item.timeFlag === '-1') {
    item.dateTime = []
  } else if (item.timeFlag === '1H') {
    item.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '12H') {
    item.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1D' || item.timeFlag === 'now') {
    item.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '3D') {
    item.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1W') {
    item.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === 'THIS_WEEK') {
    item.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1M') {
    item.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1Q') {
    item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 日期时间选择事件
const dateTimeChange = function (idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表日期时间选择事件
const dateTimeChange2 = function (id, idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}


// 加载图表
let colorArr = ['rgba(69,125,252,1)', 'rgba(189,82,244,1)', 'rgba(0,229,166,1)', 'rgba(8,205,255,1)', 'rgba(249,74,171,1)', 'rgba(254, 44, 44, 1)', 'rgba(136, 228, 49, 1)', 'rgba(255, 144, 71, 1)', 'rgba(255, 187, 0, 1)', 'rgba(33, 120, 86, 1)', 'rgba(68, 68, 93, 1)', 'rgba(29, 33, 212, 1)', 'rgba(220, 122, 30, 1)']
let colorArr2 = ['rgba(69,125,252,0.2)', 'rgba(189,82,244,0.2)', 'rgba(0,229,166,0.2)', 'rgba(8,205,255,0.2)', 'rgba(249,74,171,0.2)', 'rgba(254, 44, 44, 0.2)', 'rgba(136, 228, 49, 0.2)', 'rgba(255, 144, 71, 0.2)', 'rgba(255, 187, 0, 0.2)', 'rgba(33, 120, 86, 0.2)', 'rgba(68, 68, 93, 0.2)', 'rgba(29, 33, 212, 0.2)', 'rgba(220, 122, 30, 0.2)']
// let colorArr3 = ['rgba(69,125,252,0.4)', 'rgba(189,82,244,0.4)', 'rgba(0,229,166,0.4)', 'rgba(8,205,255,0.4)', 'rgba(249,74,171,0.4)', 'rgba(254, 44, 44, 0.4)', 'rgba(136, 228, 49, 0.4)', 'rgba(255, 144, 71, 0.4)', 'rgba(255, 187, 0, 0.4)', 'rgba(33, 120, 86, 0.4)', 'rgba(68, 68, 93, 0.4)', 'rgba(29, 33, 212, 0.4)', 'rgba(220, 122, 30, 0.4)']
let colorArr3 = ['rgba(181, 203, 254, 1)', 'rgba(229, 186, 251, 1)', 'rgba(153, 244, 219, 1)', 'rgba(156, 235, 255, 1)', 'rgba(253, 182, 222, 1)', 'rgba(255, 171, 171, 1)', 'rgba(208, 244, 173, 1)', 'rgba(255, 209, 180, 1)', 'rgba(255, 228, 153, 1)', 'rgba(166, 201, 187, 1)', 'rgba(180, 180, 190, 1)', 'rgba(164, 166, 238, 1)', 'rgba(241, 202, 165, 1)']
const chartLoad = function (idx, cIdx) {
  var chartDom
  if (cIdx) {
    chartDom = document.querySelectorAll(`.chart${screenIdx.value}${cIdx}`);
  } else {
    chartDom = document.querySelectorAll(`.chart${idx}`);
  }
  for (let i = 0; i < chartDom.length; i++) {
    var myChart = echarts.init(chartDom[i]);
    var option;
    option = {
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: 'none'
      //     },
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      grid: {
        left: 50,
        right: 10,
        top: 30,
        bottom: 30,
      },
      color: colorArr,
      legend: {
        show: true,
        type: 'scroll'
      },
      tooltip: {
        trigger: echartsData.value[idx].type === 'pie' ? 'item' : 'axis', // axis   item   none三个值
        show: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: echartsDataX.value[idx],
        show: echartsDataX.value[idx]
      },
      yAxis: {
        type: 'value',
      },
      series: echartsData.value[idx]
    };
    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }

    // 创建一个ResizeObserver实例
    const observer = new ResizeObserver(entries => {
      myChart.resize();
    });

// 监听目标div元素的宽度变化
    let ele = document.querySelectorAll('.largeScreenContent')
    for (let i = 0; i < ele.length; i++) {
      observer.observe(ele[i]);
    }

    // 监听目标div元素的宽度变化
    let ele2 = document.querySelectorAll('.carouselList')
    for (let i = 0; i < ele2.length; i++) {
      observer.observe(ele2[i]);
    }
  }
}

// 分组操作--------------------------------------------------------------------------------
// 获取分组列表
let queryGroup = ref('')
let queryGroupList = ref([])
const getQueryGroupListFun = function () {
  getQueryGroupListApi(qs.stringify({
    type: 1,
  })).then(res => {
    if (res.data.code === 0) {
      queryGroupList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取分组的数据列表
let carouselList = ref([])  //大列表
let carouselItemList = ref([])   //小列表
// 加载图表 小列表图表
const chartListLoad = function (idx, idx2) {
  let dom = document.querySelectorAll(`.chartItem${idx}_${idx2}`)
  for (let i = 0; i < dom.length; i++) {
    let myChart = echarts.init(dom[i]);
    myChart.clear(); //切换折线图前要先清除
    myChart.setOption({
      tooltip: {
        show: false,
      },
      /*图表位置*/
      grid: {
        x: 0, //左留白
        y: 10, //上留白
        x2: 10, //右留白
        y2: 0 //下留白
      },
      //x轴
      xAxis: {
        data: echartsItemDataX.value[idx][idx2],
        axisLine: {
          lineStyle: {
            color: '#ccc',
            width: 0 //x轴线
          }
        },
        //x轴线中的小竖的分割线
        axisTick: {
          show: false
        },
        show: false
      },
      //y轴
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#999',
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: echartsItemData.value[idx][idx2]
    });
    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }
  }
}

// 分组的全屏操作 ---------------------------
let isBigScreen = ref(false)
let carouselIdx = ref(0)  //当前选中的大列表的索引
// 大列表的切换操作
const carouselChange = function (newVal) {
  carouselIdx.value = newVal
  nextTick(() => {
    for (let i = 0; i < carouselItemRef.value.length; i++) {
      carouselItemRef.value[i].setActiveItem(Math.floor(newVal / 6))
    }
  })
}
// 小列表的点击
let carouselRef = ref()
let carouselItemRef = ref()
const groupItemClick = function (idx1, idx2) {
  carouselIdx.value = idx1 * 6 + idx2
  nextTick(() => {
    for (let i = 0; i < carouselRef.value.length; i++) {
      carouselRef.value[i].setActiveItem(carouselIdx.value)
    }
  })
}


// 导出--------------------------------------------------------------------------------
// 加载的动画
let loadingAdd = ref(null)
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
const exportFun = async function (id) {
  await getSearchDetailApi(id).then(res => {
    if (res.data.code === 200) {
      // 处理指标
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        res.data.data.deviceConditionList[i].dictKeys = []
        for (let j = 0; j < res.data.data.deviceConditionList[i].dictList.length; j++) {
          res.data.data.deviceConditionList[i].dictKeys[j] = res.data.data.deviceConditionList[i].dictList[j].key
        }
      }
      let data = {
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        params: res.data.data.deviceConditionList,
        yom: res.data.data.yom
      }
      // 计算同环比时间
      if (res.data.data.yom && res.data.data.yom[0] == 1) {
        data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (res.data.data.yom && res.data.data.yom[0] == 2) {
        if (timeFlag.value === '1M') {
          data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (timeFlag.value === '1Q') {
          data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(data.endTime).diff(data.startTime, 'second')
          data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let flag = false
      for (let i = 0; i < data.params.length; i++) {
        if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
          flag = true
        }
        delete data.params[i].dictList
        delete data.params[i].dictIds
      }
      if (data.frequency && data.timeUnit && !flag) {
        const downloadZip = (data, fileName = '数据查询') => {
          var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          if ('download' in document.createElement('a')) {
            var downloadElement = document.createElement('a')
            var href = window.URL.createObjectURL(blob) //创建下载的链接
            downloadElement.href = href
            downloadElement.download = fileName //下载后文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() //点击下载
            document.body.removeChild(downloadElement) //下载完成移除元素
            window.URL.revokeObjectURL(href) //释放掉blob对象
          }
        }
        openFullScreen()
        exportDataApi(data).then(res => {
          loadingAdd.value.close()
          downloadZip(res.data)
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          type: 'info',
          message: '请选择筛选条件'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 进入全屏
let screenIdx = ref(0)
let screenVal = ref()
let screenGroup = ref({})
let isScreen = ref(false)
let echartsItemDataX = ref([])
let echartsItemData = ref([])
const enterGroupScreenFun = function (item) {
  getQueryGroupDataApi(qs.stringify({
    type: 1,
    groupName: item.queryGroupName
  })).then(res => {
    if (res.data.code === 0) {
      carouselList.value = res.data.result
      for (let i = 0; i < carouselList.value.length; i++) {
        carouselList.value[i].dateTime = [carouselList.value[i].startTime, carouselList.value[i].endTime]
      }
      // 处理二维数组
      carouselItemList.value = []
      let index = 0;
      while (index < carouselList.value.length) {
        carouselItemList.value.push(carouselList.value.slice(index, index += 6));
      }
      // 处理大列表的图表数据
      let list = res.data.result
      for (let j = 0; j < list.length; j++) {
        strongShowArr.value[j] = []

        // 判断是否为饼图
        if (list[j].chatType == 4) {
          echartsDataX.value[j] = null
          let list2 = list[j].pieList
          for (let i = 0; i < list2.length; i++) {
            list2[i].name = list2[i].key
            list2[i].value = Number(list2[i].value)
            list2[i].itemStyle = {color: colorArr[i]}
            list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
            list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
            strongShowArr.value[j].push({
              name: list2[i].key,
              color: colorArr[i]
            })
          }
          echartsData.value[j] = [{
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: true,
              lineHeight: 18
            },
            data: list2
          }]
        } else {
          echartsDataX.value[j] = list[j].xAxis
          echartsData.value[j] = list[j].yAxis
          let isThan = false
          for (let i = 0; i < echartsData.value[j].length; i++) {
            // 把每条数据名称改为设备+指标
            echartsData.value[j][i].name = echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName
            echartsData.value[j][i].sampling = 'lttb'

            if (echartsData.value[j][i].name.includes('同比') || echartsData.value[j][i].name.includes('环比')) {
              isThan = true
            }

            // 数据处理为小数点两位
            for (let k = 0; k < echartsData.value[j][i].data.length; k++) {
              echartsData.value[j][i].data[k] = Number(echartsData.value[j][i].data[k]).toFixed(2)
            }
          }
          // 图表类型样式
          // 如果是属于折线图
          if (list[j].chatType == 1) {
            for (let i = 0; i < echartsData.value[j].length; i++) {
              echartsData.value[j][i].type = 'line'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[j][i], i, isThan)
            }
          }
          // 如果是属于柱状图
          if (list[j].chatType == 0) {
            for (let i = 0; i < echartsData.value[j].length; i++) {
              echartsData.value[j][i].type = 'type'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[j][i], i, isThan)
            }
          }
          for (let i = 0; i < echartsData.value[j].length; i++) {
            strongShowArr.value[j].push({
              name: echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName,
              color: echartsData.value[j][i].color
            })
          }
        }
        nextTick(() => {
          chartLoad(j, `c${j}`)
        })
      }

      // 处理小列表的图表数据
      let listItem = carouselItemList.value
      for (let i = 0; i < listItem.length; i++) {
        echartsItemDataX.value.push([])
        echartsItemData.value.push([])
        for (let j = 0; j < listItem[i].length; j++) {
          // 判断是否为饼图
          if (listItem[i][j].chatType == 4) {
            echartsItemDataX.value[i][j] = null
            let list2 = listItem[i][j].pieList
            for (let k = 0; k < list2.length; k++) {
              list2[k].name = list2[k].key
              list2[k].value = Number(list2[k].value)
              list2[k].itemStyle = {color: colorArr[k]}
              list2[k].label = {formatter: `{b}: {d}%\n${list2[k].time}`}
              list2[k].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[k].time}`}
            }
            echartsItemData.value[i][j] = [{
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: true,
                lineHeight: 18
              },
              data: list2
            }]
          } else {
            echartsItemDataX.value[i][j] = listItem[i][j].xAxis
            echartsItemData.value[i][j] = listItem[i][j].yAxis
            let isThan = false
            for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
              // 把每条数据名称改为设备+指标
              echartsItemData.value[i][j][k].name = echartsItemData.value[i][j][k].deviceName + '-' + echartsItemData.value[i][j][k].titleName
              echartsItemData.value[i][j][k].sampling = 'lttb'

              if (echartsItemData.value[i][j][k].name.includes('同比') || echartsItemData.value[i][j][k].name.includes('环比')) {
                isThan = true
              }

              // 数据处理为小数点两位
              for (let h = 0; h < echartsItemData.value[i][j][k].data.length; h++) {
                echartsItemData.value[i][j][k].data[h] = Number(echartsItemData.value[i][j][k].data[h]).toFixed(2)
              }
            }

            // 图表类型样式
            // 如果是属于折线图
            if (listItem[i][j].chatType == 1) {
              for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                echartsItemData.value[i][j][k].type = 'line'
                // 处理同环比的不同样式
                judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
              }
            }
            // 如果是属于柱状图
            if (listItem[i][j].chatType == 0) {
              for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                echartsItemData.value[i][j][k].type = 'bar'
                // 处理同环比的不同样式
                judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
              }
            }
          }


          nextTick(() => {
            chartListLoad(i, j)
          })
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const enterFullScreen = (idx, id, item) => {
  // isScreen.value = true
  // screenGroup.value = item
  // // 判断进入全屏的是分组还是单个查询
  // if (item.queryGroupName !== '') {
  //   enterGroupScreenFun(item)
  // }

  // screenIdx.value = idx
  // screenVal.value = id
  // // 获取layout的dom元素
  // const parentNode = document.querySelectorAll('.largeScreenUl')[idx].querySelector('.largeScreenContent'); // 获取需要全屏的元素;
  // // document.querySelector('.largeScreenContent .chart').style.height = 'calc(100% - 182px)'  //进入全屏后变大图片
  // // 进入全屏
  // if (parentNode.requestFullscreen) {
  //   parentNode.requestFullscreen();
  // } else if (parentNode.webkitRequestFullScreen) {
  //   parentNode.webkitRequestFullScreen();
  // } else if (parentNode.mozRequestFullScreen) {
  //   parentNode.mozRequestFullScreen();
  // } else if (parentNode.msRequestFullscreen) {
  //   // IE11
  //   parentNode.msRequestFullscreen();
  // }
  // nextTick(() => {
  //   chartLoad(idx)
  // })

  window.open(`#/homeSearchScreen?type=1&idx=${idx}`, '_blank')
};
// 退出全屏
const exitFullscreen = () => {
  isScreen.value = false
  carouselIdx.value = 0
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
  getIndexData()
};

// 定时器
let timer = setInterval(() => {
  // getIndexData()
}, refreshTime.value * 1000)

let intervalList = ref([])  //数据间隔列表
onMounted(async () => {
  await getIndexData()
  getQueryGroupListFun()
  document.addEventListener('fullscreenchange', function () {
    if (!document.fullscreenElement) {
      isScreen.value = false
      carouselIdx.value = 0
      getIndexData()
    } else {
      // console.log('仍处于全屏');
      // 执行进入全屏后的操作
    }
  });

  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>

<style lang="less" scoped>

</style>
