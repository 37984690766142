import {api} from "@/api";


// 电价-----------------------------------------------------
// 获取电价列表
export const getPowerPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/page',
        method: 'POST',
        data: data
    })
}
// 获取电价列表详情
export const getPowerPriceDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/getDetail',
        method: 'POST',
        data: data
    })
}

// 新增电价列表
export const addPowerPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/save',
        method: 'POST',
        data: data
    })
}

// 删除电价列表
export const delPowerPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/delete',
        method: 'POST',
        data: data
    })
}

// 电价类型选择
export const getTimeShareSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/listTimeShareType',
        method: 'POST',
        data: data
    })
}

// 电价方案选择
export const getPowerPriceSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPrice/listSelect',
        method: 'POST',
        data: data
    })
}

// 电价组合包-----------------------------------------------------
// 获取电价组合包列表
export const getPowerPriceGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/page',
        method: 'POST',
        data: data
    })
}
// 获取电价组合包选项
export const getPowerPriceGroupSelectApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/listSelect',
        method: 'POST',
        data: data
    })
}

// 获取组合包正在使用的电价
export const getUsePriceApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroupPrice/getUsePrice',
        method: 'POST',
        data: data
    })
}


// 获取电价列表详情
export const getPowerPriceGroupDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/getDetail',
        method: 'POST',
        data: data
    })
}

// 新增电价列表
export const addPowerPriceGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/save',
        method: 'POST',
        data: data
    })
}

// 删除电价列表
export const delPowerPriceGroupListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/delete',
        method: 'POST',
        data: data
    })
}
// 获取项目设备树
export const getPowerPriceGroupTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/getTreePrefix',
        method: 'POST',
        data: data
    })
}
// 获取电价组合包电价列表
export const getPowerPriceGroupPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroupPrice/list',
        method: 'POST',
        data: data
    })
}
// 新增电价组合包电价列表
export const addPowerPriceGroupPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroupPrice/save',
        method: 'POST',
        data: data
    })
}
// 删除电价组合包电价列表
export const delPowerPriceGroupPriceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroupPrice/delete',
        method: 'POST',
        data: data
    })
}


// 电价应用-----------------------------------------------------
// 根据设备id获取可应用的组合包
export const getDetailByEquipmentIdApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/getDetailByEquipmentId',
        method: 'POST',
        data: data
    })
}
// 根据设备id获取可应用的组合包
export const getDefaultEquipmentApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerPriceGroup/getDefaultEquipment',
        method: 'POST',
        data: data
    })
}


