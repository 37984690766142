<template>
  <div class="powerStationItemOverview">
    <!--上部分-->
    <div class="topBox">
      <!--上部分左侧-->
      <div class="topLeftBox">
        <div class="titleBox">
          <p class="title">电站信息</p>
          <div class="more" @click="emit('isShowMapFun', 2);">
            <span>位置</span>
            <el-tooltip content="地图分布" placement="top">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/positionIcon.png">
            </el-tooltip>
          </div>
        </div>
        <div class="topLeftItemBox">
          <div class="topLeftItem1">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItemCopy2.png">
            <div class="topLeftCenterRight">
              <p class="valueBox">
                <span class="val" style="margin-right: 5px">{{ stationData.equipmentNumber }}</span>
                <span class="unit">个</span>
              </p>
              <p class="describe">接入设备数</p>
            </div>
          </div>
          <div class="topLeftItem2">
            <div class="item">
              <p class="val">{{ stationData.onlineEquipmentNumber }}</p>
              <p class="describe">在线设备数</p>
            </div>
            <div class="item" style="cursor: pointer" @click="lookOfflineFun">
              <p class="val">{{ stationData.offlineEquipmentNumber }}</p>
              <p class="describe">离线设备数</p>
              <!--              <el-icon>-->
              <!--                <ArrowRight/>-->
              <!--              </el-icon>-->
            </div>
            <div class="item" style="cursor: pointer" @click="lookWarnFun">
              <p class="val" style="color: #FF1111">{{ stationData.warnEquipmentNumber }}</p>
              <p class="describe" style="color: #FF1111">告警设备数</p>
              <!--              <el-icon>-->
              <!--                <ArrowRight/>-->
              <!--              </el-icon>-->
            </div>
          </div>
        </div>
      </div>
      <!--上部分右侧-->
      <div class="topRightBox">
        <div class="titleBox">
          <p class="title">运行分析</p>
          <div class="more" @click="settingClick">
            <span>配置</span>
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/setIcon.png"
                 style="cursor:pointer;">
          </div>
        </div>
        <div class="topRightContent">
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem1.png">
            <div class="cont">
              <p class="val">{{ stationData.totalDischargeAmount }}</p>
              <p class="describe">累计放电量 kwh</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem1.png">
            <div class="cont">
              <p class="val">{{ stationData.totalChargeAmount }}</p>
              <p class="describe">累计充电量 kwh</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem2.png">
            <div class="cont">
              <p class="val">{{ stationData.totalIncome }}</p>
              <p class="describe">累计收益 元</p>
            </div>
          </div>
          <div class="topRightItem">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem3.png">
            <div class="cont">
              <p class="val">{{ stationData.totalCapacity }}</p>
              <p class="describe">总装机容量 kw</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--中间部分-->
    <div class="centerBox">
      <p class="title">充放电趋势</p>
      <div class="timeBox">
        <div>
          <el-date-picker v-if="timeActive===4" v-model="dateTime[0]"
                          :disabled-date="disabledDate3"
                          placeholder="请选择日期" type="date"
                          value-format="YYYY-MM-DD" @change="timeSelect"/>
          <el-date-picker v-if="timeActive===1" v-model="dateTime" :disabled-date="disabledDate3"
                          end-placeholder="结束时间"
                          popper-class="date-style" range-separator="→"
                          start-placeholder="开始时间" type="daterange"
                          value-format="YYYY-MM-DD"
                          @change="timeSelect">
          </el-date-picker>
          <el-date-picker v-if="timeActive===2"
                          v-model="dateTime[0]" :disabled-date="disabledDate3"
                          placeholder="请选择日期" type="year"
                          value-format="YYYY" @change="timeSelect"/>
        </div>
        <div class="timer">
          <p :class="timeActive===4?'active':''" @click="selectTimeFun(4)">时</p>
          <p :class="timeActive===1?'active':''" @click="selectTimeFun(1)">日</p>
          <p :class="timeActive===2?'active':''" @click="selectTimeFun(2)">月</p>
          <p :class="timeActive===3?'active':''" @click="selectTimeFun(3)">年</p>
        </div>
      </div>
      <div v-if="echartsDataX.length>0" class="chart"></div>
      <el-empty v-if="echartsDataX.length===0"
                class="emptyClass" description="暂无数据信息"
                image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
    </div>
    <!--视频监控-->
    <div class="footerBox">
      <div class="headBox">
        <p class="tit">视频监控</p>
        <el-icon @click="goVideoClick">
          <ArrowRight/>
        </el-icon>
      </div>
      <div v-if="stationData.videoJsonStr.length===0" style="display: flex;flex-direction: column;align-items: center;">
        <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
             style="height: 130px">
        <p>暂无数据</p>
      </div>
      <div v-if="stationData.videoJsonStr.length>0" class="videoList">
        <div v-for="(item,index) in stationData.videoJsonStr" :key="index" class="videoListItem">
          <video :src="item.url" autoplay="autoplay" controls loop muted></video>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!--离线站点弹窗-->
    <el-dialog v-model="offlineDialog" :close-on-click-modal="false" class="offlineDialog" title="查看详情" width="50%">
      <el-table v-loading="offlineLoading" :data="offlineTableData" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="名称" prop="name"/>
        <el-table-column label="产品型号" prop="productModule"/>
        <el-table-column label="协议类型" prop="type"/>
        <el-table-column label="地址" prop="address"/>
        <el-table-column label="创建人" prop="createPeople">
          <template #default="scope">
            <div class="createPeopleBox">
              <img alt="" src="../../../../assets/img/user.png">
              <span>{{ scope.row.createPeople }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template #default="scope">
            <div :class="scope.row.status == 1?'online':'offline'" class="statusPox">
              <p class="dotOnClass"></p>
              <p>{{ scope.row.status == 1 ? '在线' : '离线' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-tooltip content="设备详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   @click="lookEquipmentDetail(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--告警设备弹窗-->
    <el-dialog v-model="warnDialog" :close-on-click-modal="false" class="warnDialog" title="查看详情" width="50%">
      <el-table v-loading="warnLoading" :data="warnTableData" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="名称" prop="name"/>
        <el-table-column label="发生时间" prop="happenTime"/>
        <el-table-column label="报警数量" prop="warn"/>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-tooltip content="设备详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   style="margin-right: 10px"
                   @click="lookEquipmentDetail(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="告警详情">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon2.png"
                   @click="lookWarn(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--告警详情弹窗-->
    <el-dialog v-model="warnDetailDialog" :close-on-click-modal="false" class="warnDetailDialog" title="查看详情"
               width="50%">
      <el-table v-loading="warnLoading" :data="warnTableData2" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="发生时间" prop="happenTime"/>
        <el-table-column label="类别" prop="type"/>
        <el-table-column class-name="level" label="级别" prop="level">
          <template #default="scope">
            <p v-if="scope.row.level===3" style="background: #FF1111">高风险</p>
            <p v-if="scope.row.level===2" style="background: #FF7811">中风险</p>
            <p v-if="scope.row.level===1" style="background: #FFBB11">低风险</p>
            <p v-if="scope.row.level===0" style="background: #00B42A">正常</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="scope">
            <el-tooltip content="结束报警">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon6.png"
                   style="margin-right: 10px" @click="endClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="转工单">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon7.png"
                   style="margin-right: 10px" @click="wordClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="查看当时值">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   @click="lookValue(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref, watch, watchEffect} from "vue"
import {useRouter} from "vue-router/dist/vue-router";
import * as echarts from "echarts";
import {ArrowRight} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from "element-plus";
import {getStoreChartDataApi, getStoreDataApi} from "@/api/modules/powerStation";
import qs from 'qs'
import moment from "moment";


const props = defineProps(['custerObj'])

let router = useRouter()
const emit = defineEmits(['isShowMapFun']);


// 点击进行配置
const settingClick = function () {
  router.push({
    path: '/admin/energyStationSetting',
    query: {
      id: custerObj.value.id
    }
  })
}
// 点击查看监控
const goVideoClick = function () {
  router.push('/admin/energyStationVideo?id=' + custerObj.value.id)
}

// 电站数据-------------------------------------------------------------------
let stationData = ref({
  videoJsonStr: []
})
const getPhotovoltaicDataFun = function () {
  getStoreDataApi(qs.stringify({
    id: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      stationData.value = res.data.result
      stationData.value.videoJsonStr = JSON.parse(stationData.value.videoJsonStr)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  })
}

// 图表-------------------------------------------------------------------
// 年月日选择
let timeActive = ref(1)
const selectTimeFun = function (idx) {
  timeActive.value = idx
  if (timeActive.value === 1) {
    dateTime.value = [moment().subtract(2, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  } else if (timeActive.value === 2) {
    dateTime.value = [moment().startOf('years').format('YYYY'), moment().startOf('years').format('YYYY')]
  } else if (timeActive.value === 4) {
    dateTime.value = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  }
  getPhotovoltaicChartDataFun()
}
let dateTime = ref([moment().subtract(2, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
const timeSelect = function () {
  getPhotovoltaicChartDataFun()
}
// 禁用今天之后的时间
const disabledDate3 = function (time) {
  return time.getTime() > Date.now(); // 禁用当前时间之后的日期
}


// 获取图表数据
let echartsDataX = ref([])
let echartsData = ref([])
const getPhotovoltaicChartDataFun = function () {
  getStoreChartDataApi(qs.stringify({
    id: custerObj.value.id,
    timeType: timeActive.value,
    startTime: dateTime.value[0],
    endTime: dateTime.value[1],
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value = res.data.result.chartDataTimeList
      echartsData.value = res.data.result.chartDataValueList
      nextTick(() => {
        chartLoad()
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  })
}
// 加载图表
const chartLoad = function () {
  var chartDom = document.querySelector('.chart');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    grid: {
      left: 50,
      right: 20,
      bottom: 20,
      top: 40,
    },
    legend: {
      show: true,
      type: 'scroll'
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#405873'
        }
      },
      data: echartsDataX.value
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#405873'
        }
      },
    },
    series: [{
      name: echartsData.value[0].key,
      type: 'bar',
      data: echartsData.value[0].value,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {offset: 0, color: '#3196FF'},
        {offset: 1, color: '#165DFF'}
      ])
    }, {
      name: echartsData.value[1].key,
      type: 'bar',
      data: echartsData.value[1].value,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {offset: 0, color: '#31E1FF'},
        {offset: 1, color: '#02ADFF'}
      ])
    }]
  };
  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }

}


// 离线站点--------------------------------------------------------------------------
let offlineDialog = ref(false)
let offlineLoading = ref(false)
let offlineTableData = ref([
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  },
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  },
  {
    id: 0,
    name: '设备1',
    productModule: '12345',
    type: '376.1',
    address: '000001',
    createPeople: 'admin',
    status: 0,
  }
])
// 点击查看离线设备
const lookOfflineFun = function () {
  // offlineDialog.value = true
}
// 点击查看设备详情
const lookEquipmentDetail = function (id) {
  router.push({
    path: '/admin/energyStationEquipment',
    query: {
      id: id
    }
  })
}


// 报警设备--------------------------------------------------------------------------
let warnDialog = ref(false)
let warnLoading = ref(false)
let warnTableData = ref([
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  },
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  },
  {
    id: 0,
    name: '设备1',
    happenTime: '2024-09-10 12:45:04',
    warn: '高风险4分、中风险0个、低风险0个',
  }
])
// 点击查看告警设备
const lookWarnFun = function () {
  // warnDialog.value = true
}
// 点击查看告警详情
let warnDetailDialog = ref(false)  //告警详情弹窗
// 告警详情数据
let warnTableData2 = ref([
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 0
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 1
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 2
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 3
  }
])
const lookWarn = function (id) {
  warnDetailDialog.value = true
}
// 结束报警
const endClick = function () {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '结束报警',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要结束此报警，结束后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '结束报警成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束报警'
    });
  });
}


onMounted(() => {
  // getPhotovoltaicChartDataFun()
  getPhotovoltaicDataFun()
})

let custerObj = ref()
watch(() => props.custerObj, (newVal) => {
  custerObj.value = newVal
  getPhotovoltaicChartDataFun()
  getPhotovoltaicDataFun()
}, {immediate: true, deep: true})

</script>

<style lang="less" scoped>
.powerStationItemOverview {
  width: 100%;
  height: 100%;
  min-height: 700px;

  .topBox {
    margin-bottom: 10px;
    height: calc(33% - 10px);
  }

  .topBox {
    display: flex;
    justify-content: space-between;

    > div {
      flex: 1;
      height: calc(100% - 32px);
      background: #FFFFFF;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
      border-radius: 6px;
      padding: 16px;
    }

    .topLeftBox {
      margin-right: 10px;

      .titleBox {
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: 600;
          color: #333;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #666666;
          font-size: 12px;

          img {
            width: 16px;
            height: 16px;
            cursor: pointer;
            margin-left: 8px;
          }
        }

      }

      .topLeftItemBox {
        height: calc(100% - 24px);
      }

      .topLeftItem1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: calc(54% - 34px);
        background: rgba(22, 93, 255, 0.05);
        box-shadow: inset 0px 0px 20px 0px rgba(22, 93, 255, 0.1);
        border-radius: 3px;
        margin-top: 10px;
        padding: 12px 0;

        img {
          //width: 100px;
          height: 100%;
        }

        .topLeftCenterRight {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .valueBox {
            .val {
              font-size: 24px;
              color: #165DFF;
              font-weight: 600;
            }
          }
        }
      }

      .topLeftItem2 {
        display: flex;
        height: calc(46% - 10px);
        justify-content: space-between;
        margin-top: 10px;

        .item:first-of-type, .item:nth-child(2) {
          margin-right: 10px;
        }

        .item:last-child {
          background: rgba(255, 22, 22, 0.05);
          box-shadow: inset 0px 0px 20px 0px rgba(255, 22, 22, 0.1);
        }

        .item {
          flex: 1;
          background: rgba(22, 93, 255, 0.05);
          box-shadow: inset 0px 0px 20px 0px rgba(22, 93, 255, 0.1);
          border-radius: 3px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          padding: 10px;


          .val {
            font-weight: 600;
            font-size: 22px;
            color: #333333;
          }

          .describe {
            font-size: 13px;
          }

          .el-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            color: #999999;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }

    .topRightBox {
      .titleBox {
        display: flex;
        justify-content: space-between;

        .title {
          font-weight: 600;
          color: #333;
        }

        .more {
          display: flex;
          align-items: center;

          span {
            cursor: pointer;
            color: #666666;
          }

          img {
            width: 16px;
            height: 16px;
            margin-left: 16px;
            cursor: pointer;
          }
        }

      }

      .topRightContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: calc(100% - 24px);

        .topRightItem:nth-child(odd) {
          margin-right: 10px;
        }


        .topRightItem {
          width: calc(50% - 17px);
          height: calc(50% - 34px);
          background: rgba(22, 93, 255, 0.05);
          box-shadow: inset 0px 0px 20px 0px rgba(22, 93, 255, 0.1);
          border-radius: 6px;
          padding: 12px 0 12px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          img {
            width: 56px;
            height: 56px;
          }

          .cont {
            width: calc(100% - 66px);
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;

            .val {
              font-weight: 600;
              font-size: 24px;
              color: #165DFF;
            }

            .describe {
              font-size: 13px;
            }
          }

          .cont::before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 1px;
            height: calc(100% - 16px);
            background: rgba(22, 93, 255, 0.1);
          }
        }
      }
    }
  }

  //视频监控
  .footerBox {
    width: calc(100% - 32px);
    height: calc(33% - 42px);
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 16px;
    margin-top: 10px;

    .headBox {
      display: flex;
      justify-content: space-between;

      .tit {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }

      .el-icon {
        cursor: pointer;
      }
    }

    .videoList {
      padding: 14px 28px 0;
      width: calc(100% - 56px);
      height: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .videoListItem {
        width: 23%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        video {
          padding: 4px;
          width: calc(100% - 8px);
          max-height: calc(100% - 34px);
          background: url("https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/videoBorder.png") no-repeat 0 0;
          background-size: 100% 100%;
          cursor: pointer;
        }


        p {
          text-align: center;
        }
      }
    }
  }

  .centerBox {
    width: calc(100% - 32px);
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
    border-radius: 6px;
    padding: 16px;
    position: relative;
    height: calc(33.3333% - 32px);

    .chart {
      width: 100%;
      height: calc(100% - 32px);
      margin-top: 32px;
    }

    .title {
      font-weight: 600;
      color: #333;
      position: absolute;
      left: 16px;
      top: 16px;
    }

    .timeBox {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
      display: flex;
    }

    .timer {
      width: 168px;
      height: 32px;
      background: #E8EFFF;
      border-radius: 4px;
      display: flex;
      margin-left: 16px;

      p {
        flex: 1;
        height: 32px;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }

      .active {
        background: #165DFF;
        color: #FFFFFF;
        border-radius: 4px;
      }
    }
  }


  //离线弹窗
  .el-dialog {
    .el-table {
      .createPeopleBox {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      .statusPox {
        width: 60px;
        height: 22px;
        border-radius: 3px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .dotOnClass {
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }
      }

      .online {
        background: #E8FFEA;

        p {
          color: #00B42A;
        }

        .dotOnClass {
          background: #00B42A;
        }
      }

      .offline {
        background: #FFECE8;

        p {
          color: #F53F3F;
        }

        .dotOnClass {
          background: #F53F3F;
        }
      }

      .level {
        p {
          width: 56px;
          height: 24px;
          border-radius: 4px;
          text-align: center;
          line-height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .operator {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transform: translateY(3px);
      }

    }
  }

}
</style>
